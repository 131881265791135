/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState, useEffect } from "react";
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
import { toast } from "react-toastify";
import parse from 'html-react-parser';
import "./registrationFeePayment.css"

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import { HttpService } from "Api-Services/httpService";
import { Autocomplete, Avatar, Checkbox, CircularProgress, Divider, FormControlLabel, List, ListItem, ListItemAvatar, ListItemText, TextField } from "@mui/material";
import { useCurrentUser } from "Api-contexts/currentUserContext";
import { useNavigate } from "react-router-dom";

function AgencyRegistrationFeePayment() {
  const [forceRerender, setForceRerender] = React.useState(true);
  const [loading, setLoading] = useState(false);
  const [transactionDate, setTransactionDate] = useState(false);
  const [transeredByFullName, setTranseredByFullName] = useState(false);
  const [transactionId, setTransactionId] = useState(false);
  const [isFormValid, setIsFormValid] = useState(true);
  const [invalidResponse, setInvalidResponse] = useState("");
  const [loginResponse, setLoginResponse] = useState(null);

  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [paymentPage, setPaymentPage] = useState(1);
  const [selectedPaymentAccount, setSelectedPaymentAccount] = useState(null);
  const [confirmationResponseMessage, setConfirmationResponseMessage] = useState(null);
  const [acceptInstruction, setAcceptInstruction] = useState(true);
  const [currentData,updateContext] = useCurrentUser();
  const { paymentMethods,agencyData,websitePagesContent,currentUser, employmentAgencyPaymentAccounts ,myAgencyJobSeekerInformation, myJobSeekerInformation, jobs } = currentData;
  const [joinUsPage, setJoinUsPage] = React.useState(null);
  const [newSeekerJobs, setNewSeekerJobs] = React.useState([]);
  const [agencyJobs, setAgencyJobs] = React.useState([]);

const navigate = useNavigate();
  useEffect(() => { 
    if (websitePagesContent) {
      if (websitePagesContent.pages) {
        if (websitePagesContent.pages.joinUsPage) {
          if (websitePagesContent.pages.joinUsPage.sections) {
            setJoinUsPage(websitePagesContent.pages.joinUsPage.sections);
          }
        }
      }
    }
  }, [websitePagesContent]);
   
  useEffect(() => { 
    // console.log("useEffect", " data:employmentAgencyPaymentAccounts ",employmentAgencyPaymentAccounts, myAgencyJobSeekerInformation,websitePagesContent,agencyData,myJobSeekerInformation   );

   setForceRerender(!forceRerender)
  }, [myAgencyJobSeekerInformation,websitePagesContent,agencyData,myJobSeekerInformation]);

  useEffect(() => {
    // console.log("agencyData",agencyData)
        let filterdJobs=[]
    if (agencyData) {
      if (agencyData.jobsId) {  
        let allagencyjobs=agencyData.jobsId.split(",")
        filterdJobs=  jobs.filter(jobD=>{ 
          let res= false
            allagencyjobs.forEach(element => {
              element=parseInt(element)
              if(element){
                if(element==jobD.id){
                  res= true
                } 
              }
            });
               return res

          })
        setAgencyJobs(filterdJobs) 
      }
    }
  }, [agencyData,jobs]);
 

  const login = async () => {
    let valid = false;
    setLoginResponse(null);
    setInvalidResponse("");
    setIsFormValid(true);
    if (fullName && message) {
      setIsFormValid(true);
      valid = true;
    } else {
      setIsFormValid(false);
      valid = false;
      setInvalidResponse(" Please provide your full name and your message");
    }
    // console.log("userEmail&&password",userEmail,password, valid)
    if (valid) {
      setLoading(true);
      let data = {
        // "platformUserName":companyName,
        email: email,
        message: message,
        fullName: fullName,
      };
      let relativePath = "/api/contactus/new";
      let toastId = toast.loading("updating website user...",{
        position: toast.POSITION.BOTTOM_LEFT
      })
      // let AdditionalData={withCredentials: true, credentials: 'include'}//{  credentials: 'include' }//header can be included here
      let contactUsRes = await postDocuments("contactus", relativePath, data, {});
      if (contactUsRes.success) {
        toast.success(contactUsRes.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        toast.error(contactUsRes.message, {
          position: toast.POSITION.TOP_LEFT,
        });
      }
    }
    setLoading(false);
  };


  const getDataFromArray = (dataArray, searchField, searchValue, returnRows, returnField) => {
    let res = {};
    // console.log("dataArray   ", dataArray,searchField, searchValue, returnRows, returnField);
    try {
      if (dataArray && searchField && searchValue) {
        if (dataArray.length > 0) {
          let filteredLevels = dataArray.filter((level) => level[searchField] == searchValue);
          if (filteredLevels.length) {
            res = filteredLevels;
            if (returnRows == "single") {
              let row = filteredLevels[0];
              res = row;
              if (returnField) {
                let rowField = row[returnField];
                res = rowField;
              }
            }
          }
        }
      }
      // console.log("filtered city  ", res);
    } catch (error) {
      // console.log("filtered city error", error);
    }
    return res;
  };

  const postDocuments = async (documentName, relativePath, data) => {
    let responseD = { success: false, message: "", record: null };
    let documentsResponse = { success: false };
    setLoading(true);

    ////////// UPDATED/////////////
    documentsResponse = await HttpService.postService(relativePath, data, {}, "");
    // console.log(documentName, " data: ", documentsResponse   );
    setLoading(false);

    if (documentsResponse.success) {
      responseD.success = true;
      responseD.record = documentsResponse.data.record;
      responseD.message = documentsResponse.data.message;
      let record = responseD.record;

      //write codtions down here//

      if (documentName == "login") {
        localStorage.clear();
        setLoginResponse(documentsResponse);
        setLoading(true);
        // await getDocumentsAll("allOffices","/api/officeUserRole/myoffices",{})//init user offices and data
        setLoading(false);
        let path = "/";
        window.location.assign(path);
      }
    } else {
      responseD.message = documentsResponse.message;
      if (documentName == "login") {
        setLoginResponse(responseD);
      }
    }

    // console.log(documentName, " responseD: ", responseD   );
    setForceRerender(!forceRerender);
    return responseD;
    ////////// UPDATED/////////////
  };


  const addUserToWebsite=async()=>{  
 
    let data; 
    try { 
      setConfirmationResponseMessage(null)
      if(currentUser&&agencyData&&selectedPaymentAccount){
    
        let relativePath="/api/employmentagencypaymentconfirmationrequest/new";  
          data={  
            "employmentAgencyId":  agencyData.id,
            "jobSeekerId" :myJobSeekerInformation.id ,
            "transactionId":transactionId,
            "transeredByFullName":transeredByFullName ,
        
            // transeredByFirstName:DataTypes.TEXT ,
            // transeredByLastName:DataTypes.TEXT ,
            // transeredByMiddleName:DataTypes.TEXT ,
        
            "employmentAgencyPaymentAccountId":  selectedPaymentAccount.id ,
            "paymentMethodId": selectedPaymentAccount.paymentMethodId,
            "transeredToFullName":selectedPaymentAccount.accountOwnerFullName ,
            "transeredToAccountNumber" :selectedPaymentAccount.accountOwnerNumber , 
            "transactionDate" :transactionDate, 
        }       
      
      let toastId = toast.loading("Confirming payment...",{
        position: toast.POSITION.BOTTOM_LEFT
      })
      setLoading(true)
      let addUserToWebsiteRes=await postDocuments("addUserToWebsite",relativePath,data)
      setLoading(false)
      if(addUserToWebsiteRes){ 
          setConfirmationResponseMessage(addUserToWebsiteRes.message)
          toast.update(toastId, { render: addUserToWebsiteRes.message, type: addUserToWebsiteRes.success?"success":"warn", isLoading: false , autoClose: 5000});
          if(addUserToWebsiteRes.success){
          setPaymentPage(4)
          // setAddNewUserButton(false)
          // setUserSearchResult(null)
          updateContext("myAgencies")
          updateContext("employmentAgencyJobSeekers")
          updateContext("newJobSeekers") 
          updateContext("myAgencyJobSeekerInformation") 
          // "/profile"
          // navigate("/profile?tab=2");
          }
        }
          } 
      
    } catch (error) {
    // console.log("error",error)
      
    }
    
   
  }
  
    



  return (
    <MKBox
      component="section"
      display="grid"
      position="relative"
      minHeight="90vh"
      borderRadius="xl"
      mr={{ xs: 0, lg: -2 }}
      mb={{ xs: 0, lg: -2 }}
      sx={{ overflow: { xs: "hidden", lg: "visible" }, placeItems: "center" }}
    >
      {(joinUsPage) && (
        <MKBox
          component="img"
          alt="lake house"
          src={
            joinUsPage.card.cardImage ||
            "https://images.unsplash.com/photo-1539803442075-48618f39bb3d?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;ixlib=rb-1.2.1&amp;auto=format&amp;fit=crop&amp;w=963&amp;q=80"
          }
          width={{ xs: "100%", lg: "50%" }}
          height={{ xs: "100%", lg: "100%" }}
          position="absolute"
          right={0}
          bottom={{ xs: "-25%", lg: "unset" }}
          top={{ xs: 0, lg: "unset" }}
          sx={{
            objectFit: "cover",
            borderTopLeftRadius: ({ borders: { borderRadius } }) => ({
              xs: 0,
              lg: borderRadius.lg,
            }),
          }}
        />
      )}
      <Container>
        <Grid container spacing={{ xs: 0, lg: 3 }} sx={{ mt: { xs: 0, lg: 12 } }}>
          <Grid item xs={12} lg={12} justifyContent="center" flexDirection="column">
            <MKBox
              display="flex"
              justifyContent="center"
              // alignItems="center"
              flexDirection="column"
              shadow="lg"
              borderRadius="xl"
              sx={{
                backgroundColor: ({ functions: { rgba }, palette: { white } }) =>
                  rgba(white.main, 0.6),
                backdropFilter: "saturate(200%) blur(30px)",
                px: { xs: 3, sm: 6 },
                py: { xs: 3, sm: 8 },
                mb: { xs: 0, lg: 8 },
                mt: { xs: 0, lg: -6 },
              }}
            >
                <MKBox py={1} px={{ md: 4, lg: 4, xl: 6 }}>
              <MKTypography variant="h3" textAlign="center" mb={2}> 
               {"Payment"}</MKTypography>

              {/* <MKTypography variant="h5" textAlign="left" mb={2}>
             {agencyData?(agencyData.brandName||agencyData.fullName):""} </MKTypography> */}
              {(agencyData)&&<>
              <MKTypography variant="body2" color="danger" textAlign="left" mb={2}> 
               {"Paid To: "} <strong> {agencyData?(agencyData.brandName||agencyData.fullName):""} </strong> </MKTypography>
              <MKTypography variant="body2" color="danger" textAlign="left" mb={2}> 
               {"Payment Reason: "} <strong>{"Registration Fee"}</strong> </MKTypography>
              <MKTypography variant="body2" color="danger" textAlign="left" mb={2}> 
               {"Amount: "} <strong>{agencyData.registrationFeeAmount+" "+agencyData.registrationFeeCurrency}</strong></MKTypography> 
                </>} 

              <MKTypography variant="h5" color="danger" textAlign="center" mb={2}>  
               {"Payment Processes! "}</MKTypography>
              </MKBox>
             {  (paymentPage==1)&& 
             <>
                <MKBox py={1} px={{ md: 4, lg: 4, xl: 6 }}>

              <MKTypography variant="body2" color="danger" textAlign="center" mb={2}>  
               {"Choose Payment Method "}</MKTypography>
                </MKBox>

              <MKBox component="form">

                <MKBox py={1} px={{ md: 4, lg: 4, xl: 6 }}>
                    <Grid container spacing={3} sx={{ mb: 3 ,py:1}}>
                    <Grid item xs={12} lg={12} justifyContent="center" flexDirection="column">
                      <MKBox
                        // display="flex"
                        justifyContent="center"
                        alignItems="center"
                        // flexDirection="column"
                        shadow="lg"
                        borderRadius="xl"
                        sx={{
                          backgroundColor: ({ functions: { rgba }, palette: { white } }) =>
                            rgba(white.main, 0.8),
                          backdropFilter: "saturate(200%) blur(30px)",
                          px: { xs: 3, sm: 6 },
                          py: { xs: 3, sm: 2 },
                          // mb: { xs: 0, lg: 8 },
                          mt: { xs: 0, lg: 1 },
                        }}
                      >
                        <MKTypography variant="h6" textAlign="center" mb={2}>
                        {"1/ Select Payment Method"}
                        </MKTypography>
                        
                     
                        <MKBox style={{overflow:"auto", maxHeight:"250px"}} > 
                          <MKTypography variant="subtitle" color="text" >
                          <small>
                          {"You can easily complete the payment process by paying through any of the banks listed below."}
                                </small>
                            </MKTypography> 
                        </MKBox>

                        {/* <MKTypography variant="body2" color="danger" textAlign="left" mb={2}>  
                      {"Payment Methods! "}</MKTypography> */}
 
                  <Grid   container spacing={3} sx={{ mb: 3 ,py:1}}> 
                        {(employmentAgencyPaymentAccounts?(employmentAgencyPaymentAccounts.length?true:false):false)&&<>
                      < >
                                <Divider />
                          {employmentAgencyPaymentAccounts.map((paymentAccountD)=>{
                            let paymentMethodD=getDataFromArray(
                              paymentMethods,
                              "id",
                              paymentAccountD.paymentMethodId,
                              "single"
                            )
                          return <Grid item xs={6} lg={3} justifyContent="center" flexDirection="column">
                            <div   className={"paymentBox"}>
                                <MKBox lineHeight={1} px={2}  pt={2} textAlign={"center"}  sx={{ boxShadow: 2 }} 
                                onClick={()=>{
                                  setSelectedPaymentAccount(paymentAccountD)
                                  setPaymentPage(2);

                                }}
                                                      >
                                {/* <MKBox lineHeight={1} p={direction === "center" ? 2 : 0} textAlign={direction}> */}
                                      
                                        <MKTypography
                                          display="block"
                                          variant={"h2"}
                                          // variant={direction === "center" ? "h2" : "h3"}
                                          // color={color}
                                          textGradient
                                        >
                                          {" "}
                                          <Avatar src={paymentMethodD.logo} 
                                            sx={{ width: 56, height: 56 }}> 
                                                                    </Avatar>{" "}
                                        </MKTypography>
                                      
                                      <MKTypography
                                        display="block"
                                        variant="5"
                                        fontWeight="bold"
                                        mt={1 }
                                        // mt={direction === "center" ? 1 : 2}
                                        mb={1.5}
                                      >
                                        {paymentMethodD.shortName}
                                      </MKTypography>
                                      {/* <MKTypography
                                        display="block"
                                        variant={small ? "button" : "body2"}
                                        color="text"
                                        pr={direction === "left" ? 6 : 0}
                                        pl={direction === "right" ? 6 : 0}
                                      >
                                        {"description"}
                                      </MKTypography> */}
                                    </MKBox>
</div>

                                {/* <List
                                
                                Item>
                                  <ListItemAvatar>
                                    <Avatar src={paymentMethodD.logo}> 
                                    </Avatar>
                                  </ListItemAvatar>
                                  <ListItemText primary={paymentMethodD.shortName} secondary={paymentMethodD.name}  />
                                </List> */}
                                {/* <Divider /> */}
                                </Grid>})}
                              </>

                        </>}
                              </Grid>

                        </MKBox>
                        </Grid>
 
                  </Grid>
                </MKBox>
              </MKBox>
                </>}
             {((paymentPage==2)&& (selectedPaymentAccount!=null))&&
               <>
                <MKBox py={1} px={{ md: 4, lg: 4, xl: 6 }}>
 
              <MKTypography variant="body2" color="danger" textAlign="center" mb={2}>  
               {"Pay on the below ! "}</MKTypography>
                </MKBox>

              <MKBox component="form">

                <MKBox py={1} px={{ md: 4, lg: 4, xl: 6 }}>
                    <Grid container spacing={3} sx={{ mb: 3 ,py:1}}>
                    <Grid item xs={12} lg={12} justifyContent="center" flexDirection="column">
                      <MKBox
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="column"
                        shadow="lg"
                        borderRadius="xl"
                        sx={{
                          backgroundColor: ({ functions: { rgba }, palette: { white } }) =>
                            rgba(white.main, 0.8),
                          backdropFilter: "saturate(200%) blur(30px)",
                          px: { xs: 3, sm: 6 },
                          py: { xs: 3, sm: 2 },
                          // mb: { xs: 0, lg: 8 },
                          mt: { xs: 0, lg: 1 },
                        }}
                      >
                        <MKTypography variant="h6" textAlign="center" mb={2}>
                        {"2/ Make Payment"}
                        </MKTypography>
                        
                        <MKBox style={{overflow:"auto", maxHeight:"250px"}} > 
                          <MKTypography variant="subtitle" color="text" >
                          <small>
                           {"  You can pay via the below payment method by using mobile banking, bank branch office or agent :"}
                                </small>
                            </MKTypography> 
                        </MKBox>

                        {/* <MKTypography variant="body2" color="danger" textAlign="left" mb={2}>  
                      {"Payment Methods! "}</MKTypography> */}

                        {((employmentAgencyPaymentAccounts&&selectedPaymentAccount)?(employmentAgencyPaymentAccounts.length?true:false):false)&&<>
                      <List sx={{ width: '100%' , bgcolor: 'background.paper' }}>
                                <Divider /> 
                                <ListItem>
                                  <ListItemAvatar>
                                    <Avatar 
                                    src={selectedPaymentAccount.paymentMethodId?getDataFromArray(
                              paymentMethods,
                              "id",
                              selectedPaymentAccount.paymentMethodId,
                              "single"
                            ).logo:""}
                                    >
                                      {/* <ImageIcon /> */}
                                    </Avatar>
                                  </ListItemAvatar>
                                  <ListItemText primary={selectedPaymentAccount.accountOwnerFullName} secondary={selectedPaymentAccount.accountOwnerNumber}  />
                                </ListItem>
                                <Divider /> 
                              </List>

                        </>}

                        </MKBox>
                        </Grid>

                        <Grid item xs={12} lg={12} justifyContent="center" flexDirection="column">
                      <MKBox
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="column"
                        shadow="lg"
                        borderRadius="xl"
                        sx={{
                          backgroundColor: ({ functions: { rgba }, palette: { white } }) =>
                            rgba(white.main, 0.8),
                          backdropFilter: "saturate(200%) blur(30px)",
                          px: { xs: 3, sm: 6 },
                          py: { xs: 3, sm: 2 },
                          // mb: { xs: 0, lg: 8 },
                          mt: { xs: 0, lg: 1 },
                        }}
                      >
                        {/* <MKTypography variant="h6" textAlign="center" mb={2}>
                        {"2/ Confirm Your Payment"}
                        </MKTypography> */}
                        <MKBox style={{overflow:"auto", maxHeight:"250px"}} > 
                          <MKTypography variant="subtitle" color="text" >
                          <small>
                                        {"After making The Transfer to the Above Account please Use the below link to confirm your payment"}
                                </small>
                            </MKTypography> 
                        </MKBox>
                    <MKButton
                        variant="gradient"
                        color="success"
                        // fullWidth 
                        
                        // disable={loading||(newSeekerJobs.length<1)}
                        disabled={loading||(!acceptInstruction)}
                        onClick={() => {
                          setPaymentPage(3);
                        }}
                      >
                        Confirm Payment
                      </MKButton> 

                        </MKBox>
                        
                     
                        </Grid>

  
                    
                   {/* {(loginResponse || !isFormValid||loading) &&
                   <Grid item xs={12}>
                      {(loginResponse || !isFormValid) && (
                        <MKBox mt={3} mb={1} textAlign="center">
                          {loginResponse && <small>{loginResponse.message}</small>}
                          {!isFormValid && <small>{invalidResponse}</small>}
                        </MKBox>
                      )}
                      {loading && (
                        <MKBox mt={3} mb={1} textAlign="center">
                          <CircularProgress size={24} />{" "}
                        </MKBox>
                      )}
                    </Grid>} */}


 
                  </Grid>
                </MKBox>
              </MKBox>
                </>}
             {(paymentPage==3)&& <>
                <MKBox py={1} px={{ md: 4, lg: 4, xl: 6 }}>

              {/* <MKTypography variant="h6" color="danger" textAlign="center" mb={2}>  
               {"Payment Confirmation! "}</MKTypography> */}
              <MKTypography variant="body2" color="danger" textAlign="center" mb={2}>  
               {"Fill the payment information "}</MKTypography>
                </MKBox>

              <MKBox component="form">

                <MKBox py={1} px={{ md: 4, lg: 4, xl: 6 }}>
                    <Grid container spacing={3} sx={{ mb: 3 ,py:1}}>
                        <Grid item xs={12} lg={12} justifyContent="center" flexDirection="column">
                      <MKBox
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="column"
                        shadow="lg"
                        borderRadius="xl"
                        sx={{
                          backgroundColor: ({ functions: { rgba }, palette: { white } }) =>
                            rgba(white.main, 0.8),
                          backdropFilter: "saturate(200%) blur(30px)",
                          px: { xs: 3, sm: 6 },
                          py: { xs: 3, sm: 2 },
                          // mb: { xs: 0, lg: 8 },
                          mt: { xs: 0, lg: 1 },
                        }}
                      >
                        <MKTypography variant="h6" textAlign="center" mb={2}>
                        {"3/ Confirm Your Payment"}
                        </MKTypography>
                        <MKBox  style={{overflow:"auto", maxHeight:"250px"}} > 
                          <MKTypography variant="subtitle" color="text" >
                          <small>
                                        {"Complete the payment information form below to complete your payment process. you can get the info from your bank massages or mobile banking system"}
                                </small>
                            </MKTypography> 
                        </MKBox>
 <MKBox   sx={{ px: { xs: 3, sm: 6 }, mt:2 , py: { xs: 3, sm: 2 }, boxShadow: 2 ,width:"100%"}} >
                        <Grid container spacing={3} sx={{ mb: 3 ,py:1}}>
                          <Grid item xs={12} sx={{ my: 1 }}>
                              <MKInput
                                variant="standard"
                                type="email"
                                placeholder="eg. abebe desta bekele"
                                label="Transered By (Full Name )"
                                onChange={(e) => {
                                  setTranseredByFullName(e.target.value);
                                }}
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                              />
                            </Grid>
                          <Grid item xs={12} sx={{ my: 1 }}>
                              <MKInput
                                variant="standard"
                                type="email"
                                // placeholder="eg. extremeTutor@gmail.com"
                                label="Transaction ID ( Txn ID)"
                                onChange={(e) => {
                                  setTransactionId(e.target.value);
                                }}
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                              />
                            </Grid>   
                          <Grid item xs={12} sx={{ my: 1 }}>
                              <MKInput
                                variant="standard"
                                type="date"
                                placeholder="eg. extremeTutor@gmail.com"
                                label="Payment Date"
                                onChange={(e) => {
                                  setTransactionDate(e.target.value);
                                }}
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                              />
                            </Grid> 

</Grid>    

                    <MKButton
                        variant="gradient"
                        color="success"
                        fullWidth 
                        
                        // disable={loading||(newSeekerJobs.length<1)}
                        disabled={loading||(!transeredByFullName||!transactionDate||!transactionId)}
                        onClick={() => {
                          addUserToWebsite();
                        }}
                      >
                        Confirm Payment
                      </MKButton>  </MKBox>

                        </MKBox>
                        
                     
                        </Grid>

  
                     
                      {(confirmationResponseMessage ) && (
                        <Grid item xs={12}>
                              <MKBox mt={3} mb={1} textAlign="center"> 
                                <small>{confirmationResponseMessage}</small> 
                              </MKBox>
                          </Grid> 
                      )}

                      {loading && (
                        <Grid item xs={12}>
                              <MKBox mt={3} mb={1} textAlign="center">
                                <CircularProgress size={24} />{" "}
                              </MKBox>
                          </Grid> 
                      )}

 
                  </Grid>
                </MKBox>
              </MKBox>
                </>}
  
             {(paymentPage==4)&& <>
                {/* {<> */}
                <MKBox py={1} px={{ md: 4, lg: 4, xl: 6 }}>

              {/* <MKTypography variant="h6" color="danger" textAlign="center" mb={2}>  
               {"Payment Confirmation! "}</MKTypography> */}
              {/* <MKTypography variant="body2" color="danger" textAlign="center" mb={2}>  
               {"Fill the payment information "}</MKTypography> */}
                </MKBox>

              <MKBox component="form">

                <MKBox py={1} px={{ md: 4, lg: 4, xl: 6 }}>
                    <Grid container spacing={3} sx={{ mb: 3 ,py:1}}>
                        <Grid item xs={12} lg={12} justifyContent="center" flexDirection="column">
                      <MKBox
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="column"
                        shadow="lg"
                        borderRadius="xl"
                        sx={{
                          backgroundColor: ({ functions: { rgba }, palette: { white } }) =>
                            rgba(white.main, 0.8),
                          backdropFilter: "saturate(200%) blur(30px)",
                          px: { xs: 3, sm: 6 },
                          py: { xs: 3, sm: 2 },
                          // mb: { xs: 0, lg: 8 },
                          mt: { xs: 0, lg: 1 },
                        }}
                      >
                        <MKTypography variant="h6" textAlign="center" mb={2}>
                        {" Confirmtion request sent!"}
                        </MKTypography>
                        <MKBox  style={{overflow:"auto", maxHeight:"250px"}} > 
                          <MKTypography variant="subtitle" color="text" >
                          <small>
                          {confirmationResponseMessage}     </small>
                            </MKTypography> 
                        </MKBox>
 <MKBox   sx={{ px: { xs: 3, sm: 6 }, mt:2 , py: { xs: 3, sm: 2 },    width:"100%"}} >
                    
                    <MKButton
                        variant="gradient"
                        color="primary"
                        sx={{ mx:3}}
                        
                        // disable={loading||(newSeekerJobs.length<1)}
                        disabled={loading||(!transeredByFullName||!transactionDate||!transactionId)}
                        onClick={() => {
                         navigate("/");
                        }}
                      >
                        Home
                      </MKButton> 

                    <MKButton 
                        sx={{ mx:3}}
                        variant="gradient"
                        color="primary"
                          
                        
                        // disable={loading||(newSeekerJobs.length<1)}
                        disabled={loading||(!transeredByFullName||!transactionDate||!transactionId)}
                        onClick={() => {
                           navigate("/profile");
                        }}
                      >
                        Profile
                      </MKButton>  </MKBox>

                        </MKBox>
                        
                     
                        </Grid> 

 
                  </Grid>
                </MKBox>
              </MKBox>
                </>}


            </MKBox>
          </Grid>
        {forceRerender}
        </Grid>
      </Container>
    </MKBox>
  );
}

export default AgencyRegistrationFeePayment;
