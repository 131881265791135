/* eslint-disable no-param-reassign */
/**
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { Fragment, useState, useEffect } from "react";

// react-router components
import { Link, useNavigate } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Container from "@mui/material/Container";
import Icon from "@mui/material/Icon";
import Popper from "@mui/material/Popper";
import Grow from "@mui/material/Grow";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import MuiLink from "@mui/material/Link";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

// Material Kit 2 PRO React examples
import DefaultNavbarDropdown from "examples/Navbars/DefaultNavbar/DefaultNavbarDropdown";
import DefaultNavbarMobile from "examples/Navbars/DefaultNavbar/DefaultNavbarMobile";

// Material Kit 2 PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";
import { useCurrentUser } from "Api-contexts/currentUserContext";
import MKAvatar from "components/MKAvatar";
import {
  Avatar,
  IconButton,
  InputBase,
  ListItemIcon,
  Menu,
  MenuItem,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import { GroupWorkSharp, Logout, Search, SettingsApplications, Work } from "@mui/icons-material";
import { UseAuth } from "Api-Services/useAuth";
import { isMobile } from "react-device-detect";
import { CommonServices } from "Api-Services/commonServices";

function DefaultNavbar({ brand, routes, transparent, light, action, sticky, relative, center }) {
  const [dropdown, setDropdown] = useState("");
  const [dropdownEl, setDropdownEl] = useState("");
  const [dropdownName, setDropdownName] = useState("");
  const [nestedDropdown, setNestedDropdown] = useState("");
  const [nestedDropdownEl, setNestedDropdownEl] = useState("");
  const [nestedDropdownName, setNestedDropdownName] = useState("");
  const [arrowRef, setArrowRef] = useState(null);
  const [mobileNavbar, setMobileNavbar] = useState(false);
  const [mobileView, setMobileView] = useState(false);

  const [currentData] = useCurrentUser();
  const { currentUser,isUserLoggedIn,myAgencies,myJobSeekerInformation,agencyData,isAgentWebsite } = currentData; 
  const openMobileNavbar = () => {setMobileNavbar(!mobileNavbar); setMobileView(!mobileNavbar)};

  useEffect(() => {
    // A function that sets the display state for the DefaultNavbarMobile.
    function displayMobileNavbar() {
      if (window.innerWidth < breakpoints.values.lg) {
        setMobileView(true);
        setMobileNavbar(false);
      } else {
        setMobileView(true);
        setMobileNavbar(false);
      }
    }

    /** 
     The event listener that's calling the displayMobileNavbar function when 
     resizing the window.
    */
    window.addEventListener("resize", displayMobileNavbar);

    // Call the displayMobileNavbar function to set the state with the initial value.
    displayMobileNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", displayMobileNavbar);
  }, []);

  const [searchKeyword, setSearchKeyword] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  // const open = Boolean(anchorEl);
  const handleClick = (event,field) => {
    setOpen(field)
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setOpen("")
    setAnchorEl(null);
  };

  const navigate = useNavigate();
  const goToPage = (page) => {
    setAnchorEl(null);
    navigate(page);
  };
  
  const searchByKeyword = async () => {
    try { 
      let pathSearch; 
       if(searchKeyword){
        // jobSearchData = jobSearchFieldsandValue;
        pathSearch = { keyword:searchKeyword };
        pathSearch = CommonServices.encryptLocalData(pathSearch);
        let path = "/pages/jobs?search=" + pathSearch; //+"&employmentAgencyId="+jobSeeker.employmentAgencyId
        navigate(path); 
        }  
        } catch (error) {  
      }
  };


  const getProfileImage = () => {
      let res=""
      try {
      if( myJobSeekerInformation){
        if(  myJobSeekerInformation.profileImageUrl){
          res= myJobSeekerInformation.profileImageUrl
        }
       } 
      if((res=="")&&currentUser){
           if(currentUser.user){
              if(currentUser.user.userFrontImage ){
                res=currentUser.user.userFrontImage 
              }
            }
        }
        
      } catch (error) {
        
      }
        return res
    }  

  const getUserFirstName = () => {
      let res=""
      try { 
      if( myJobSeekerInformation){
        if(  myJobSeekerInformation.firstName){
          res= myJobSeekerInformation.firstName
        }
       } 
      if((res=="")&&currentUser){
           if(currentUser.userInformation){
              if(currentUser.userInformation.firstName ){
                res=currentUser.userInformation.firstName 
              }
            }
        }
      } catch (error) {
        
      }
        return res
    }  


  const didIJoinedCurrentAgency = () => {
    let res=false
    try {
      if(agencyData&&myAgencies){
        // console.log("agencyData&&myAgencies",agencyData,myAgencies)
          if(myAgencies.length){
            myAgencies.forEach(element => {
              if(agencyData.id==element.id){
                res=true
              }
            });
          }
      }
    } catch (error) {
      
    }

    return res 
  };

  const logout = () => {
    setAnchorEl(null);
  };
  const renderNavbarItems = routes.map(({isAllowedForAgent, name, icon, href, route, collapse }) => {
    let routeD=  <DefaultNavbarDropdown
      key={name}
      name={name}
      icon={icon}
      href={href}
      route={route}
      collapse={Boolean(collapse)}
      onMouseEnter={({ currentTarget }) => {
        if (collapse) {
          setDropdown(currentTarget);
          setDropdownEl(currentTarget);
          setDropdownName(name);
        }
      }}
      onMouseLeave={() => collapse && setDropdown(null)}
      light={light}
    />

      if(agencyData&&isAgentWebsite ){
        if(isAllowedForAgent){
         return routeD
        }
      }
      else{
         return routeD
      }
    });

  // Render the routes on the dropdown menu
  const renderRoutes = routes.map(({ name, collapse, columns, rowsPerColumn }) => {
    let template;

    // Render the dropdown menu that should be display as columns
    if (collapse && columns && name === dropdownName) {
      const calculateColumns = collapse.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index / rowsPerColumn);

        if (!resultArray[chunkIndex]) {
          resultArray[chunkIndex] = [];
        }

        resultArray[chunkIndex].push(item);

        return resultArray;
      }, []);

      template = (
        <Grid key={name} container spacing={3} py={1} px={1.5}>
          {calculateColumns.map((cols, key) => {
            const gridKey = `grid-${key}`;
            const dividerKey = `divider-${key}`;

            return (
              <Grid key={gridKey} item xs={12 / columns} sx={{ position: "relative" }}>
                {cols.map((col, index) => (
                  <Fragment key={col.name}>
                    <MKTypography
                      display="block"
                      variant="button"
                      fontWeight="bold"
                      textTransform="capitalize"
                      py={1}
                      px={0.5}
                      mt={index !== 0 ? 2 : 0}
                    >
                      {col.name}
                    </MKTypography>
                    {col.collapse.map((item) => (
                      <MKTypography
                        key={item.name}
                        component={item.route ? Link : MuiLink}
                        to={item.route ? item.route : ""}
                        href={item.href ? item.href : (e) => e.preventDefault()}
                        target={item.href ? "_blank" : ""}
                        rel={item.href ? "noreferrer" : "noreferrer"}
                        minWidth="11.25rem"
                        display="block"
                        variant="button"
                        color="text"
                        textTransform="capitalize"
                        fontWeight="regular"
                        py={0.625}
                        px={2}
                        sx={({ palette: { grey, dark }, borders: { borderRadius } }) => ({
                          borderRadius: borderRadius.md,
                          cursor: "pointer",
                          transition: "all 300ms linear",

                          "&:hover": {
                            backgroundColor: grey[200],
                            color: dark.main,
                          },
                        })}
                      >
                        {item.name}
                      </MKTypography>
                    ))}
                  </Fragment>
                ))}
                {key !== 0 && (
                  <Divider
                    key={dividerKey}
                    orientation="vertical"
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "-4px",
                      transform: "translateY(-45%)",
                      height: "90%",
                    }}
                  />
                )}
              </Grid>
            );
          })}
        </Grid>
      );

      // Render the dropdown menu that should be display as list items
    } else if (collapse && name === dropdownName) {
      template = collapse.map((item) => {
        const linkComponent = {
          component: MuiLink,
          href: item.href,
          target: "_blank",
          rel: "noreferrer",
        };

        const routeComponent = {
          component: Link,
          to: item.route,
        };

        return (
          <MKTypography
            key={item.name}
            {...(item.route ? routeComponent : linkComponent)}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            variant="button"
            textTransform="capitalize"
            minWidth={item.description ? "14rem" : "12rem"}
            color={item.description ? "dark" : "text"}
            fontWeight={item.description ? "bold" : "regular"}
            py={item.description ? 1 : 0.625}
            px={2}
            sx={({ palette: { grey, dark }, borders: { borderRadius } }) => ({
              borderRadius: borderRadius.md,
              cursor: "pointer",
              transition: "all 300ms linear",

              "&:hover": {
                backgroundColor: grey[200],
                color: dark.main,

                "& *": {
                  color: dark.main,
                },
              },
            })}
            onMouseEnter={({ currentTarget }) => {
              if (item.dropdown) {
                setNestedDropdown(currentTarget);
                setNestedDropdownEl(currentTarget);
                setNestedDropdownName(item.name);
              }
            }}
            onMouseLeave={() => {
              if (item.dropdown) {
                setNestedDropdown(null);
              }
            }}
          >
            {item.description ? (
              <MKBox>
                {item.name}
                <MKTypography
                  display="block"
                  variant="button"
                  color="text"
                  fontWeight="regular"
                  sx={{ transition: "all 300ms linear" }}
                >
                  {item.description}
                </MKTypography>
              </MKBox>
            ) : (
              item.name
            )}
            {item.collapse && (
              <Icon
                fontSize="small"
                sx={{ fontWeight: "normal", verticalAlign: "middle", mr: -0.5 }}
              >
                keyboard_arrow_right
              </Icon>
            )}
          </MKTypography>
        );
      });
    }

    return template;
  });

  // Routes dropdown menu
  const dropdownMenu = (
    <Popper
      anchorEl={dropdown}
      popperRef={null}
      open={Boolean(dropdown)}
      placement="top-start"
      transition
      style={{ zIndex: 10 }}
      modifiers={[
        {
          name: "arrow",
          enabled: true,
          options: {
            element: arrowRef,
          },
        },
      ]}
      onMouseEnter={() => setDropdown(dropdownEl)}
      onMouseLeave={() => {
        if (!nestedDropdown) {
          setDropdown(null);
          setDropdownName("");
        }
      }}
    >
      {({ TransitionProps }) => (
        <Grow
          {...TransitionProps}
          sx={{
            transformOrigin: "left top",
            background: ({ palette: { white } }) => white.main,
          }}
        >
          <MKBox borderRadius="lg">
            <MKTypography variant="h1" color="white">
              <Icon ref={setArrowRef} sx={{ mt: -3 }}>
                arrow_drop_up
              </Icon>
            </MKTypography>
            <MKBox shadow="lg" borderRadius="lg" p={2} mt={2}>
              {renderRoutes}
            </MKBox>
          </MKBox>
        </Grow>
      )}
    </Popper>
  );

  // Render routes that are nested inside the dropdown menu routes
  const renderNestedRoutes = routes.map(({ collapse, columns }) =>
    collapse && !columns
      ? collapse.map(({ name: parentName, collapse: nestedCollapse }) => {
          let template;

          if (parentName === nestedDropdownName) {
            template =
              nestedCollapse &&
              nestedCollapse.map((item) => {
                const linkComponent = {
                  component: MuiLink,
                  href: item.href,
                  target: "_blank",
                  rel: "noreferrer",
                };

                const routeComponent = {
                  component: Link,
                  to: item.route,
                };

                return (
                  <MKTypography
                    key={item.name}
                    {...(item.route ? routeComponent : linkComponent)}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    variant="button"
                    textTransform="capitalize"
                    minWidth={item.description ? "14rem" : "12rem"}
                    color={item.description ? "dark" : "text"}
                    fontWeight={item.description ? "bold" : "regular"}
                    py={item.description ? 1 : 0.625}
                    px={2}
                    sx={({ palette: { grey, dark }, borders: { borderRadius } }) => ({
                      borderRadius: borderRadius.md,
                      cursor: "pointer",
                      transition: "all 300ms linear",

                      "&:hover": {
                        backgroundColor: grey[200],
                        color: dark.main,

                        "& *": {
                          color: dark.main,
                        },
                      },
                    })}
                  >
                    {item.description ? (
                      <MKBox>
                        {item.name}
                        <MKTypography
                          display="block"
                          variant="button"
                          color="text"
                          fontWeight="regular"
                          sx={{ transition: "all 300ms linear" }}
                        >
                          {item.description}
                        </MKTypography>
                      </MKBox>
                    ) : (
                      item.name
                    )}
                    {item.collapse && (
                      <Icon
                        fontSize="small"
                        sx={{ fontWeight: "normal", verticalAlign: "middle", mr: -0.5 }}
                      >
                        keyboard_arrow_right
                      </Icon>
                    )}
                  </MKTypography>
                );
              });
          }

          return template;
        })
      : null
  );

  // Dropdown menu for the nested dropdowns
  const nestedDropdownMenu = (
    <Popper
      anchorEl={nestedDropdown}
      popperRef={null}
      open={Boolean(nestedDropdown)}
      placement="right-start"
      transition
      style={{ zIndex: 10 }}
      onMouseEnter={() => {
        setNestedDropdown(nestedDropdownEl);
      }}
      onMouseLeave={() => {
        setNestedDropdown(null);
        setNestedDropdownName("");
        setDropdown(null);
      }}
    >
      {({ TransitionProps }) => (
        <Grow
          {...TransitionProps}
          sx={{
            transformOrigin: "left top",
            background: ({ palette: { white } }) => white.main,
          }}
        >
          <MKBox ml={2.5} mt={-2.5} borderRadius="lg">
            <MKBox shadow="lg" borderRadius="lg" py={1.5} px={1} mt={2}>
              {renderNestedRoutes}
            </MKBox>
          </MKBox>
        </Grow>
      )}
    </Popper>
  );

  return (
    <Container sx={sticky ? { position: "sticky", top: 0, zIndex: 10 } : null}>
      <MKBox
        py={1}
        px={{ xs: 4, sm: transparent ? 2 : 3, lg: transparent ? 0 : 2 }}
        my={relative ? 0 : 2}
        mx={relative ? 0 : 3}
        width={relative ? "100%" : "calc(100% - 48px)"}
        borderRadius="xl"
        shadow={transparent ? "none" : "md"}
        color={light ? "white" : "dark"}
        position={relative ? "relative" : "absolute"}
        left={0}
        zIndex={3}
        sx={({ palette: { transparent: transparentColor, white }, functions: { rgba } }) => ({
          backgroundColor: transparent ? transparentColor.main : rgba(white.main, 0.8),
          backdropFilter: transparent ? "none" : `saturate(200%) blur(30px)`,
        })}
      >
        <MKBox display="flex" justifyContent="space-between" alignItems="center">
          <MKBox
            component={Link}
            to="/"
            lineHeight={1}
            py={transparent ? 1.5 : 0.75}
            pl={relative || transparent ? 0 : { xs: 0, lg: 1 }}
          >
            
            {(isAgentWebsite)&&<>
            {/* // <img src="https://crm.ethiopianairlines.com/ic/builder/rt/ET_DCS_WORKING/live/webApps/dcs/version_6107194738138692335/resources/images/logo.png" alt="logo"  */}
           { (agencyData)&&
           <>
           {(agencyData.logo)?<img src={agencyData.logo}style={
              {
                // width: fontSizeSettings.brandLogoWidth?fontSizeSettings.brandLogoWidth+"px":"125px",
                verticalAlign: "middle",
                border: "0 white",
                position:"absolute",
                // margin:"-54px",
                marginLeft:"-18px",
                marginTop:"-23px",
                height:"70px"
              }
            } />:
              <MKTypography variant="button" fontWeight="bold" color={light ? "white" : "dark"}>
              {agencyData.brandName||agencyData.fullName}
            </MKTypography>  }


            </>}
          </>  }
            
            {(!isAgentWebsite)&&<>
            {/* // <img src="https://crm.ethiopianairlines.com/ic/builder/rt/ET_DCS_WORKING/live/webApps/dcs/version_6107194738138692335/resources/images/logo.png" alt="logo"  */}
            {/* <img src="https://crm.ethiopianairlines.com/ic/builder/rt/ET_DCS_WORKING/live/webApps/dcs/version_6107194738138692335/resources/images/logo.png" alt="logo" 
            style={
              {
                // width: fontSizeSettings.brandLogoWidth?fontSizeSettings.brandLogoWidth+"px":"125px",
                verticalAlign: "middle",
                border: "0 white",
                position:"absolute",
                // margin:"-54px",
                marginLeft:"-18px",
                marginTop:"-43px",
                height:"70px"
              }
            } /> */}
            <MKTypography variant="button" fontWeight="bold" color={light ? "white" : "dark"}>
              Megenagna
              </MKTypography>
          </>  }
          </MKBox>
          <MKBox
            color="inherit"
            display={{ xs: "none", lg: "flex" }}
            ml="auto"
            mr={center ? "auto" : 0}
          >
            
      <DefaultNavbarDropdown
        key={"home"}
        name={"home"} 
        route={"/"}
        collapse={false}   
        light={light} 

      > 
      </DefaultNavbarDropdown>
            {renderNavbarItems}

          </MKBox>
          {isUserLoggedIn ? (<>
            {/* {((myJobSeekerInformation==null)||(true))&&
            <MKBox ml={{ xs: "auto", lg: 0 }} mr={{  lg:1 }}>
                <MKButton
                    component={Link}
                    // to={"/join-agency"}
                    // onClick={()=>{goToPage(action.route)}}
                     
                    onClick={(e)=>handleClick(e,"join")}
                    variant={
                      action.color === "white" || action.color === "default"
                        ? "contained"
                        : "gradient"
                    }
                    color={action.color ? action.color : "info"}
                    size="small"
                  >
                    {"Join As"}
                  </MKButton>
            </MKBox>} */}
           
            <MKBox ml={{ xs: "auto", lg: 0 }}>

              
         {/* <MKBox
            // {...rest}
            mx={1}
            p={1}
            display="flex"
            alignItems="baseline"
            color={light ? "white" : "dark"}
            opacity={light ? 1 : 0.6}
            sx={{ cursor: "pointer", userSelect: "none" }}
            // {...(route && routeComponent)}
            // {...(href && linkComponent)}
          > */}


            {/* <Grid item xs={12} md={7} sx={{ mt: 2 }} alignItems={"right"}> */}
       
            {/* </Grid> */}

        {/* </MKBox> */}

              <MKBox sx={{ display: "flex" }}>
                {/* {currentUser.user.userName}  */}
                {/* <MKAvatar src={currentUser.user.userFrontImage} alt="Burce Mars"  shadow="xl" /> */}

                {/* <MKTypography
                  variant="button"
                  onClick={handleClick}
                  fontWeight="bold"
                  mt={1}
                  color={light ? "white" : "dark"}
                >
                  {currentUser.user?((currentUser.userInformation.firstName||currentUser.user.userName)+"").toUpperCase():"Profile "}
                </MKTypography> */}
           {  !isMobile&&<Paper
          // component="form"
          sx={{ p: '2px 4px',mr:"15px", display: 'flex', alignItems: 'center', width:"100%" }}
        >
          <IconButton sx={{ p: '10px' }} aria-label="menu">
            <Menu/>
          </IconButton>
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Search "
            value={searchKeyword} 
            
            onChange={(e)=>{
              setSearchKeyword(e.target.value) 
            }}
            onKeyDown={(event)=>{
              if (event.keyCode) {
                if (event.keyCode === 13) {searchByKeyword()}
                }
             }}
            type='text'  
            inputProps={{ 'aria-label': 'search google maps',
            // onKeyDown:(event)=>{
            //   if (event.keyCode) {
            //     if (event.keyCode === 13) {searchByKeyword()}
            //     }
            //  }
             }}
          />
          
          <IconButton
           disabled={!searchKeyword}
            onClick={()=>{searchByKeyword()}}
             type="button" sx={{ p: '10px' }} aria-label="search">
            <Search />
          </IconButton>
          {/* <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
          <IconButton color="primary" sx={{ p: '10px' }} aria-label="directions">
            <Directions />
          </IconButton> */}
             </Paper>}
                <MKTypography
                  variant="button"
                  onClick={(e)=>handleClick(e,"profile")}
                  fontWeight="bold"
                  mt={isMobile?1:2}
                  color={light ? "white" : "dark"}
                > 
                {getUserFirstName()}
                </MKTypography>
                {currentUser.user&&
                <Tooltip title="Account settings">
                  <IconButton                 
                   onClick={(e)=>handleClick(e,"profile")}
                    size="small"
                    sx={{ ml: 2 }}
                    aria-controls={open ? "account-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                  ><div style={{ width: "60px"}}>
                    <Avatar
                      style={{
                        // width: fontSizeSettings.brandLogoWidth?fontSizeSettings.brandLogoWidth+"px":"125px",
                        // verticalAlign: "middle",
                        // border: "0 white",
                        position:"absolute",
                        // marginLeft:"14px",  
                        marginTop:"-14px",  
                        // // height:"70px"
                      }}
                      src={getProfileImage() }
                      sx={{ width: 33 , height:33 ,}}
                    >
                    </Avatar></div>
                  </IconButton>
                </Tooltip>}
              </MKBox>
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open=="profile"}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <MenuItem onClick={() => goToPage("/profile?tab=0")}>
                  <Avatar
                    src={getProfileImage() }
                  />{" "}
                  Profile
                </MenuItem>
                
                {/* <Divider /> */}
                {(myJobSeekerInformation==null)&&
                <MenuItem onClick={() => goToPage("/profile")}>
                  <Avatar
                    src={currentUser.user ? currentUser.user.userFrontImage : "/"}
                  />{" "}
                  Register as a job seeker
                </MenuItem>  }
              {(myJobSeekerInformation)&&
                 <>
                 {(isAgentWebsite&&agencyData)&& <>
                  { (!(didIJoinedCurrentAgency()))&& 
                  <MenuItem onClick={() => goToPage("/join-agency")}>
                      <Avatar 
                        src={agencyData.logo?agencyData.logo : "/"}
                      />{" "}
                      {"Join "}{agencyData.brandName||agencyData.fullName}
                    </MenuItem>
                    }  
                  </>}
                </>}
                
                {(myJobSeekerInformation)&&
                <MenuItem onClick={() => goToPage("/profile/my-applications")}>
                  <Avatar >
                     <SettingsApplications/>
                    </Avatar>
                    {" "}
                  My Applications
                </MenuItem> }
                {(myJobSeekerInformation)&&
                <MenuItem onClick={() => goToPage("/profile?tab=1")}>
                  <Avatar >
                     <Work/>
                    </Avatar>
                    {" "}
                  My Careers
                </MenuItem> }
                <MenuItem onClick={() => goToPage("/profile?tab=2")}>
                <Avatar >
                     <GroupWorkSharp/>
                    </Avatar>{" "}
                        Joined Agencies
                      </MenuItem>
                <Divider />
                <MenuItem onClick={() => logout(UseAuth.logout())}>
                  <ListItemIcon>
                    <Logout fontSize="small" />
                  </ListItemIcon>
                  Logout
                </MenuItem>
              </Menu>
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open=="join"}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
              {(myJobSeekerInformation)&&
              <MenuItem onClick={() => goToPage("/profile")}>
                  <Avatar
                    src={currentUser.user ? currentUser.user.userFrontImage : "/"}
                  />{" "}
                  Join extreme tutor
                </MenuItem>}
                {/* <Divider /> */}
                {(myJobSeekerInformation==null)&&
                <MenuItem onClick={() => goToPage("/profile")}>
                  <Avatar
                    src={currentUser.user ? currentUser.user.userFrontImage : "/"}
                  />{" "}
                  Register as a job seeker
                </MenuItem> }
              </Menu>
            </MKBox>
            
            </>
          ) : (
            <MKBox ml={{ xs: "auto", lg: 0 }}>
              {action &&
                (action.type === "internal" ? (
                  <MKButton
                    component={Link}
                    to={action.route}
                    // onClick={()=>{goToPage(action.route)}}
                    variant={
                      action.color === "white" || action.color === "default"
                        ? "contained"
                        : "gradient"
                    }
                    color={action.color ? action.color : "info"}
                    size="small"
                  >
                    {action.label}
                  </MKButton>
                ) : (
                  <MKButton
                    component="a"
                    href={action.route}
                    // onClick={()=>{goToPage(action.route)}}
                    target="_blank"
                    rel="noreferrer"
                    variant={
                      action.color === "white" || action.color === "default"
                        ? "contained"
                        : "gradient"
                    }
                    color={action.color ? action.color : "info"}
                    size="small"
                  >
                    {action.label}
                  </MKButton>
                ))}  
            </MKBox>
          )}
          <MKBox
            display={{ xs: "inline-block", lg: "none" }}
            lineHeight={0}
            py={1.5}
            pr={1}
            color={transparent ? "white" : "inherit"}
            sx={{ cursor: "pointer" }}
            onClick={()=>{
            let path = "/pages/jobs?search"; //+"&employmentAgencyId="+jobSeeker.employmentAgencyId
            navigate(path); }
            }
          >
            <Icon fontSize="default">search</Icon>
          </MKBox>
          <MKBox
            display={{ xs: "inline-block", lg: "none" }}
            lineHeight={0}
            py={1.5}
            pl={1.5}
            color={transparent ? "white" : "inherit"}
            sx={{ cursor: "pointer" }}
            onClick={openMobileNavbar}
          >
            <Icon fontSize="default">{mobileNavbar ? "close" : "menu"}</Icon>
          </MKBox>
        </MKBox>
        <MKBox
          bgColor={transparent ? "white" : "transparent"}
          shadow={transparent ? "lg" : "none"}
          borderRadius="xl"
          px={transparent ? 2 : 0}
        >
          {mobileView && <DefaultNavbarMobile routes={routes} open={mobileNavbar} closeMobleMenu={()=>{openMobileNavbar()}}/>}
        </MKBox>
      </MKBox>
      {dropdownMenu}
      {nestedDropdownMenu}
    </Container>
  );
}

// Setting default values for the props of DefaultNavbar
DefaultNavbar.defaultProps = {
  brand: "Extreme Totors",
  transparent: false,
  light: false,
  action: false,
  sticky: false,
  relative: false,
  center: false,
};

// Typechecking props for the DefaultNavbar
DefaultNavbar.propTypes = {
  brand: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
  transparent: PropTypes.bool,
  light: PropTypes.bool,
  action: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      type: PropTypes.oneOf(["external", "internal"]).isRequired,
      route: PropTypes.string.isRequired,
      color: PropTypes.oneOf([
        "primary",
        "secondary",
        "info",
        "success",
        "warning",
        "error",
        "dark",
        "light",
        "default",
        "white",
      ]),
      label: PropTypes.string.isRequired,
    }),
  ]),
  sticky: PropTypes.bool,
  relative: PropTypes.bool,
  center: PropTypes.bool,
};

export default DefaultNavbar;
