 
import { useEffect } from "react";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Kit 2 PRO React themes
import theme from "assets/theme";
import Presentation from "layouts/pages/presentation";

// Material Kit 2 PRO React routes
import routes from "routes";
import HelpCenter from "pages/Support/HelpCenter"; 
import footerRoutes from "footer.routes";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import DefaultNavbar from "layouts/Navbars/DefaultNavbar";
import DefaultFooter from "layouts/Footers/DefaultFooter";
import customRoutes from "customRoutes";
import FooterThress from "layouts/sections/page-sections/footers/components/FooterThree";
import Rental from "pages/LandingPages/Rental";
import ExtremeLandingPage from "pages/LandingPages/Extreme";
import { CurrentUserProvider } from "Api-contexts/currentUserContext";
import NotificationBar from "examples/NotificationBar/NotificationBar";
///g-ga4 id=G-9WH6WP5G6X
export default function App() {
  const { pathname } = useLocation();

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  return (
    <ThemeProvider theme={theme}>
              <ToastContainer />

    <CurrentUserProvider>
      <CssBaseline />
      <NotificationBar/>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "https://www.creative-tim.com/product/material-kit-pro-react",
          label: "Register",
          color: "info",
        }}
        sticky//transparent
        // light
      />
      <Routes>
        {getRoutes(routes)}
        {getRoutes(customRoutes)}
        <Route path="/presentation" element={<Presentation />} />
        <Route path="/" element={<ExtremeLandingPage/>} />
        <Route path="*" element={<Navigate to="/" />} /> 
      </Routes>
      
      <MKBox pt={6} px={1} mt={6}>
        <FooterThress content={footerRoutes} />
      </MKBox>
      </CurrentUserProvider>
    </ThemeProvider>
  );
}
