 
import Icon from "@mui/material/Icon";

// Pages
import CoworkingPage from "layouts/pages/landing-pages/coworking"; 
import HelpCenter from "layouts/pages/support/help-center";
import ContactUs from "layouts/pages/support/contact-us";
import Faq from "layouts/pages/support/faq";
import Privacy from "layouts/pages/support/privacy";
import SingleArticle from "pages/Support/HelpCenter/sections/SingleArticle";
import AskQuestion from "pages/Support/HelpCenter/sections/AskQuestion";
// import TotorDetails from "pages/Search/Totor/TotorDetails"; 
import JobSeekerDetails from "pages/Search/SearchEmployee/components/JobSeeker/jobSeekerDetails"; 
// import SendTutorRequest from "pages/Search/Totor/sendTutorRequest";
import SignInBasicPage from "layouts/authentication/sign-in/basic";
import SignUpCoverPage from "layouts/authentication/sign-up/cover";
import ResetPasswordPage from "layouts/authentication/reset-password/cover";
import MyProfile from "pages/LandingPages/Extreme/components/User/MyProfile"; 
import JoinAgency from "pages/Support/JoinAgency";
import AgencyRegistrationFeePayment from "pages/Support/PayAgency/registrationFeePayment";
import SendHiringRequest from "pages/Search/SearchEmployee/components/Employee/SendHiringRequest";
import EmployeeDetails from "pages/Search/SearchEmployee/components/Employee/EmployeeDetails";
import JobDetails from "pages/Search/SearchJobs/Job/JobDetails";
 
import Contact from "pages/LandingPages/Extreme/sections/Contact";
import SearchEmployee from "pages/Search/SearchEmployee"; 
import SearchJob from "pages/Search/SearchJobs";  
import MyApplications from "pages/LandingPages/Extreme/components/User/MyApplications";

const customRoutes = [
  {
    name: "Article",
    route: "/pages/Article",
    component: <SingleArticle />,
  }, 
  
  {
    name: "Employee Details",
    route: "/pages/employee-details",
    component: <EmployeeDetails/>,
  },
   {
    // isAllowedForAgent: true,
    name: "Employees",
    route: "/pages/search",
    component: <SearchEmployee />,
  },

  {
    // isAllowedForAgent: true,
    name: "Jobs",
    route: "/pages/jobs",
    component: <SearchJob />,
  },
  {
      // isAllowedForAgent: true,
      name: "contact us",
      route: "/pages/support/contact-us",
      component: <Contact />,
    },
  
  // {
  //   name: "Job Details",
  //   route: "/pages/Job-Details",
  //   component: <JobDetails/>,
  // },
  
  {
    name: "JobSeeker-Details",
    route: "/pages/JobSeeker-Details",
    component: <JobSeekerDetails/>,
  },
  {
    name: "my-applications",
    route: "/profile/my-applications",
    component: <MyApplications/>,
  },
  
  {
    isAllowedForAgent: true,
    name: "Job Details",
    route: "/pages/jobs/job-details",
    component: <JobDetails />,
  },
  {
    name: "Agents",
    route: "/agents",
    component: <SearchEmployee />,
  },
  
  {
    name: "Send Tutor Request",
    route: "/pages/SendTutorRequest",
    component: <SendHiringRequest/>,
  },
  {
    name: "Ask Question",
    route: "/pages/AskQuestion",
    component: <AskQuestion />,
  }, 
  {
    name: "Login",
    route: "/login",
    component: <SignInBasicPage />,
  },
  {
    name: "Register",
    route: "/register",
    component: <SignUpCoverPage />,
  },
  {
    name: "Join Agency",
    route: "/join-agency", 
    component: <JoinAgency/>,
  },
  {
    name: "Agency Registration Fee Payment",
    route: "/Agency-Registration-Fee-Payment", 
    component: <AgencyRegistrationFeePayment/>,
  },
  {
    name: "reset password" ,
    route: "/reset-password",
    component: <ResetPasswordPage />,
  },
  {
    name: "reset password" ,
    route: "/profile",
    component: <MyProfile/>,
  },
  
];

export default customRoutes;
