/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon"; 
// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKAvatar from "components/MKAvatar";
import queryString from "query-string";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// Images
import { cities ,subCities,specificAreas} from "Api-master-datas/masterDatas.js";

import ReactGA from "react-ga4";
import profilePicture from "assets/images/bruce-mars.jpg";
import { Link, useNavigate } from "react-router-dom";
import { useCurrentUser } from "Api-contexts/currentUserContext";
import Card from "@mui/material/Card";
import React, { useEffect } from "react";
import { ArrowDropDown, ArrowDropDownSharp, Delete, Edit, ExpandMoreOutlined, Favorite, PersonPin, Phone, Visibility, VisibilityOff, Warning } from "@mui/icons-material";
import MKInput from "components/MKInput";
import {
  Autocomplete,
  Avatar,
  Badge,
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FilledInput,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Input,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  OutlinedInput,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  Tab,
  Tabs, 
  TextField, 
  styled,
} from "@mui/material";
import { HttpService } from "Api-Services/httpService"; 
import FileUploadComp from "components/common-components/fileUploadComp";
import axios from "axios";
import { CommonServices } from "Api-Services/commonServices";
import { isMobile } from "react-device-detect";
import { pink } from "@mui/material/colors";
import { UseAuth } from "Api-Services/useAuth";
const ratings = {
  0.5: [
    <Icon key={1}>star_outline</Icon>,
    <Icon key={2}>star_outline</Icon>,
    <Icon key={3}>star_outline</Icon>,
    <Icon key={4}>star_outline</Icon>,
    <Icon key={5}>star_outline</Icon>,
  ],
  1: [
    <Icon key={1}>star</Icon>,
    <Icon key={2}>star_outline</Icon>,
    <Icon key={3}>star_outline</Icon>,
    <Icon key={4}>star_outline</Icon>,
    <Icon key={5}>star_outline</Icon>,
  ],
  1.5: [
    <Icon key={1}>star</Icon>,
    <Icon key={2}>star_half</Icon>,
    <Icon key={3}>star_outline</Icon>,
    <Icon key={4}>star_outline</Icon>,
    <Icon key={5}>star_outline</Icon>,
  ],
  2: [
    <Icon key={1}>star</Icon>,
    <Icon key={2}>star</Icon>,
    <Icon key={3}>star_outline</Icon>,
    <Icon key={4}>star_outline</Icon>,
    <Icon key={5}>star_outline</Icon>,
  ],
  2.5: [
    <Icon key={1}>star</Icon>,
    <Icon key={2}>star</Icon>,
    <Icon key={3}>star_half</Icon>,
    <Icon key={4}>star_outline</Icon>,
    <Icon key={5}>star_outline</Icon>,
  ],
  3: [
    <Icon key={1}>star</Icon>,
    <Icon key={2}>star</Icon>,
    <Icon key={3}>star</Icon>,
    <Icon key={4}>star_outline</Icon>,
    <Icon key={5}>star_outline</Icon>,
  ],
  3.5: [
    <Icon key={1}>star</Icon>,
    <Icon key={2}>star</Icon>,
    <Icon key={3}>star</Icon>,
    <Icon key={4}>star_half</Icon>,
    <Icon key={5}>star_outline</Icon>,
  ],
  4: [
    <Icon key={1}>star</Icon>,
    <Icon key={2}>star</Icon>,
    <Icon key={3}>star</Icon>,
    <Icon key={4}>star</Icon>,
    <Icon key={5}>star_outline</Icon>,
  ],
  4.5: [
    <Icon key={1}>star</Icon>,
    <Icon key={2}>star</Icon>,
    <Icon key={3}>star</Icon>,
    <Icon key={4}>star</Icon>,
    <Icon key={5}>star_half</Icon>,
  ],
  5: [
    <Icon key={1}>star</Icon>,
    <Icon key={2}>star</Icon>,
    <Icon key={3}>star</Icon>,
    <Icon key={4}>star</Icon>,
    <Icon key={5}>star</Icon>,
  ],
};


const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-indicatorSpan': {
    maxWidth: 40,
    width: '100%',
    backgroundColor: '#635ee7',
  },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: 'none',
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
      backgroundColor: 'rgb(162 146 53 / 42%)' ,
    color: 'rgba(255, 255, 255, 0.7)',
    '&.Mui-selected': {
      color: '#fff',
      // fontWeight: theme.typography.fontWeightRegular,
      // fontSize: theme.typography.pxToRem(15),
      // backgroundColor: 'rgba(100, 95, 228, 1)',
      fontWeight: 600,
      fontSize: "1.22rem",
      backgroundColor: 'rgb(255 196 73)' 
    },
    '&.Mui-focusVisible': {
      backgroundColor: 'rgba(100, 95, 228, 0.32)',
    },
  }),
);



function MyProfile() {
  
  const navigate = useNavigate(); 
  const [currentData,updateContext] = useCurrentUser();
  const { currentUser ,myAgencyJobSeekerInformation,myJobSeekerInformation,educationLevels,agencyData, myAgencies, jobs } = currentData;
  const [expanded, setExpanded] = React.useState(false);
  const [warningExpanded, setWarningExpanded] = React.useState(false);
  const [expanded2, setExpanded2] = React.useState(false);
  const [loadingSave, setLoadingSave] = React.useState(false);
  const [loadingBuildJobSeeker, setLoadingBuildJobSeeker] = React.useState(false);
  const [userInformation, setUserInformation] = React.useState(null);
  const [user, setUser] = React.useState(null);
  const [profileSectionIndex, setProfileSectionIndex] = React.useState(0);
  const [forceRerender, setForceRerender] = React.useState(false);
  const [dropzoneFiles, setDropzoneFiles] = React.useState([]);
  const [openedDialog , setOpenedDialog ] = React.useState("");
  const [moreText, setMoreText] = React.useState({content:"",title:""});
  const [newWorkExperianceData , setNewWorkExperianceData ] = React.useState({
            description :  "",
            expectingSalaryPerHour: null,
            expectingSalaryPerMonth: null, 
            isAvailable: true,
            jobId: null, 
            workExperianceInMonth:null,
            skillsOrSubjects:[],
  });
  // user
  // {id: DataTypes.DOUBLE,
  // userFrontImage: DataTypes.TEXT,
  // userEmail: DataTypes.TEXT,
  // googleEmail: DataTypes.TEXT,
  // facebookId: DataTypes.TEXT,
  // userStatus: DataTypes.DOUBLE,

  // currentEducationLevel:DataTypes.DOUBLE ,
  // informationApproved:DataTypes.BOOLEAN ,
  // informationApprovedDate:DataTypes.TEXT ,
  // TotoringEducationLevelStart:DataTypes.DOUBLE ,
  // TotoringEducationLevelEnd:DataTypes.DOUBLE ,

  // isInstructor :DataTypes.BOOLEAN,
  // status :DataTypes.TEXT,
  // registeredDate :DataTypes.TEXT,
  const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 22,
    height: 22,
    border: `2px solid ${theme.palette.background.paper}`,
  }));

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname, title: "JobSeeker Profile" });
    if (currentUser.user) {
      setUser(currentUser.user);
      setUserInformation(currentUser.userInformation);
    }
    setForceRerender(!forceRerender)
  }, [currentUser,myAgencies]);
 

  useEffect(async() => {
    let isUserLoggidIn = await UseAuth.verifyUser()
    if(!isUserLoggidIn){
      navigate("/")
    }
  }, [currentUser]);

  useEffect(() => {
    if (location.search) {
      let locationSearch = queryString.parse(location.search);
      if (locationSearch.tab) { 
        setProfileSectionIndex(locationSearch.tab);
      }
    }

  }, [location.search,location.pathName]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  }; 

  const handleChangeExpanded2 = (panel) => {
    setExpanded2( panel);
    // if(!isExpanded){
    //   setExpanded(false);
    // }
  };


  const postDocuments = async (documentName, relativePath, data,additionalData) => {
    let responseD = { success: false, message: "", record: null };
    let documentsResponse = { success: false }; 
    documentsResponse = await HttpService.postService(relativePath, data, additionalData||{}, "");
    
    if (documentsResponse.data) {
      responseD.record = documentsResponse.data.record;
      responseD.message = documentsResponse.data.message;
    }
    if (documentsResponse.success) {
      responseD.success = true;
      let record = responseD.record;

      //write codtions down here//
    }
    else{
     responseD.record = documentsResponse.data;  
    } 
    return responseD; 
  };

  
  const getDocumentsAll = async (documentName, relativePath, additionalData) => {
    let responseD = { success: false, message: "", record: null };
    let documentsResponse = { success: false }; 
    documentsResponse = await HttpService.getService(
      relativePath,
      additionalData ? additionalData : {},
      ""
    );
    if (documentsResponse.data) {
      responseD.record = documentsResponse.data.record;
      responseD.message = documentsResponse.data.message;
    }

    if (documentsResponse.success) {
      responseD.success = true;
    }
    setForceRerender(!forceRerender);
    return responseD;
  };
  
  const getEducationLevelById = (id, rows, field) => {
    let res = {};
    if (educationLevels && id) {
      let filteredLevels = educationLevels.filter((level) => level.id == id);
      if (filteredLevels.length) {
        res = filteredLevels;
        if (rows == "single") {
          let row = filteredLevels[0];
          res = row;
          if (field) {
            let rowField = row[field];
            res = rowField;
          }
        }
      }
      // console.log("filtered educat error", res);
    }
    return res;
  };

  const getCityById = (city, rows, field) => {
    let res = {};
    try {
      if (cities && city) {
        let filteredLevels = cities.filter((level) => level.TOWN_NAME == city);
        if (filteredLevels.length) {
          res = filteredLevels;
          if (rows == "single") {
            let row = filteredLevels[0];
            res = row;
            if (field) {
              let rowField = row[field];
              res = rowField;
            }
          }
        }
      }
      // console.log("filtered city  ", res);
    } catch (error) {
      // console.log("filtered city error", error);
    }
    return res;
  };
 
  const getDataFromArray = (dataArray, searchField, searchValue, returnRows, returnField) => {
    let res =returnField?"": {}; 

    try {
      if (searchValue) {
      if (dataArray&&searchField&&searchValue) {
        if (dataArray.length>0) {
        let filteredLevels = dataArray.filter((level) => level[searchField] == searchValue);
          if (filteredLevels.length) {
            res = filteredLevels;
            if (returnRows == "single") {
              let row = filteredLevels[0];
              res = row;
              if (returnField) {
                let rowField = row[returnField];
                res = rowField;
              }
            }
           }
          }
        }
      }
      // console.log("filtered city  ", res);
    } catch (error) {
      // console.log("filtered city error", error);
    }
      // console.log("filtered city  ", res);
    return res;
  };

 
  const startBuildingJobSeeker = async () => {
    // console.log("data", dataD);

    try {
      if (user) { 
        let data={}
        let relativePath= "/api/employmentjobseeker/initializemyjobseekerinformation";   
        let refreshVariable="myJobSeekerInformation" 
         
        setLoadingBuildJobSeeker(true);
        let changeUserProfileRes = await postDocuments("changeUserInfo", relativePath, data);
        //  toast.update(toastId, { render: changeUserProfileRes.message, type: changeUserProfileRes.success?"success":"warn", isLoading: false , autoClose: 5000});
        setLoadingBuildJobSeeker(false); 
        if (changeUserProfileRes.success) {
          setExpanded(false)
          updateContext(refreshVariable)
        }
      }
    } catch (error) {}
  };

  const handleNewWorkExperianceData = async (field,value) => {
      try {
        let data=newWorkExperianceData
        if(field&&value){
          data[field]=value
          setNewWorkExperianceData(data)
          setForceRerender(!forceRerender)
        } 
      } catch (error) {
        
      }

  }

  const removeAgencyIJoined = async (agencyId) => {
// return
    try { 
    let ressss= await CommonServices.confirmPopUp('Remove','Are you sure to do this.') 
      // console.log("ressss",ressss)
      if(!ressss){
        return 
      }
      if (agencyId) { 
      let relativePath = "/api/employmentjobseeker/removeagencyijoined";
      let refreshVariable = "myAgencies"
 
        // setLoadingSave(true);
    // console.log("data", dataD,parentData, dataParentName,fieldType);
        let changeUserProfileRes = await postDocuments("changeUserInfo", relativePath, {employmentAgencyId:agencyId});
        //  toast.update(toastId, { render: changeUserProfileRes.message, type: changeUserProfileRes.success?"success":"warn", isLoading: false , autoClose: 5000});
        // setLoadingSave(false);

        if (changeUserProfileRes.success) {
          setExpanded(false)
          updateContext("myJobSeekerInformation")
          updateContext(refreshVariable)
        } 
      }
    } catch (error) {}
  };

  const deleteWorkExperiance = async (id) => {
// return
    try { 
    let ressss= await CommonServices.confirmPopUp('Delete','Are you sure to do this.') 
      // console.log("ressss",ressss)
      if(!ressss){
        return 
      }
      if (id) { 
      let relativePath = "/api/employmentjobseekerworkexperiance/delete/"+id;
      let refreshVariable = "myJobSeekerInformation"
 
        // setLoadingSave(true);
    // console.log("data", dataD,parentData, dataParentName,fieldType);
        let changeUserProfileRes = await getDocumentsAll("changeUserInfo", relativePath, {});
        //  toast.update(toastId, { render: changeUserProfileRes.message, type: changeUserProfileRes.success?"success":"warn", isLoading: false , autoClose: 5000});
        // setLoadingSave(false);

        if (changeUserProfileRes.success) {
          setExpanded(false)
          updateContext(refreshVariable)
        } 
      }
    } catch (error) {}
  };

  const addNewWorkExperiance = async () => {
    // console.log("data", dataD,parentData, dataParentName,fieldType);
// return
    try { 
      let data=newWorkExperianceData
      let relativePath = "/api/employmentjobseekerworkexperiance/new";
      let refreshVariable = "myJobSeekerInformation"
      if(data){ 
       data.jobSeekerId = myJobSeekerInformation.id;

       if(data.skillsOrSubjects){ 
        let skils=""
          if(data.skillsOrSubjects.length){ 
            data.skillsOrSubjects.forEach(element => {
              skils=skils+(skils?",":"")+element
            });
          } 
            data.skillsOrSubjects=skils
      }
    }

      if (data.jobSeekerId) {
        if(data){ 
        // setLoadingSave(true);
        let changeUserProfileRes = await postDocuments("changeUserInfo", relativePath, data);
        //  toast.update(toastId, { render: changeUserProfileRes.message, type: changeUserProfileRes.success?"success":"warn", isLoading: false , autoClose: 5000});
        // setLoadingSave(false);

        if (changeUserProfileRes.success) {
          setExpanded(false)
          setOpenedDialog("")
          updateContext(refreshVariable)
        }
       }
      }
    } catch (error) {}
  };


  const updateUserInformation = async (dataD, parentData, dataParentName,fieldType) => {
    // console.log("data", dataD,parentData, dataParentName,fieldType);
// return
    try {
      let id;
      let relativePath;
      let refreshVariable=""
      let value
      if(dataD){
        if(dataD.value){
        value=  dataD.value
        }
      }
    
      // if (dataParentName == "agencyjobseeker") {
      //   id = myJobSeekerInformation.id;
      //   relativePath = "/api/employmentagencyjobseeker/edit";
      //   refreshVariable="myJobSeekerInformation"
      // } 
      if (dataParentName == "jobseeker") {
        id = myJobSeekerInformation.id;
        relativePath = "/api/employmentjobseeker/edit";
        refreshVariable="myJobSeekerInformation"
      }
      if (dataParentName == "workExperiance") {
        id = parentData.id;
        relativePath = "/api/employmentjobseekerworkexperiance/edit";
        refreshVariable="myJobSeekerInformation"
      }
       else if (dataParentName == "userInformation") {
        id = userInformation.id;
        relativePath = "/api/employee/edit";
        refreshVariable="currentUser"
      }
       else if (dataParentName == "user") {
        id = user.id;
        relativePath = "/api/auth/edit";
        refreshVariable="currentUser"
      }

      if (relativePath && id) {
        let data = {
          id: id,
        };
        if("fileUrl"==fieldType){
          if(dataD.value){
          let filesD= await uploadFiles(dataD.value)
          if(filesD){
            if(filesD.length){
              if(filesD.length>0){
                value=filesD[0]
              }
            }
           }
          }
        }
        if("imageUrl"==fieldType){
          if(dataD.value){
          let filesD= await uploadFiles(dataD.value)
          if(filesD){
            if(filesD.length){
              if(filesD.length>0){
                value=filesD[0]
              }
            }
           }
          }
        }
        if(value){
        data[dataD.fieldName] = value;
        // console.log("data", data);

        // let toastId = toast.loading("Updating Profile data...",{
        //   position: toast.POSITION.BOTTOM_LEFT
        // })
        setLoadingSave(true);
        let changeUserProfileRes = await postDocuments("changeUserInfo", relativePath, data);
        //  toast.update(toastId, { render: changeUserProfileRes.message, type: changeUserProfileRes.success?"success":"warn", isLoading: false , autoClose: 5000});
        setLoadingSave(false);

        if (changeUserProfileRes.success) {
          setExpanded(false)
          updateContext(refreshVariable)
        }
       }
      }
    } catch (error) {}
  };

 
  
  const truncateString=(str, num, lableName) =>{
    let res=str
    try {
      if (str) {
        if (str.length > num) { 
        let btn= <Button variant="text" onClick={()=>{setMoreText({content:str,title:lableName}); setOpenedDialog("moreText"); setForceRerender(!forceRerender) }} >{"View more"}</Button>
        let txt=str.slice(0, num) + "..."
        res= <>{txt} {btn}</>;
        }  
    } 
  }catch (error) {
      // console.log("errrr",error)
    }
    return res
  }
   
   


  const truncateStringDefualt=(str, num, lableName) =>{
    let res=str
    try {
      if (str) {
        if (str.length > num) { 
        let txt=str.slice(0, num) + "..."
        res= <>{txt} </>;
        }  
    } 
  }catch (error) {
      // console.log("errrr",error)
    }
    return res
  }
    
    
  const editInputComponent = (parentData,
    parentFieldName,
    fieldName,
    fieldLabel,
    valueD,
    fieldType,
    options,
    optionsSelectedField,
    optionsSelectedLable,
    optionsGroupedBy
  ) => {
    // const editInputComponent = (fieldName, fieldLabel, valueD, fieldType, options, selectedField) => {
    // console.log("fieldName",fieldName, valueD);
    let res = <></>;
    let inputComp = <></>;
    let value = valueD;
    try {
      if (fieldType == "textArea") {
        inputComp = (
          <>
            {" "}  
               {/* <TextField
                id="standard-multiline-static"
                label={fieldLabel}
                defaultValue={value}
                onChange={(e) => {                 
                   console.log("aaa", e.target.value);

                  value = e.target.value;
                }} 
                variant="standard"
              /> */}
              <MKInput
                placeholder="Enter text"
                label={fieldLabel}
                multiline
                defaultValue={value}
                rows={4}
                onChange={(e) => {
                  // console.log("aaa", e.target.value);
                  value = e.target.value;
                }}
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
          </>
        );
      }
      if (fieldType == "textField" || fieldType == "email" || fieldType == "phone") {
        inputComp = (
          <>
            {" "}
            {value ? (
              <MKInput
                placeholder="Enter text"
                label={fieldLabel}
                defaultValue={value}
                onChange={(e) => {
                  // console.log("aaa", e.target.value);
                  value = e.target.value;
                }}
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            ) : (
              <MKInput
                placeholder="Enter text"
                label={fieldLabel}
                onChange={(e) => {
                  // console.log("aaa", e.target.value);
                  value = e.target.value;
                }}
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            )}
          </>
        );
      }
      if (fieldType == "number") {
        inputComp = (
          <>
            {" "}
            {value ? (
              <MKInput
                placeholder="Enter Number"
                label={fieldLabel}
                defaultValue={value} 
                inputProps={{type:"number"}}
                onChange={(e) => {
                  // console.log("aaa", e.target.value);
                  value = e.target.value;
                }}
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            ) : (
              <MKInput
                placeholder="Enter Number"
                inputProps={{type:"number"}}
                label={fieldLabel}
                onChange={(e) => {
                  // console.log("aaa", e.target.value);
                  value = e.target.value;
                }}
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            )}
          </>
        );
      } else if (fieldType == "radio") {
        inputComp = (
          <>
            <FormControl>
              <FormLabel id="demo-controlled-radio-buttons-group">{fieldLabel}</FormLabel>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                defaultValue={value}
                onChange={(e) => {
                  // console.log("aaa", e.target.value);
                  value = e.target.value;
                }}
              >
                {options && (
                  <>
                    {options.length ? (
                      <>
                        {options.map((option) => (
                          <FormControlLabel
                            value={optionsSelectedField ? option[optionsSelectedField] : option}
                            control={<Radio />}
                            label={optionsSelectedField ? option[optionsSelectedField] : option}
                          />
                        ))}
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </RadioGroup>
            </FormControl>
          </>
        );
      } else if (fieldType == "fileUrl") {
        // let currentIcon={ c:input.value }
        inputComp = (
          <>
            <FormControl variant="outlined" fullWidth>
              {/* <span style={{ marginRight: 10,  fontSize: 18,}}> 
                <i className={"fab "+input.value } />
            </span>  */}
            </FormControl>
            {/* <GridItem xs={12} sm={12} md={12}> */}
            <Divider variant="middle" />
            <h4 divider> {fieldLabel} </h4>
            <div className="container">
              <div className="row">
                <div className="col s12"> 
                  <FileUploadComp filesLimit={1} initialFiles={[value]}  handleDropZoneFiles={(files)=>{value=files}}/>
                  {/* <h1>mmm</h1> */}
                  {/* <ServerPhotoGallery fileType="document"  filesLimit={1} initialFiles={[value]} 
                  uplodedImages={(data)=>{
                        if(data){
                          if(data.length){
                               value =data[0]
                            // onInputChange(data[0],input)
                          }
                        } 
                  }}  
                            /> */}
                </div>
              </div>
            </div>
            {/* </GridItem>  */}
          </>
        );
      }
       else if (fieldType == "imageUrl") {
        // let currentIcon={ c:input.value }
        inputComp = (
          <>
            <FormControl variant="outlined" fullWidth>
              {/* <span style={{ marginRight: 10,  fontSize: 18,}}> 
                <i className={"fab "+input.value } />
            </span>  */}
            </FormControl>
            {/* <GridItem xs={12} sm={12} md={12}> */}
            <Divider variant="middle" />
            <h4 divider> {fieldLabel} </h4>
            <div className="container">
              <div className="row">
                <div className="col s12"> 
                  <FileUploadComp filesLimit={1} acceptedFiles={['image/*']} initialFiles={[value]}  handleDropZoneFiles={(files)=>{value=files}}/>
                  {/* <h1>mmm</h1> */}
                  {/* <ServerPhotoGallery fileType="document"  filesLimit={1} initialFiles={[value]} 
                  uplodedImages={(data)=>{
                        if(data){
                          if(data.length){
                               value =data[0]
                            // onInputChange(data[0],input)
                          }
                        } 
                  }}  
                            /> */}
                </div>
              </div>
            </div>
            {/* </GridItem>  */}
          </>
        );
      }
      else if (fieldType == "boolean")  {
        inputComp = (
          <>
            <FormControl>
              <FormLabel id="demo-controlled-radio-buttons-group">{fieldLabel}</FormLabel>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                defaultValue={value?true:false}
                onChange={(e) => {
                  // console.log("aaa", e.target.value);
                  value = e.target.value;
                }}
              > 
                          <FormControlLabel
                            value={ true }
                            control={<Radio />}
                            label= "Yes" 
                          /> 
                          <FormControlLabel
                            value={ false }
                            control={<Radio />}
                            label= "No" 
                          /> 
              </RadioGroup>
            </FormControl>
          </>
        );
              }
        else if (fieldType == "selectOption")  {
          inputComp = (
            <>
              <FormControl>
                <FormLabel id="demo-controlled-radio-buttons-group">{fieldLabel}</FormLabel>
                {/* <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  defaultValue={value?true:false}
                  onChange={(e) => {
                    // console.log("aaa", e.target.value);
                    value = e.target.value;
                  }}
                > 
                            <FormControlLabel
                              value={ true }
                              control={<Radio />}
                              label= "true" 
                            /> 
                            <FormControlLabel
                              value={ false }
                              control={<Radio />}
                              label= "false" 
                            /> 
                </RadioGroup> */}
                {(options)&&<Autocomplete fullWidth variant="standard"
                sx={{ width: 400 }}
                onChange={(e, val) => {
                    if(val){
                      if(val){
                      // console.log("val,id",val.id);
                       value=val;
                      // console.log("value",value);
                      }}
                }}
                options={options}
                // defaultValue={options?(optionsSelectedField?value[optionsSelectedLable]:value):""}
                getOptionLabel={(option) => optionsSelectedLable?option[optionsSelectedLable]:option}
                
                groupBy={(option) => optionsGroupedBy?option[optionsGroupedBy]:null} 
                renderInput={(params) => <MKInput {...params} variant="standard" />}
              />}
              </FormControl>
            </>
          );
        }
        else if(fieldType=="chipsText"){
          let chipNewText=""
          let chipsData=[]
          if(value){
            if(value.length){

              chipsData=value.split(",")
            }
          }

          inputComp = (
            <>
              <FormControl>
                <FormLabel id="demo-controlled-radio-buttons-group">{fieldLabel}</FormLabel>
            
             {/* <Autocomplete
                  multiple
                  id="tags-filled"
                  options={[]}
                  // options={top100Films.map((option) => option.title)}
                  defaultValue={chipsData}
                  // value={chipsData}
                  freeSolo   
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                    ))
                  }
                  renderInput={(params) => {
                    let newValue=""
                    console.log("params",params)
                  return<> 
                      <Input
                      
                      {...params}
                      value={newValue}
                      id="outlined-adornment-password" 
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            // onClick={handleClickShowPassword}
                            // onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                          <Visibility /> 
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Password"
                    /> 
                    
                    </> }
                  }
                />  */}

            <Autocomplete fullWidth
                  // sx={{ width: 400 }}
                  multiple
                  id="tags-filled"
                  options={[]}
                  // defaultValue={chipsData}
                  value={chipsData}
                      // defaultValue={newWorkExperianceData.skillsOrSubjects}
                  onChange={(e,data)=>{
                    // handleNewWorkExperianceData("skillsOrSubjects",data)
                        let skills=""
                          if(data.length){ 
                            data.forEach(element => {
                              skills=skills+(skills?",":"")+element
                            });
                          } 
                            value=skills 
                  }}
                  freeSolo 
                  renderTags={(value, getTagProps) =>  
                    value.map((option, index) => 
                      (
                      <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                    ) )  
                  }
                  renderInput={(params) => ( 
                    <TextField
                      {...params}
                      variant="filled"
                      label="Skills Or Subjects"
                      placeholder="Add Skill" 
                    />  
                  )}
                />  
               
              </FormControl>
            </>
          );
        }

      let accordionTitle = "value not assigned";
      if (fieldType == "fileUrl") {
        accordionTitle = (
          <Button
            href={value}
            target="_blank"
            onClick={() => {
              // setDocumentUrl(value);
              // setOpenedDialog("documentviewer")
            }}
            color="primary"
            round
            size="sm"
          >
            open file
          </Button>
        );
      }
      else if (fieldType == "imageUrl") {
        accordionTitle = (
          <Avatar
            src={value}   
          > 
          </Avatar>
        );
      }
      else if(fieldType=="chipsText"){
        let chipsData=[]
        if(value){
          if(value.length){
            
            chipsData=value.split(",")
          }
        }
        accordionTitle = (
          <>
            {chipsData.map((chipValue) => (
              <Chip
                label={ chipValue}
                variant="outlined"
              />
            ))}
          </>
        );
      }
       else if (fieldType == "chip") {
        accordionTitle = (
          <>
            {value.map((chipValue) => (
              <Chip
                label={optionsSelectedLable ? chipValue[optionsSelectedLable] : chipValue}
                variant="outlined"
              />
            ))}
          </>
        );
      }
      else if (fieldType == "boolean") {
        accordionTitle = (
          <>
            {value?"Yes":"No" }
          </>
        );
      } 
      //  else if (fieldType == "selectOption") {
      
      //   accordionTitle = (
      //     <>
      //       {value?getEducationLevelById(value, "single","title"):"Not selected" }
      //     </>
      //   );
      // } 
      else {
        if (value) {
          accordionTitle = optionsSelectedLable
            ? value[optionsSelectedLable]
              ? value[optionsSelectedLable]
              : ""
            : value;
            accordionTitle=truncateString(accordionTitle,50)
        }
      }

      res = (
        <>
          <Accordion expanded={expanded === fieldName} onChange={handleChange(fieldName)}>
            <AccordionSummary
              expandIcon={<Edit />}
              aria-controls="panel3bh-content"
              id="panel3bh-header"
            >
              
              <MKTypography sx={{ width: "43%", flexShrink: 0 }} variant="body2" color="text" fontWeight="regular" pl={1}>
              {fieldLabel}  </MKTypography>
              <MKTypography sx={{ color: "text.secondary" }} variant="body2" color="text" fontWeight="regular" pl={1}>
              <strong>{accordionTitle}</strong> </MKTypography>
              {/* <Typography sx={{ width: "43%", flexShrink: 0 }}>{fieldLabel}</Typography> */}
              {/* <Typography sx={{ color: "text.secondary" }}><strong>{accordionTitle}</strong></Typography> */}
            </AccordionSummary>
            <AccordionDetails>
              <Grid container alignItems="center">
                <Grid py={1} item xs={12} lg={6}>
                  {inputComp}
                </Grid>
                <Grid py={1} item xs={12} lg={6}>
                  <MKBox px={4}>
                    <Stack direction="row" spacing={2}>
                    <Box sx={{ m: 1, position: 'relative' }}>
        
                      <Button
                        variant="contained"
                        color="success"
                        disabled={loadingSave}
                        onClick={() => {
                          let dataDD = {};
                          if (fieldType == "chip") {
                            let valuesD = "";
                            value.forEach((val) => {
                              valuesD =
                                valuesD +
                                (optionsSelectedField ? val[optionsSelectedField] : val) +
                                ",";
                            });
                            dataDD = {
                              value: valuesD,
                              fieldName: fieldName,
                            };
                          } else {
                            dataDD = {
                              value: optionsSelectedField ? value[optionsSelectedField] : value,
                              fieldName: fieldName,
                            };
                          }
                          updateUserInformation(dataDD,parentData, parentFieldName,fieldType);
                          // console.log("value", value);
                        }}
                      >
                        Save
                      </Button>

                 
        {loadingSave && (
          <CircularProgress
            size={24}
            sx={{
              // color: green[500],
              position: 'absolute',
              top: '50%',
              left: '50%',
              marginTop: '-12px',
              marginLeft: '-12px',
            }}
          />
        )}
      </Box>
    





                      <Button
                        color="secondary"
                        onClick={() => {
                          setExpanded(false);
                        }}
                      >
                        Cancel
                      </Button>
                    </Stack>
                  </MKBox>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </>
      );
    } catch (error) {
      // console.log("inp comp", error);
    }
    return res;
  };

  
  const didIJoinedCurrentAgency = () => {
    let res = false;
      // console.log("agencyData&&myAgencies",agencyData,myAgencies)
    if (agencyData && myAgencies) {
      if (myAgencies.length) {
        myAgencies.forEach((element) => {
          if (agencyData.id == element.id) {
            res = true;
          }
        });
      }
    }

    return res;
  };
  
  const uploadFiles = async (filesD,filePathD) => {
    // console.log("uploadFiles",filesD,filePathD);
    let res=[]
    let dropzoneTempImages1=filesD||dropzoneFiles; 
    try {
      let filePath="./public/files/users/user_25/new-folder3" 
      const formData = new FormData();
      _.filter(dropzoneTempImages1, file => {
          // formData.append("folderPath", uploadingFolderPath);
          formData.append("folderPath", filePath);
          formData.append("file", file);
          return file;
      });

     let getHeaderAndURLRes=await HttpService.getHeaderAndURL()  
     let baseUrl=getHeaderAndURLRes.baseUrl
     let additionalData={
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        // onUploadProgress: progressEvent => {
        //   if(progressEvent){
        //     // console.log("progressEvent", Math.round((progressEvent.loaded * 100) / progressEvent.total))
        //       setuploadPercentage(parseInt(
        //       Math.round((progressEvent.loaded * 100) / progressEvent.total)
        //         ))
        //         }
        // }
      }
  

      let uploadFileRes = await postDocuments("uploadFile", "/api/file/photos", formData,additionalData);
      if(uploadFileRes){
        if(uploadFileRes.record){
        if(uploadFileRes.record.length){
          uploadFileRes.record.forEach(imgFile => {
                // var destination=imgFile.destination.replace('./public',"");
                var destination=imgFile.destination.replace('./public',"/public");
                var filename="/"+imgFile.filename 
                var pathN=baseUrl+destination+filename; 
                var imgpath=pathN
                res.push(imgpath) 
              }); 
    }}}
      // console.log("uploadFileRes  ",uploadFileRes);
      // console.log("uploadFileRes res",res);
        // await axios.post(baseUrl+"/api/file/photos", formData,{additionalData})
        // .then(function (response) {
        //   let imgs=[]  
        //   // console.log("file  dropzoneval, uploaded",dropzoneTempImages1,response.data);
        //   response.data.forEach(imgFile => {
        //     var destination=imgFile.destination.replace('./public',"");
        //     var filename="/"+imgFile.filename 
        //     var pathN=baseUrl+destination+filename; 
        //     var imgpath=pathN
        //     imgs.push(imgpath) 
        //   });
        //   // props.uplodedImages(imgs,props.uploadingImageType);
        //     // setuploadedFiles([...uploadedFiles, ...imageFiles])  
        // })
        // .catch(function (error) { 
        // });  
      
    } catch (err) { 
      // console.log("uploadFiles err",err);

    }
    return res
  }

  function WarningsComp(){
    let res=<></>

    let warningsListComp=<>

    {((!myJobSeekerInformation))?
       <Grid container item xs={12} justifyContent="center" mx="auto">
      

       <Grid container item xs={12} py={1}  justifyContent="center">
         <Card sx={{ width: "100%" }}>
           <Grid container alignItems="center">
             <Grid item xs={12} lg={4}>
               <MKBox p={3} textAlign="center">
                 <MKTypography variant="h6" mt={{ xs: 0, sm: 3 }}>
                 Build Job Information
                 </MKTypography>
                 <MKTypography variant="h1">
                   {"Job Info "}
                   {/* <MKBox component="small">{" Month(s)"}</MKBox> */}
                 </MKTypography>
                 <MKButton
                   disabled={loadingBuildJobSeeker} 
                   variant="gradient"
                   color={"success" }
                   size="large"
                   sx={{ my: 2 }}
                   onClick={()=>{startBuildingJobSeeker()}}
                 >{loadingBuildJobSeeker?"Building":"Start Building"}
                 </MKButton>
                 <Box sx={{ m: 1, position: 'relative' }}>
        
                     {loadingBuildJobSeeker && (
                     <CircularProgress
                     size={24}
                     sx={{
                     // color: green[500],
                     position: 'absolute',
                     top: '50%',
                     left: '50%',
                     marginTop: '-12px',
                     marginLeft: '-12px',
                     }}
                     />
                     )}
                 </Box>
                 <MKTypography
                   display="block"
                   variant="button"
                   color="text"
                   fontWeight="regular"
                 >
                   {  "Build your Career Info" }
                 </MKTypography>
               </MKBox>
             </Grid>
             <Grid item xs={12} lg={8}>
               <MKBox py={3} px={4}>
                 <MKTypography variant="h3" mb={1}>
                   Job Information Building Process Includes:
                 </MKTypography>
                 {/* <MKTypography variant="body2" color="text" fontWeight="regular">
                   You have Free Unlimited Updates and Premium Support on each package. You also
                   have 30 days to request a refund.
                 </MKTypography> */}
                 <Grid container item xs={12} lg={3} sx={{ mt: 6, mb: 1 }}>
                   <MKTypography variant="h6">Processes</MKTypography>
                 </Grid>
                 <Grid container spacing={3}> 
                     <Grid item xs={12} md={12}>
                       <MKBox display="flex"  pr={1}  >
                         <MKTypography variant="body1" color="dark">
                           <Icon sx={{ fontWeight: "bold" }}>done</Icon>
                         </MKTypography>
                         <MKTypography variant="body2" color="text" fontWeight="regular" pl={1}>
                           {"Put your correct personal information"}
                         </MKTypography>
                       </MKBox>
                     </Grid> 
                     <Grid item xs={12} md={12}>
                       <MKBox display="flex"  >
                         <MKTypography variant="body1" color="dark">
                           <Icon sx={{ fontWeight: "bold" }}>done</Icon>
                         </MKTypography>
                         <MKTypography variant="body2" color="text" fontWeight="regular" pl={1}>
                           {"Put your correct career information"}
                         </MKTypography>
                       </MKBox>
                     </Grid> 
                     <Grid item xs={12} md={12}>
                       <MKBox display="flex"  >
                         <MKTypography variant="body1" color="dark">
                           <Icon sx={{ fontWeight: "bold" }}>done</Icon>
                         </MKTypography>
                         <MKTypography variant="body2" color="text" fontWeight="regular" pl={1}>
                           {"Put your correct job experiance and education background"}
                         </MKTypography>
                       </MKBox>
                     </Grid> 
                     <Grid item xs={12} md={12}>
                       <MKBox display="flex"  >
                         <MKTypography variant="body1" color="dark">
                           <Icon sx={{ fontWeight: "bold" }}>done</Icon>
                         </MKTypography>
                         <MKTypography variant="body2" color="text" fontWeight="regular" pl={1}>
                           {"Find employee agency you want and join as a job seeker"}
                         </MKTypography>
                       </MKBox>
                     </Grid> 
                 </Grid>
               </MKBox>
             </Grid>
           </Grid>
            
         </Card>
       </Grid>
       
        </Grid>:
        <>
        { (agencyData)&&<>
        { ((!didIJoinedCurrentAgency()))?<Grid container item xs={12} py={1} justifyContent="center">
        <Card sx={{ width: "100%" }} >
        <MKBox
          display="flex"
          // my={24}
          py={1}
          sx={{
            backgroundImage: ({ palette: { gradients }, functions: { linearGradient, rgba } }) => `${linearGradient(rgba(gradients.dark.main, 0.8), rgba(gradients.dark.state, 0.8))},`,
          }}
        >
          <Container>
            <Grid container alignItems="center">
              <Grid item xs={12} md={8} lg={8}>
                <MKTypography variant="subtitle2"  color="warning"    fontWeight="bold">
                { "You did not join '"+ agencyData.brandName+"' yet! "}
                </MKTypography>
                <MKTypography variant="body2" color="text" >
                <small>
                    {"To start working with "+ agencyData.brandName+ ", you must accept the privacy policy, terms and conditions and follow the instructions."}
                
                  </small>  </MKTypography>
                {/* <MKTypography variant="body2" color="text" mb={3}>
                  {"Click the button below to view the instructions and accept the rules." }
                </MKTypography> */}
              </Grid>
              <Grid item xs={12} lg={3} sx={{ ml: "auto" }}>
                <MKBox width="12rem" ml="auto">
                  <MKButton variant="gradient" color="warning" component={Link}     to={"/join-agency"}
                  fullWidth sx={{ boxShadow: "none" }}>
                  {"Join " + agencyData.brandName}
                  </MKButton>
                </MKBox>
              </Grid>
            </Grid>
          </Container>
        </MKBox>
        </Card>
        </Grid>:<>
        
        { (myAgencyJobSeekerInformation?(myAgencyJobSeekerInformation.isActive?false:true):true)&&<>
          
        <Grid container item xs={12} py={1}  justifyContent="center">
        <Card sx={{ width: "100%" }} >
        <MKBox
          display="flex"
          // my={24}
          py={1}
          sx={{
            backgroundImage: ({ palette: { gradients }, functions: { linearGradient, rgba } }) => `${linearGradient(rgba(gradients.dark.main, 0.8), rgba(gradients.dark.state, 0.8))},`,
          }}
        >
          <Container>
            <Grid container alignItems="center">
              <Grid item xs={12} md={8} lg={8}>
                <MKTypography variant="subtitle2"  color="warning"   fontWeight="bold"> 
                {"Your "+(agencyData.brandName||agencyData.fullName)+" membership is inactive!"}
                </MKTypography>
                <MKTypography variant="body2" color="text" >
                <small>
                  {"Dear "+ myJobSeekerInformation.firstName+ ", You have joined an  "+(agencyData.brandName||agencyData.fullName)+", but your account has not yet been activated due to payment or certain settings."}
                
                  </small>  </MKTypography>
                {/* <MKTypography variant="body2" color="text" mb={3}>
                  {"Click the button below to view the instructions and accept the rules." }
                </MKTypography> */}
              </Grid>
              <Grid item xs={12} lg={3} sx={{ ml: "auto" }}>
                <MKBox width="12rem" ml="auto">
                  <MKButton variant="gradient" size="small"  color="success" component={Link}     to={"/join-agency"}
                  fullWidth sx={{ boxShadow: "none" }}>
                  {"view instructions" }
                  </MKButton>
                </MKBox>
              </Grid>
            </Grid>
          </Container>
        </MKBox>
        </Card>
        </Grid> 
        </>}
        </>}


        
        </>}
        

        { (myJobSeekerInformation.workExperiances?(myJobSeekerInformation.workExperiances.length?((myJobSeekerInformation.workExperiances.length>0)?false:true):true):true)&&<>
          
        <Grid container item xs={12} py={1} justifyContent="center">
        <Card sx={{ width: "100%" }} >
        <MKBox
          display="flex"
          // my={24}
          py={1}
          sx={{
            backgroundImage: ({ palette: { gradients }, functions: { linearGradient, rgba } }) => `${linearGradient(rgba(gradients.dark.main, 0.8), rgba(gradients.dark.state, 0.8))},`,
          }}
        >
          <Container>
            <Grid container alignItems="center">
              <Grid item xs={12} md={8} lg={8}>
                <MKTypography variant="subtitle2" color="warning"  fontWeight="bold">
                { "No professions have been added yet!"}
                </MKTypography>
                <MKTypography variant="body2" color="text" >
                 <small>
                    {"Dear "+ myJobSeekerInformation.firstName+ ", you have not added any profession or work experience! Please fill in your information to increase your chances of being hired "}
                </small>
                  </MKTypography>
                {/* <MKTypography variant="body2" color="text" mb={3}>
                  {"Click the button below to view the instructions and accept the rules." }
                </MKTypography> */}
              </Grid>
              <Grid item xs={12} lg={3} sx={{ ml: "auto" }}>
                <MKBox width="12rem" ml="auto">
                  <MKButton variant="gradient" size="small" color="success" component={Link}  onClick={()=>{
                     setWarningExpanded(false);
                    setProfileSectionIndex(1)}}  
                  fullWidth sx={{ boxShadow: "none" }}>
                  {"Add your Professions" }
                  </MKButton>
                </MKBox>
              </Grid>
            </Grid>
          </Container>
        </MKBox>
        </Card>
        </Grid> 
        </>}
        </>
      }
    </>
      
    res=<>
     <Grid item xs={12} md={8} sx={{ ml: 2 }}>
        <MKTypography variant="h6" color="warning" 
          onClick={() => {
            setWarningExpanded(!warningExpanded); 
          }} >
          {"2 Warnings "}
          <Warning/>
        </MKTypography>
      </Grid>
      <Card
        sx={{
          my: 2,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
          overflow: "hidden",
        }}
      >
   {(warningExpanded)&&<>
      {warningsListComp}
       </>}
         <Accordion
          expanded={warningExpanded}
          onChange={() => {
            setWarningExpanded(!warningExpanded); 
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreOutlined />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <MKTypography variant="h6" opacity={0.4}>
              {warningExpanded ? "Hide Warning" : "See Warning"}
            </MKTypography>
          </AccordionSummary>
        </Accordion> 
    </Card>
    
    </>


    return res

  }

  return (
    <MKBox component="section" py={{ xs: 6, sm: 6 }}>
      <Container>
      {/* <Grid container item xs={12}  justifyContent="center"> */}
      
      {/* <WarningsComp/> */}
      {/* <Grid item xs={12} md={8} sx={{ ml: 2 }}>
        <MKTypography variant="h6" color="warning" 
          onClick={() => {
            setWarningExpanded(!warningExpanded); 
          }} >
          {"2 Warnings "}
          <Warning/>
        </MKTypography>
      </Grid> */}
      <Grid   py={1} pb={1}   xs={12} justifyContent="center" mx="auto">
        <MKBox 
                    // alt={name} 
                    borderRadius="md"
                    shadow="lg"
                  > {" "}
      <Grid pb={1} xs={12} justifyContent="center" mx="auto"> 
              <StyledTabs  value={profileSectionIndex||0} onChange={(e,val)=>{ setProfileSectionIndex(val)}} aria-label="icon label tabs example">
                <StyledTab  icon={<PersonPin />} label="Profile" />
                <StyledTab icon={<PersonPin />} label="My Professions" />
             { (!(agencyData||myAgencyJobSeekerInformation))&&  <StyledTab icon={< Phone/>} label="Agencies" />}
                {/* <Tab icon={<Favorite />} label="Applied Jobs" /> */}
              </StyledTabs>
      </Grid>
            </MKBox>
      </Grid>
      
      <Card
        sx={{
          my: 2,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
          overflow: "hidden",
        }}
      >
        {(!myJobSeekerInformation)&&
       <Grid container item xs={12} justifyContent="center" mx="auto">
      

       <Grid container item xs={12} py={1}  justifyContent="center">
         <Card sx={{ width: "100%" }}>
           <Grid container alignItems="center">
             <Grid item xs={12} lg={4}>
               <MKBox p={3} textAlign="center">
                 <MKTypography variant="h6" mt={{ xs: 0, sm: 3 }}>
                 Build Job Information
                 </MKTypography>
                 <MKTypography variant="h1">
                   {"Job Info "}
                   {/* <MKBox component="small">{" Month(s)"}</MKBox> */}
                 </MKTypography>
                 <MKButton
                   disabled={loadingBuildJobSeeker} 
                   variant="gradient"
                   color={"success" }
                   size="large"
                   sx={{ my: 2 }}
                   onClick={()=>{startBuildingJobSeeker()}}
                 >{loadingBuildJobSeeker?"Building":"Start Building"}
                 </MKButton>
                 <Box sx={{ m: 1, position: 'relative' }}>
        
                     {loadingBuildJobSeeker && (
                     <CircularProgress
                     size={24}
                     sx={{
                     // color: green[500],
                     position: 'absolute',
                     top: '50%',
                     left: '50%',
                     marginTop: '-12px',
                     marginLeft: '-12px',
                     }}
                     />
                     )}
                 </Box>
                 <MKTypography
                   display="block"
                   variant="button"
                   color="text"
                   fontWeight="regular"
                 >
                   {  "Build your Career Info" }
                 </MKTypography>
               </MKBox>
             </Grid>
             <Grid item xs={12} lg={8}>
               <MKBox py={3} px={4}>
                 <MKTypography variant="h3" mb={1}>
                   Job Information Building Process Includes:
                 </MKTypography>
                 {/* <MKTypography variant="body2" color="text" fontWeight="regular">
                   You have Free Unlimited Updates and Premium Support on each package. You also
                   have 30 days to request a refund.
                 </MKTypography> */}
                 <Grid container item xs={12} lg={3} sx={{ mt: 6, mb: 1 }}>
                   <MKTypography variant="h6">Processes</MKTypography>
                 </Grid>
                 <Grid container spacing={3}> 
                     <Grid item xs={12} md={12}>
                       <MKBox display="flex"  pr={1}  >
                         <MKTypography variant="body1" color="dark">
                           <Icon sx={{ fontWeight: "bold" }}>done</Icon>
                         </MKTypography>
                         <MKTypography variant="body2" color="text" fontWeight="regular" pl={1}>
                           {"Put your correct personal information"}
                         </MKTypography>
                       </MKBox>
                     </Grid> 
                     <Grid item xs={12} md={12}>
                       <MKBox display="flex"  >
                         <MKTypography variant="body1" color="dark">
                           <Icon sx={{ fontWeight: "bold" }}>done</Icon>
                         </MKTypography>
                         <MKTypography variant="body2" color="text" fontWeight="regular" pl={1}>
                           {"Put your correct career information"}
                         </MKTypography>
                       </MKBox>
                     </Grid> 
                     <Grid item xs={12} md={12}>
                       <MKBox display="flex"  >
                         <MKTypography variant="body1" color="dark">
                           <Icon sx={{ fontWeight: "bold" }}>done</Icon>
                         </MKTypography>
                         <MKTypography variant="body2" color="text" fontWeight="regular" pl={1}>
                           {"Put your correct job experiance and education background"}
                         </MKTypography>
                       </MKBox>
                     </Grid> 
                     <Grid item xs={12} md={12}>
                       <MKBox display="flex"  >
                         <MKTypography variant="body1" color="dark">
                           <Icon sx={{ fontWeight: "bold" }}>done</Icon>
                         </MKTypography>
                         <MKTypography variant="body2" color="text" fontWeight="regular" pl={1}>
                           {"Find employee agency you want and join as a job seeker"}
                         </MKTypography>
                       </MKBox>
                     </Grid> 
                 </Grid>
               </MKBox>
             </Grid>
           </Grid>
            
         </Card>
       </Grid>
       
        </Grid>}
        </Card> 


      

       {(profileSectionIndex==0)&&
        <Grid container item xs={12} justifyContent="center" mx="auto">
          {userInformation && (
            <Grid container justifyContent="center" py={3}>
               {(user)&& 
               <Grid item xs={12} md={4}>
                <MKBox textAlign="left">
                  {/* <MKAvatar src={profilePicture} alt="Burce Mars" size="xxl" shadow="xl" /> */}
                  <MKBox
                    component="img" 
                    // alt={name}
                    // width="50%"
                    borderRadius="md"
                    shadow="lg"
                  /> 
                <Badge
                  overlap="circular"
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  badgeContent={
                    <SmallAvatar alt="Remy Sharp" 
                    sx={{ width: 50, height:50 }} src={
                     user.userFrontImage
                       ? user.userFrontImage
                       : profilePicture
                    }/>
                  }
                >
                  <Avatar alt="Travis Howard" 
                     sx={{ width: 120, height: 120 }} src={
                      myJobSeekerInformation
                        ? myJobSeekerInformation.profileImageUrl
                        : profilePicture
                    }/>
                </Badge>
                </MKBox>



              </Grid>}

             {(myJobSeekerInformation)&&
              <Grid item xs={12} md={8}>
                <MKBox justifyContent="space-between" alignItems="center" mb={1}>
                 { (myJobSeekerInformation.firstName)&&
                 <MKTypography variant="h3">
                    {myJobSeekerInformation.firstName
                      ? myJobSeekerInformation.firstName + " " + myJobSeekerInformation.lastName
                      : "Michael Roven"}
                  </MKTypography>}

                 {(myJobSeekerInformation.currentEducationLevelEducatedField )&& <MKTypography variant="h6" mb={1}>
                    {myJobSeekerInformation.currentEducationLevelEducatedField +
                      " , " +
                      myJobSeekerInformation.currentEducationLevelEducatedSchool}
                  </MKTypography>}

                  {/* <MKTypography
                    variant="h4"
                    // color={color === "transparent" || color === "light" ? "text" : "white"}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      ml: 0.375,

                      "& .material-icons-round": {
                        ml: -0.375,
                      },
                    }}
                  >
                    {ratings[5]}
                  </MKTypography> */}
                </MKBox>
                {/* <Grid container spacing={3} mb={3}>
                  <Grid item>
                    <MKTypography component="span" variant="body2" fontWeight="bold">
                      323&nbsp;
                    </MKTypography>
                    <MKTypography component="span" variant="body2" color="text">
                      Posts
                    </MKTypography>
                  </Grid>
                  <Grid item>
                    <MKTypography component="span" variant="body2" fontWeight="bold">
                      3.5k&nbsp;
                    </MKTypography>
                    <MKTypography component="span" variant="body2" color="text">
                      Followers
                    </MKTypography>
                  </Grid>
                  <Grid item>
                    <MKTypography component="span" variant="body2" fontWeight="bold">
                      260&nbsp;
                    </MKTypography>
                    <MKTypography component="span" variant="body2" color="text">
                      Following
                    </MKTypography>
                  </Grid>
                </Grid> */}
                <MKTypography variant="body1" fontWeight="light" color="text">
                  {truncateString(myJobSeekerInformation.description,isMobile?100:300,"Description")}
                  <MKTypography
                    component="a"
                    href="#"
                    variant="body1"
                    fontWeight="light"
                    color="info"
                    mt={3}
                    sx={{
                      width: "max-content",
                      display: "flex",
                      alignItems: "center",

                      "& .material-icons-round": {
                        transform: `translateX(3px)`,
                        transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                      },

                      "&:hover .material-icons-round, &:focus .material-icons-round": {
                        transform: `translateX(6px)`,
                      },
                    }}
                  ></MKTypography>
                </MKTypography>
              </Grid>}


              {(user&&agencyData && (myAgencyJobSeekerInformation?(myAgencyJobSeekerInformation.isActive?false:true):false)) && (
            <Grid container item xs={12} pt={3} justifyContent="center">
              <Card sx={{ width: "100%" }}>
                <Grid container alignItems="center">
                  <Grid  p={1} item xs={12} lg={12}>
                    <MKBox py={1} px={4}>
                      <MKTypography variant="h6" mb={1}>
                       {agencyData.brandName}
                      </MKTypography> 

                      </MKBox>
                    <MKBox pb={{sm: 0, md:1} } px={{sm: 2, md:3} } > 
                        <List > 
                        { (!myAgencyJobSeekerInformation.isActive)&&  <ListItem 
                            secondaryAction={ <MKButton size={"small"} to="/pages/support/contact-us"  component={Link} color="warning">
                            Contact Us
                          </MKButton>

                            }
                          > 
                            <ListItemText
                             
                             primary={myAgencyJobSeekerInformation.isActive?"Active" :"Inactive"  }
                             secondary={ 'Account Status'  }
                            />
                          </ListItem> }
                          <Divider/>
                        { (!myAgencyJobSeekerInformation.registrationFeePaid && agencyData.requireRegistrationFee)&& 
                         <ListItem
                            secondaryAction={ <MKButton size={"small"}   to={"/Agency-Registration-Fee-Payment"}component={Link}  color="warning">
                            Pay
                          </MKButton> 
                            }
                          > 
                            <ListItemText
                             primary={myAgencyJobSeekerInformation.registrationFeePaid?"Paid" :"Not Paid"  }
                             secondary ={"Registration payment"  }
                            />
                          </ListItem> }
                      </List>

                    </MKBox>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          )} 

{(user&&myJobSeekerInformation) && (
            <Grid container item xs={12} pt={3} justifyContent="center">
              <Card sx={{ width: "100%" }}>
                <Grid container alignItems="center">
                  <Grid  p={1} item xs={12} lg={12}>
                    <MKBox py={1} px={4}>
                      <MKTypography variant="h6" mb={1}>
                       {"Jobs and Work Experiances"}
                      </MKTypography> 

                      </MKBox>
                    <MKBox pb={{sm: 0, md:1} } px={{sm: 2, md:3} } > 
                        <List > 
                      
                          {/* <Divider/> */}
                {(myJobSeekerInformation )&&
                         <ListItem
                            secondaryAction={ <MKButton size={"small"}  onClick={()=>{
                              setProfileSectionIndex(1)
                            }}  color="warning">
                         
                           {myJobSeekerInformation.workExperiances?(
                            myJobSeekerInformation.workExperiances.length?("Manage") :"Add" ):"Add" }
                          
                          </MKButton> 
                            }
                          > 
                            <ListItemText
                             primary={myJobSeekerInformation.workExperiances?(
                              myJobSeekerInformation.workExperiances.length?(myJobSeekerInformation.workExperiances.length+" professions") :"No Professions added" ):"No Professions added" }
                             secondary ={"My Professioins"  }
                            />
                          </ListItem> }
                      </List>

                    </MKBox>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          )}


              {user && myJobSeekerInformation && (
            <Grid container item xs={12} pt={3} justifyContent="center">
              <Card sx={{ width: "100%" }}>
                <Grid container alignItems="center">
                  <Grid item xs={12} lg={12}>
                    <MKBox py={1} px={3} > 

                      <MKTypography variant="h5" mb={1}>
                        Personal Informations
                      </MKTypography>
                    </MKBox>
                    <MKBox py={{sm: 0, md:1} } px={{sm: 0, md:3} } > 
                      {/* educationalDocumentAttachementUrl */}
                      {editInputComponent(
                       
 myJobSeekerInformation,
 "jobseeker",
                        "profileImageUrl",
                        "Profile Image",
                        myJobSeekerInformation.profileImageUrl,
                        "imageUrl"
                      )}
                      {editInputComponent(
                       
 myJobSeekerInformation,
 "jobseeker",
                        "firstName",
                        "First Name",
                        myJobSeekerInformation.firstName,
                        "textField"
                      )}
                      {editInputComponent(
                       
 myJobSeekerInformation,
 "jobseeker",
                        "middleName",
                        "Middle Name",
                        myJobSeekerInformation.middleName,
                        "textField"
                      )}
                      {editInputComponent(
                       
 myJobSeekerInformation,
 "jobseeker",
                        "lastName",
                        "Last Name",
                        myJobSeekerInformation.lastName,
                        "textField"
                      )}
                      {editInputComponent(
                       
 myJobSeekerInformation,
 "jobseeker",
                        "email",
                        "Contact Email",
                        myJobSeekerInformation.email,
                        "email"
                      )}
                      {editInputComponent(
                       
 myJobSeekerInformation,
 "jobseeker",
                        "phone",
                        "Contact Phone",
                        myJobSeekerInformation.phone,
                        "phone"
                      )}
                      {editInputComponent(
                       
 myJobSeekerInformation,
 "jobseeker",
                        "sex",
                        "Sex",
                        myJobSeekerInformation.sex,
                        "radio",
                        ["male", "female"]
                      )}
                      
                      {editInputComponent( 
                       
                       myJobSeekerInformation,
                       "jobseeker",
                       "description",
                       "Description about your experiances",
                       myJobSeekerInformation.description,
                       "textArea"
                     )}
                      <MKTypography  variant="h6" mt={2} style={{color:"darkgreen"}} mb={2} mx={2}>
                        Address
                      </MKTypography>
                      {editInputComponent(
                       
 myJobSeekerInformation,
 "jobseeker",
                        "country",
                        "Country",
                        myJobSeekerInformation.country,
                        "textField"
                      )} 
                      {editInputComponent(
                       
 myJobSeekerInformation,
 "jobseeker",
                        "city",
                        "City",
                        cities?getCityById(myJobSeekerInformation.city, "single"):"" ,
                        "selectOption",
                        cities,
                        "TOWN_NAME",
                        "TOWN_NAME",
                        "REGION"
                      )}
                    { (myJobSeekerInformation.city)&& <>
                   { (myJobSeekerInformation.city.toLowerCase()=="addis ababa")&&
                   <>
                     {(subCities)&&<>
                     {editInputComponent(
                       
 myJobSeekerInformation,
 "jobseeker",
                        "subCity",
                        "Sub City", 
                        getDataFromArray(subCities,"title", myJobSeekerInformation.subCity , "single"),
                        "selectOption",
                        subCities,
                        "title",
                        "title"
                      )} 
                      </>}
                     {(specificAreas)&&<>
                      {editInputComponent(
                       
 myJobSeekerInformation,
 "jobseeker",
                        "specificArea",
                        "Specific Area",
                        getDataFromArray(specificAreas,"title", myJobSeekerInformation.specificArea , "single"),
                        "selectOption",
                        specificAreas,
                        "title",
                        "title"
                      )}
                      </>}
                      
                      </> }
                    </>}
                      {/* {editInputComponent(
                       
 myJobSeekerInformation,
 "jobseeker",
                        "specificArea",
                        "Specific Area",
                        myJobSeekerInformation.specificArea,
                        "textField"
                      )} */}
                    </MKBox>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          )}

            </Grid>
          )}
          
          {user && myJobSeekerInformation && (
            <Grid container item xs={12} pt={3} justifyContent="center">
              <Card sx={{ width: "100%" }}>
                <Grid container alignItems="center">
                  <Grid item xs={12} lg={12}>
                    <MKBox py={1} px={4}>
                      <MKTypography variant="h5" mb={1}>
                        Educational Informations
                      </MKTypography>

                      </MKBox>
                    <MKBox py={{sm: 0, md:1} } px={{sm: 0, md:3} } > 
                      <MKTypography  variant="h6" mt={2} style={{color:"darkgreen"}} mx={2}>
                        Your Current Education Level
                      </MKTypography>
                      {editInputComponent(
                       
 myJobSeekerInformation,
 "jobseeker",
                        "currentEducationLevelId",
                        "Education Level",
                        educationLevels?getEducationLevelById(myJobSeekerInformation.currentEducationLevelId, "single"):"" ,
                        "selectOption",
                        educationLevels,
                        "id",
                        "title"
                      )}
                      {editInputComponent(
                       
 myJobSeekerInformation,
 "jobseeker",
                        "currentEducationLevelEducatedField",
                        "Educated Field",
                        myJobSeekerInformation.currentEducationLevelEducatedField,
                        "textField"
                      )}
                      {editInputComponent(
                       
 myJobSeekerInformation,
 "jobseeker",
                        "currentEducationLevelEducatedSchool",
                        "Educated School",
                        myJobSeekerInformation.currentEducationLevelEducatedSchool,
                        "textField"
                      )}
                      {editInputComponent(
                       
 myJobSeekerInformation,
 "jobseeker",
                        "currentEducationLevelEducatedCountry",
                        "Educated Country",
                        myJobSeekerInformation.currentEducationLevelEducatedCountry,
                        "textField"
                      )}
                      {/* educationalDocumentAttachementUrl */}
                      {editInputComponent(
                       
 myJobSeekerInformation,
 "jobseeker",
                        "educationalDocumentAttachementUrl",
                        "Education Document Attachement",
                        myJobSeekerInformation.educationalDocumentAttachementUrl,
                        "fileUrl"
                      )}
                      
                    </MKBox>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          )}
 
          {user && userInformation && (
            <Grid container item xs={12} pt={3} justifyContent="center">
              <Card sx={{ width: "100%" }}>
                <Grid container alignItems="center">
                  <Grid  p={1} item xs={12} lg={12}>
                    <MKBox py={1} px={4}>
                      <MKTypography variant="h5" mb={1}>
                       Connected Accounts
                      </MKTypography> 

                      </MKBox>
                    <MKBox pb={{sm: 0, md:1} } px={{sm: 2, md:3} } > 
                        <List > 
                          <ListItem
                            secondaryAction={ <MKButton 
                              onClick={()=>{UseAuth.openAuthWebsite("changepassword")}}  color="warning">
                            Change password
                          </MKButton>

                            }
                          >
                            <ListItemAvatar>
                            <Avatar alt="Remy Sharp" 
                                sx={{ width: 50, height:50 }} src={
                                user.userFrontImage
                                  ? user.userFrontImage
                                  : profilePicture
                                }/>
                            </ListItemAvatar>
                            <ListItemText
                              primary={
                               truncateStringDefualt((userInformation.firstName+" "
                              +userInformation.lastName+" "),(isMobile?15:50))  
                            }
                              secondary={ 'Bali Platform Account'  }
                            />
                          </ListItem> 
                      </List>

                    </MKBox>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          )} 
        </Grid>}

       {(profileSectionIndex==1)&&<>
       {(myJobSeekerInformation)?
       (<Grid container item xs={12} justifyContent="center" mx="auto"> 
            <Grid container justifyContent="center" pb={1} pt={3}>
              {/* <Grid item xs={3} md={1}> */}
               
                <Grid  xs={12} justifyContent="center" py={3} mx="auto">
                <Grid container alignItems="center"> 
                <Grid item   xs={4} md={2} lg={1}>
                  {/* <MKBox p={1} textAlign="center">  */}
                  <MKBox textAlign="left"> 
                  <MKBox
                    component="img"
                    src={
                      myJobSeekerInformation.profileImageUrl
                        ? myJobSeekerInformation.profileImageUrl
                        : profilePicture
                    }
                    // alt={name}
                    width="90%"
                    borderRadius="md"
                    shadow="lg"
                  />{" "}
                </MKBox>

                  {/* </MKBox> */}
                  </Grid>
                <Grid item  xs={8} md={10} lg={11}>
            <Card sx={{ width: "100%" }}>
            <Grid container alignItems="center"> 

                <Grid item xs={12} lg={6}>
                  <MKBox p={1} textAlign="center">  
                    <MKTypography variant="h6" mt={{ xs: 0, sm: 3 }}>
                      Total Work Experiance
                    </MKTypography>
                    <MKTypography variant="h5"  style={{color:"darkgreen"}}>
                      {myJobSeekerInformation.workExperianceInYear||0}
                      <MKBox component="small"  style={{color:"darkgreen"}}>{" Year(s)"}</MKBox>
                    </MKTypography> 
                  </MKBox>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <MKBox p={1} textAlign="center">
                    <MKButton
                      variant="text"
                      color={myJobSeekerInformation.isAvailable ? "success" : "warning"}
                      size="small"
                      sx={{ my: 2 }}
                    >
                      {myJobSeekerInformation.isAvailable ? "Available" : "Not Available"}
                    </MKButton>
                    <MKTypography
                      display="block"
                      variant="button"
                      color="text"
                      fontWeight="regular"
                    >
                      {myJobSeekerInformation.isAvailable
                        ? "Everybody can see you"
                        : "Employer can't identfy you"}
                    </MKTypography>
                  </MKBox>
                </Grid>
              </Grid>
              </Card>
              </Grid>
              </Grid>
              </Grid>
               

              {/* </Grid> */}

            </Grid> 

      <Grid container item xs={12} py={3} justifyContent="center">
          <Grid id="professions" container alignItems="center">
              <Grid item xs={12} lg={12}>
                
                <MKBox py={{sm: 0, md:3} } px={{sm: 0, md:3} } > 
                  {/* <MKTypography variant="h3" mb={1}>
                    Educational Informations
                  </MKTypography> */}

                  {/* <MKTypography mt={5} mb={2} mx={2}>
                    availablity
                  </MKTypography> */}
                  <MKTypography variant="h5" mt={5} mb={2} mx={2}>
                    <strong> Your Professions and Work Experiances </strong>
                  <MKButton 
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      setOpenedDialog("addWorkExperiance")
                    }}
                  >
                    Add Profession
                  </MKButton>
                  </MKTypography>

                {myJobSeekerInformation.workExperiances&&<>
                {(myJobSeekerInformation.workExperiances.length?(myJobSeekerInformation.workExperiances.length>0):false)&&<>

                <Accordion expanded={false}
                // onChange={handleChangeExpanded2("YourWorkExperiance"+i)}
                >
                <AccordionSummary
                    >
                  <Typography sx={{ width: "43%", flexShrink: 0 }}>  
                  <strong 
                //  style={{color:"darkgreen"}}
                  > 
                  {"Profession" }</strong>
                  </Typography>
                  <Typography sx={{ color: "text.secondary" }}>
                    <strong>{ "Experiance (Months)" }</strong>
                    </Typography> 
                </AccordionSummary> 
              </Accordion>


                {myJobSeekerInformation.workExperiances.map((experiance,i)=>
                <Accordion expanded={expanded2 === "YourWorkExperiance"+i}
                onChange={()=>{if(expanded2 === "YourWorkExperiance"+i){handleChangeExpanded2("")}}}
                >
                <AccordionSummary
                  expandIcon={
                  <>{!isMobile?<><Button startIcon={<Edit />}
                  onClick={()=>{
                    if(expanded2 === "YourWorkExperiance"+i){
                    handleChangeExpanded2("")}
                    else{
                    handleChangeExpanded2("YourWorkExperiance"+i)
                  }
                    } }>
                  {(expanded2 != "YourWorkExperiance"+i)? "Edit":""}
                </Button>
                <Button sx={{ color: pink[500] }}
                    onClick={() => {
                      deleteWorkExperiance(experiance.id)
                    }} startIcon={<Delete  sx={{ color: pink[500] }}/>}>
                {(expanded2 != "YourWorkExperiance"+i)? "Delete":""}
                </Button>
                </>:<>
                <Stack direction="row" spacing={1}>
                    <IconButton aria-label="delete" 
                  onClick={()=>{
                    if(expanded2 === "YourWorkExperiance"+i){
                    handleChangeExpanded2("")}
                    else{
                    handleChangeExpanded2("YourWorkExperiance"+i)}
                    } 
                    }>
                      <Edit/>
                    </IconButton>
                    <Delete style={{   margin: "14px"}}
                    onClick={() => {
                      deleteWorkExperiance(experiance.id)
                    }} sx={{ color: pink[500] }}/> 
                    {/* <IconButton  onClick={() => {
                      deleteWorkExperiance(experiance.id)
                    }}  aria-label="delete" disabled sx={{ color: pink[500] }}>
                      <Delete sx={{ color: pink[500] }}  onClick={() => {
                      deleteWorkExperiance(experiance.id)
                    }} />
                    </IconButton>  */}
                  </Stack>
                </>}</>
                
              }
                // <ArrowDropDown />
                  aria-controls="panel3bh-content"
                  id="panel3bh-header"
                >
                  <Typography sx={{ width: "63%", flexShrink: 0 }}> 
                  {/* <ArrowDropDownSharp />  */}
                  <strong style={{color:"darkgreen"}}>{i+1+". "}
                  {truncateString(jobs?getDataFromArray(jobs, "id", experiance.jobId, "single", "title"):"-",40) }</strong>
                  </Typography>
                  <Typography >
                    <strong>{experiance.workExperianceInMonth}{experiance.workExperianceInMonth?(isMobile?"":" Months"):"-"}</strong>
                    </Typography>
                  {/* <Typography sx={{ color: "text.secondary" }}>
                  {"  "}
                  <MKButton
                    color="error"
                    variant="text"
                    size="small"
                    onClick={() => {
                      deleteWorkExperiance(experiance.id)
                    }}
                  >
                    <strong> delete</strong>
                  </MKButton></Typography> */}
                </AccordionSummary>
                <AccordionDetails>
                  <Grid  >
                  {editInputComponent(
                              experiance,
                            "workExperiance",
                            "jobId",
                            "Profession / Job",  
                            jobs?getDataFromArray(jobs, "id", experiance.jobId, "single"):{},
                            "selectOption",
                            jobs,
                            "id",
                            "title"
                          )}
                          {editInputComponent(
                              experiance,
                            "workExperiance",
                            "workExperianceInMonth",
                            "Work Experiance (Months)", 
                            experiance.workExperianceInMonth,
                            "number"
                          )} 
                          {editInputComponent(
                              experiance,
                            "workExperiance",
                            "workExperianceInYear",
                            "Work Experiance (Years)", 
                            experiance.workExperianceInYear,
                            "number"
                          )} 
                          {editInputComponent(
                              experiance,
                            "workExperiance",
                            "expectingSalaryPerHour",
                            "Expecting Salary (Hourly ETB)", 
                            experiance.expectingSalaryPerHour,
                            "number"
                          )}  
                          {editInputComponent(
                              experiance,
                            "workExperiance",
                            "expectingSalaryPerMonth",
                            "Expecting Salary (Monthly ETB)", 
                            experiance.expectingSalaryPerMonth,
                            "number"
                          )}  
                          {editInputComponent(
                              experiance,
                            "workExperiance",
                            "skillsOrSubjects",
                            "Skills or Subjects",
                            experiance.skillsOrSubjects,
                            "chipsText"
                          )} 
                          {editInputComponent(
                              experiance,
                            "workExperiance",
                            "description",
                            "Skills description",
                            experiance.description,
                            "textArea"
                          )}  
                  </Grid>
                </AccordionDetails>
              </Accordion> )}
              </> }
              </> }
                  
                </MKBox>
              </Grid>
            </Grid>
            
        <Grid  xs={12} justifyContent="center" py={3} mx="auto">
            <Card sx={{ width: "100%" }}>
              <Grid container alignItems="center">
                <Grid item xs={12} lg={8}>
                  <MKBox pt={3} px={4} >
                    <MKTypography variant="h5" mb={1}>
                       availablity Information
                    </MKTypography>
                  </MKBox>
                  </Grid> 
              </Grid>
              
              <Grid container alignItems="center">
                  <Grid item xs={12} lg={12}>
                   
                    <MKBox py={{sm: 0, md:3} } px={{sm: 0, md:3} } > 
                      {/* <MKTypography variant="h3" mb={1}>
                        Educational Informations
                      </MKTypography> */}

                      {/* <MKTypography mt={5} mb={2} mx={2}>
                       availablity
                      </MKTypography> */}
                      {editInputComponent( 
                                              
                        myJobSeekerInformation,
                        "jobseeker",
                        "isAvailable",
                        "Available to be hired?",
                        myJobSeekerInformation.isAvailable,
                        "boolean"
                      )}
                      {editInputComponent( 
                                              
                        myJobSeekerInformation,
                        "jobseeker",
                        "isUserManaged",
                        "Is this account Managed by Agency? ",
                        myJobSeekerInformation.isUserManaged,
                        "boolean"
                      )}    
                      
                      {/* {editInputComponent(
                                 myJobSeekerInformation,
                                "jobseeker",
                                "workExperianceInMonth",
                                "Total Work Experiance (Months)", 
                                myJobSeekerInformation.workExperianceInMonth,
                                "number"
                              )}  */}
                      
                      {editInputComponent(
                                 myJobSeekerInformation,
                                "jobseeker",
                                "workExperianceInYear",
                                "Total Work Experiance (Years)", 
                                myJobSeekerInformation.workExperianceInYear,
                                "number"
                              )} 
                    </MKBox>
                  </Grid>
                </Grid>
            </Card>
          </Grid>
          </Grid>
        </Grid>):
        (<></>)}
        </>}
        
       {(profileSectionIndex==2)&&
       <>       

       {(myAgencies)&&
        <Grid  xs={12} justifyContent="center" py={3} mx="auto">
          <>
          <h1>Agencies joined :{myAgencies.length} </h1>
          {myAgencies.length && (
            <Grid   justifyContent="center" py={2}>

              { myAgencies.map((agency,i)=>
              <Accordion expanded={expanded ==="agency"+i} onChange={handleChange("agency"+i)}>
                <AccordionSummary
                  expandIcon={<Edit />}
                  aria-controls="panel3bh-content"
                  id="panel3bh-header"
                >
                  <Typography sx={{ width: "33%", flexShrink: 0 }}>{agency.fullName}</Typography>
                  {/* <Typography sx={{ color: "text.secondary" }}>{"accordionTitle"}</Typography> */}
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container alignItems="center">
                    {/* <Grid py={1} item xs={12} lg={6}>
                      {inputComp}
                    </Grid> */}
                    <Grid py={1} item xs={12} lg={6}>
                      <MKBox px={4}>
                        <Stack direction="row" spacing={2}>
                        <Box sx={{ m: 1, position: 'relative' }}>
            
                          <Button
                            variant="contained"
                            color="success"
                            disabled={loadingSave}
                            onClick={() => { 
                              removeAgencyIJoined(agency.id);
                              // console.log("value", value);
                            }}
                          >
                            Remove
                          </Button>

                                  
                          {loadingSave && (
                            <CircularProgress
                              size={24}
                              sx={{
                                // color: green[500],
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                marginTop: '-12px',
                                marginLeft: '-12px',
                              }}
                            />
                          )}
                        </Box> 
                        <Button
                          color="secondary"
                          // onClick={() => {
                          //   setExpanded(false);
                          // }}
                        >
                          Cancel
                        </Button>
                      </Stack>
                  </MKBox>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>   
              )
          }


            </Grid>)}
            </>
       </Grid>}
       </>}
      </Container>
      {forceRerender}


      <Dialog open={openedDialog=="addWorkExperiance"} onClose={()=>{setOpenedDialog("")}}>
        <DialogTitle>Add Profession and Work Experiance</DialogTitle>
        <DialogContent style={{paddingTop:"15px"}}>
          {/* <DialogContentText>
            To subscribe to this website, please enter your email address here. We
            will send updates occasionally.
          </DialogContentText> */}

           {(jobs)&&<Autocomplete fullWidth 
                // sx={{ width: 400 }}
                onChange={(e, val) => {
                    if(val){ 
                      // console.log("val",val);
                      //  value=val;
                      handleNewWorkExperianceData("jobId",val.id)
                      // console.log("value",value);
                      } 
                }}
                options={jobs}
                // defaultValue={options?(optionsSelectedField?value[optionsSelectedLable]:value):""}
                getOptionLabel={(option) =>  option.title}
                renderInput={(params) => <MKInput {...params} label="Profession or Job" variant="outlined" />}
              />}

          <TextField
            autoFocus
            margin="dense"
            id="expectingSalaryPerMonth"
            defaultValue={newWorkExperianceData.expectingSalaryPerMonth}
            label="Expecting Salary (Per Month)"
            type="number"
            onChange={(e) => { 
                handleNewWorkExperianceData("expectingSalaryPerMonth",e.target.value)
                } 
              } 
            fullWidth
            variant="outlined"
          />
          <TextField
            autoFocus
            margin="dense"
            id="workExperianceInMonth"
            defaultValue={newWorkExperianceData.workExperianceInMonth}
            onChange={(e) => { 
                handleNewWorkExperianceData("workExperianceInMonth",e.target.value)
                } 
              } 
            label="Work Experiance (In Month)"
            type="number"
            fullWidth
            variant="outlined"
          />

          
      <Autocomplete
        multiple
        id="tags-filled"
        options={[]}
        // defaultValue={[top100Films[13].title]}
            // defaultValue={newWorkExperianceData.skillsOrSubjects}
        onChange={(e,data)=>{
          handleNewWorkExperianceData("skillsOrSubjects",data)
        }}
        freeSolo 
        renderTags={(value, getTagProps) =>  
           value.map((option, index) => 
            (
            <Chip variant="outlined" label={option} {...getTagProps({ index })} />
          ) )  
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="filled"
            label="Skills Or Subjects"
            placeholder="Add Skill"
          />
        )}
      />
 

          <TextField
            autoFocus
            margin="dense"
            multiline
            rows={4}
            id="Describe"
            defaultValue={newWorkExperianceData.description}
            onChange={(e) => { 
                handleNewWorkExperianceData("description",e.target.value)
                } 
              } 
            label="More Describtion (about your skills) "
            // type="number"
            fullWidth
            variant="outlined"
          />
          {/* newWorkExperianceData */}
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={()=>{setOpenedDialog("")}}>Cancel</Button> */}
          <MKButton
                        color="success"
                        // variant="text"
                        // size="small"
                        disabled={!(newWorkExperianceData.jobId)} onClick={()=>{addNewWorkExperiance() }}
                      >
                       <strong> Save </strong>
                      </MKButton>
        </DialogActions>
      </Dialog>

      <Dialog open={openedDialog=="moreText"} onClose={()=>{setOpenedDialog("")}}>
        {moreText&&<>
       {moreText.title&& <DialogTitle>{moreText.title}</DialogTitle>}
       {moreText.content&& <DialogContent>
          <DialogContentText>
           {moreText.content}
          </DialogContentText> 
        </DialogContent>}</>}
        <DialogActions>
          <Button onClick={()=>{setOpenedDialog("")}}>close</Button> 
        </DialogActions>
      </Dialog>

    </MKBox>
  );
}

export default MyProfile;
