/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import { cities, specificAreas, subCities, citiesOnly } from "Api-master-datas/masterDatas.js";
import queryString from "query-string";
import { toast } from "react-toastify";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
// import MKDatePicker from "components/MKDatePicker";
import MKButton from "components/MKButton";
import { useNavigate } from "react-router-dom";
import { useCurrentUser } from "Api-contexts/currentUserContext";
import React, { useEffect } from "react";
import { HttpService } from "Api-Services/httpService";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CardActions,
  CircularProgress,
  Collapse,
  IconButton,
  MenuItem,
  Select,
} from "@mui/material";
import { Card, TextField } from "@mui/material";
import { element } from "prop-types";
import { ExpandMore, ExpandMoreOutlined, Favorite, Share } from "@mui/icons-material";
import { CommonServices } from "Api-Services/commonServices";

function Search(props) {
  const navigateComp = useNavigate();
  // console.log("cities", cities);

  const [currentData] = useCurrentUser();
  const { currentUser, educationLevels, jobs, agencyData, isAgentWebsite } = currentData;
  const [currentEducationLevelId, setCurrentEducationLevelId] = React.useState(5);
  const [sex, setSex] = React.useState("Any");
  const [professionTitle, setProfessionTitle] = React.useState("");
  const [jobSeekerSearchFieldsandValue, setJobSeekerSearchFieldsandValue] = React.useState({
    currentEducationLevelId: "Any",
    sex: "Any",
    city: "Any",
    subCity: "Any",
    specificArea: "Any",
  });
  const [jobSearchFieldsandValue, setJobSearchFieldsandValue] = React.useState({
    jobId: "",
  });
  const [jobId, setJobId] = React.useState("");
  const [agencyJobs, setAgencyJobs] = React.useState(null);
  const [jobsToChoose, setJobsToChoose] = React.useState(jobs);
  const [forceRenderer, setForceRenderer] = React.useState(false);
  const [searchExpanded, setSearchExpanded] = React.useState(true);
  const [filterExpanded, setFilterExpanded] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [searchResult, setSearchResult] = React.useState(null);

  useEffect(() => {
    // console.log("educationLevels", educationLevels);
    // console.log(" myJobSeekerInformation", myJobSeekerInformation);
    if (location.search) {
      let locationSearch = queryString.parse(location.search);
      if (locationSearch) {
        if (locationSearch.search) {
          search(locationSearch.search);
        }
      }
    }
  }, [location.pathname]);
 

  useEffect(() => {
    initializeFilter();
    //     const newArr = cities.map(({
    //       EASTING,
    //       ELEVATION
    //  ,     ELEV_TXT
    //   ,    EROSIVITY
    //    ,   ETTOWN01CO
    //     ,  ETTOWN01_1
    //      , FID
    //       ,K_VALUE
    //     ,  LAT
    //      , LON
    //      , NORTHING ,
    //       POP,
    //      POPRUR
    //  ,     POPTOT
    //   ,    POPURB
    //    ,   POP_TXT
    //     ,  RAIN
    //      , RAIN_TXT ,
    //       REGION,
    //       SOIL
    //   ,    WEREDA
    //    ,   ZONES
    //     ,  the_geom
    //       , ...rest}) => {
    //       return rest;
    //     });

    // console.log("cities", cities);

    // console.log(" myJobSeekerInformation", myJobSeekerInformation);

    // console.log("areas", specificAreas)
  }, []);

  useEffect(() => {
    // console.log("agencyData",agencyData)
    let filterdJobs = [];
    if (agencyData) {
      if (agencyData.jobsId) {
        let allagencyjobs = agencyData.jobsId.split(",");
        filterdJobs = jobs.filter((jobD) => {
          let res = false;
          allagencyjobs.forEach((element) => {
            element = parseInt(element);
            if (element) {
              if (element == jobD.id) {
                res = true;
              }
            }
          });
          return res;
        });
        setAgencyJobs(filterdJobs);
        setJobsToChoose(filterdJobs);
      }
    }
        setForceRenderer(!forceRenderer);
  }, [agencyData, jobs]);

  const goToPage = (page) => {
    navigateComp(page);
  };

  const initializeFilter = () => {
    // setJobSeekerSearchFieldsandValue (
    //     )
    // setForceRenderer(!forceRenderer)
  };

  const handleSearchFieldsandValue = async (field, value, objectType) => {
    try {
      let searchFieldsandValueD = {};
      if (objectType == "jobseeker") {
        searchFieldsandValueD = jobSeekerSearchFieldsandValue;
      }
      if (objectType == "job") {
        searchFieldsandValueD = jobSearchFieldsandValue;
      }

      searchFieldsandValueD[field] = value;

      if (objectType == "jobseeker") {
        setJobSeekerSearchFieldsandValue(searchFieldsandValueD);
      }
      if (objectType == "job") {
        setJobSearchFieldsandValue(searchFieldsandValueD);
      }
      // console.log("searchFieldsandValueD", searchFieldsandValueD);

      setForceRenderer(!forceRenderer);
    } catch (error) {}
  };

  const search = async (searchD) => {
    try {
      let jobSeekerSearchData;
      let jobSearchData;
      let pathSearch;
      if (searchD) {
        let pathSearchData = CommonServices.decryptLocalData(searchD);
        // console.log("pathSearchData", pathSearchData);
        jobSeekerSearchData = pathSearchData.jobSeekerSearch;
        jobSearchData = pathSearchData.jobSearch;
      } else {
        jobSeekerSearchData = jobSeekerSearchFieldsandValue;
        jobSearchData = jobSearchFieldsandValue;
        pathSearch = { jobSeekerSearch: jobSeekerSearchData, jobSearch: jobSearchData };
        pathSearch = CommonServices.encryptLocalData(pathSearch);
        let path = "/pages/search?search=" + pathSearch; //+"&employmentAgencyId="+jobSeeker.employmentAgencyId
        navigateComp(path);
      }

      let jobSeekerSearch = {};
      let jobSearch = {};
      let agencyJobSeekerSearch = {};
      if (isAgentWebsite) {
        if (agencyData) {
          if (agencyData.id) {
            agencyJobSeekerSearch.employmentAgencyId = agencyData.id;
          }
        }
      }

      if (jobSeekerSearchData) {
        Object.entries(jobSeekerSearchData).forEach((element) => {
          if (element[1]) {
            if (element[1] != "Any") {
              jobSeekerSearch[element[0]] = element[1];
            }
          }
          if (searchD) {
            handleSearchFieldsandValue(element[0], element[1], "jobseeker");
          }
        });
      }

      if (jobSearchData) {
        Object.entries(jobSearchData).forEach((element) => {
          if (element[1]) {
            if (element[1] != "Any") {
              jobSearch[element[0]] = element[1];
            }
          }
          if (searchD) {
            handleSearchFieldsandValue(element[0], element[1], "job");
          }
        });
      }

      let data = {
        jobSeeker: jobSeekerSearch || {},
        job: jobSearch || {},
        agencyJobSeeker: agencyJobSeekerSearch || {},
      };

      // console.log("data", data);

      if (location.pathname.indexOf("pages/search") < 0) {
        let searchFields = "";
        Object.entries(data).forEach((element) => {
          searchFields = searchFields + element[0] + "=" + element[1] + "&";
        });
        navigateComp("/pages/search?" + searchFields);

        // pages/search
      }

      try {
        // let valid = false;
        // setLoginResponse(null);
        // setInvalidResponse("");
        // setIsFormValid(true);
        // if (fullName && message) {
        //   setIsFormValid(true);
        //   valid = true;
        // } else {
        //   setIsFormValid(false);
        //   valid = false;
        //   setInvalidResponse(" Please provide your full name and your message");
        // }
        // console.log("userEmail&&password",userEmail,password, valid)
        if (true) {
          if (sex == "All") {
            delete data.sex;
          }
          // let relativePath = "/api/employmentagencyjobseeker/search";
          let relativePath = "/api/employmentjobseeker/search";

          // let toastId = toast.loading("Serching job seekers...",{
          //   position: toast.POSITION.BOTTOM_LEFT
          // })
          setLoading(true);

          // let AdditionalData={withCredentials: true, credentials: 'include'}//{  credentials: 'include' }//header can be included here
          let contactUsRes = await postDocuments("contactus", relativePath, data, {});
          setLoading(false);

          // toast.update(toastId, { render: contactUsRes.message, type: contactUsRes.success?"success":"warn", isLoading: false , autoClose: 5000});
          contactUsRes.professionTitle = professionTitle || "";
          contactUsRes.employees = null;
          if (contactUsRes.success) {
            if (contactUsRes.record) {
              contactUsRes.employees = contactUsRes.record;
              if (contactUsRes.record.length) {
                setSearchExpanded(false);
              }
            }
          }
          setSearchResult(contactUsRes.employees);
          props.handleSearchResult(contactUsRes);
          if (contactUsRes.success) {
            // toast.success(contactUsRes.message, {
            //   position: toast.POSITION.TOP_CENTER,
            // });
          } else {
            // toast.error(contactUsRes.message, {
            //   position: toast.POSITION.TOP_LEFT,
            // });
          }
        }
        // setLoading(false);
      } catch (error) {}
    } catch (error) {}
  };

  const filterResult = (searchField, searchValue) => {
    let res = { professionTitle: "", employees: null };
    let dataArray = searchResult;
    // console.log("dataArray   ", dataArray, searchField, searchValue);
    try {
      if (dataArray && searchField && searchValue) {
        if (dataArray.length > 0) {
          let filteredLevels = dataArray.filter((level) => {
            if (level[searchField]) {
              return level[searchField].indexOf(searchValue) > -1;
            } else {
              return false;
            }
          });
          if (filteredLevels.length) {
            res = filteredLevels;
          }
        }
      } else if (!searchValue) {
        res = dataArray;
      }
      // console.log("filtered    ", res);
    } catch (error) {
      // console.log("filtered city error", error);
    }

    res.professionTitle = professionTitle || "";
    res.employees = res;

    props.handleSearchResult(res);
  };

  const getDataFromArray = (dataArray, searchField, searchValue, returnRows, returnField) => {
    let res = {};
    // console.log("dataArray   ", dataArray,searchField, searchValue, returnRows, returnField);
    try {
      if (dataArray && searchField && searchValue) {
        if (dataArray.length > 0) {
          let filteredLevels = dataArray.filter((level) => level[searchField] == searchValue);
          if (filteredLevels.length) {
            res = filteredLevels;
            if (returnRows == "single") {
              let row = filteredLevels[0];
              res = row;
              if (returnField) {
                let rowField = row[returnField];
                res = rowField;
              }
            }
          }
        }
      }
      // console.log("filtered city  ", res);
    } catch (error) {
      // console.log("filtered city error", error);
    }
    return res;
  };

  const postDocuments = async (documentName, relativePath, data) => {
    let responseD = { success: false, message: "", record: null };
    let documentsResponse = { success: false };
    // setLoading(true);

    ////////// UPDATED/////////////
    documentsResponse = await HttpService.postService(relativePath, data, {}, "");
    // console.log(documentName, " data: ", documentsResponse   );
    // setLoading(false);

    if (documentsResponse.data) {
      responseD.record = documentsResponse.data.record;
      responseD.message = documentsResponse.data.message;
    }
    if (documentsResponse.success) {
      responseD.success = true;
      let record = responseD.record;

      //write codtions down here//
    }

    // console.log(documentName, " responseD: ", responseD);
    // setForceRerender(!forceRerender);
    return responseD;
    ////////// UPDATED/////////////
  };

  const getEducationLevelById = (id, rows, field) => {
    let res = {};
    try {
      if (educationLevels && id) {
        let educationLevelsD = [...[{ title: "Any", id: "Any" }], ...educationLevels];

        let filteredLevels = educationLevelsD.filter((level) => level.id == id);
        if (filteredLevels.length) {
          res = filteredLevels;
          if (rows == "single") {
            let row = filteredLevels[0];
            res = row;
            if (field) {
              let rowField = row[field];
              res = rowField;
            }
          }
        }
      }
    } catch (error) {
      // console.log("filtered error ", error);
    }
    // console.log("filtered educat ", res);
    return res;
  };

  const getCityById = (city, rows, field) => {
    let res = {};
    try {
      if (cities && city) {
        let filteredLevels = cities.filter((level) => level.TOWN_NAME == city);
        if (filteredLevels.length) {
          res = filteredLevels;
          if (rows == "single") {
            let row = filteredLevels[0];
            res = row;
            if (field) {
              let rowField = row[field];
              res = rowField;
            }
          }
        }
      }
    } catch (error) {
      // console.log("filtered city error", error);
    }
    return res;
  };

  return (
    <>
      <Grid item xs={12} md={8} sx={{ ml: 2 }}>
        <MKTypography variant="h3" opacity={0.4}>
          {"Search Employee"}
        </MKTypography>
      </Grid>
      <Card
        sx={{
          my: 2,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
          overflow: "hidden",
        }}
      >
        {searchExpanded == true && (
          <MKBox component="section">
            <Container>
              <Grid container spacing={{ xs: 0, lg: 3 }} sx={{ pt: 2, pb: 3, px: 2, mx: "auto" }}>
                {jobs.length > 0 && (
                  <Grid item xs={12} lg={3} sx={{ mt: 2 }}>
                    <MKTypography
                      display="block"
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      mb={1}
                    >
                      Employee profesion
                    </MKTypography>
                    {/* <Autocomplete
                      // defaultValue={jobsToChoose.length?jobsToChoose[0]:{}}
                      onChange={(e, val) => { 
                        handleSearchFieldsandValue("jobId",val?val.id:"","job")
                        setProfessionTitle(val?val.title:""); 
                      }}
                      // defaultValue={jobId?getCityById(jobId):}
                      options={agencyJobs||jobs}
                      getOptionLabel={(option) => option.title}
                      renderInput={(params) => <MKInput placeholder="select profession" {...params} variant="standard" />}
                    /> */}

                    <Select
                      variant="standard"
                      fullWidth
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      // defaultValue={getEducationLevelById(jobSeekerSearchFieldsandValue.currentEducationLevelId, "single")}
                      // defaultValue={jobSeekerSearchFieldsandValue.currentEducationLevelId}
                      defaultValue={jobSearchFieldsandValue.jobId || "Any"}
                      // defaultValue={"Any"}
                      // defaultValue={jobsToChoose.length?jobsToChoose[0]:{}}
                      // label="Age"
                      onChange={(e) => {
                        // setSex(val);
                        let idD = e.target.value;
                        try {
                          if (idD) {
                            handleSearchFieldsandValue("jobId", e ? idD : "Any", "job");

                            // handleSearchFieldsandValue("jobId",val?val.id:"","job")
                            if (jobs) {
                              let jTitle = jobs.filter((job) => job.id == idD);
                              if (jTitle.length) {
                                jTitle = jTitle[0];
                                setProfessionTitle(jTitle.title);
                              }
                            }
                          }
                        } catch (error) {}
                      }}
                    >
                      <MenuItem value={"Any"}>{"Any"}</MenuItem>
                      {(agencyJobs || jobs).map((val) => (
                        <MenuItem value={val.id}>{val.title}</MenuItem>
                      ))}
                    </Select>
                  </Grid>
                )}
                {educationLevels.length > 0 && (
                  <Grid item xs={12} lg={3} sx={{ mt: 2 }}>
                    <MKTypography
                      display="block"
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      mb={1}
                    >
                      Employee education level
                    </MKTypography>
                    {/* <Autocomplete
                      // defaultValue="High School"
                      onChange={(e, val) => { 
                          // setCurrentEducationLevelId(val.id);
                        handleSearchFieldsandValue("currentEducationLevelId",val?val.id:"Any","jobseeker")}
                      }  
                      options={[...[{title:"Any",id:"Any"}], ...educationLevels]}
                      defaultValue={getEducationLevelById(jobSeekerSearchFieldsandValue.currentEducationLevelId, "single")}
                      getOptionLabel={(option) => option.title}
                      renderInput={(params) => <MKInput {...params} variant="standard" />}
                    /> */}

                    <Select
                      variant="standard"
                      fullWidth
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      // defaultValue={getEducationLevelById(jobSeekerSearchFieldsandValue.currentEducationLevelId, "single")}
                      defaultValue={jobSeekerSearchFieldsandValue.currentEducationLevelId}
                      // label="Age"
                      onChange={(e) => {
                        // setSex(val);
                        let idD = e.target.value;
                        if (idD) {
                          handleSearchFieldsandValue(
                            "currentEducationLevelId",
                            e ? idD : "Any",
                            "jobseeker"
                          );
                        }
                      }}
                    >
                      <MenuItem value={"Any"}>{"Any"}</MenuItem>
                      {educationLevels.map((val) => (
                        <MenuItem value={val.id}>{val.title}</MenuItem>
                      ))}
                    </Select>
                  </Grid>
                )}
                <Grid item xs={12} lg={3} sx={{ mt: 2 }}>
                  <MKTypography
                    display="block"
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    mb={1}
                  >
                    Employee gender
                  </MKTypography>
                  {/* <Autocomplete
                    onChange={(e, val) => {
                      // setSex(val);
                      handleSearchFieldsandValue("sex",val?val:"Any","jobseeker")

                    }}
                    value={jobSeekerSearchFieldsandValue.sex}
                    options={["Any", "male", "female"]}
                    getOptionLabel={(option) =>  option }
                    renderInput={(params) => <MKInput {...params} variant="standard" placeholder="select gender"/>}
                  /> */}
                  <Select
                    variant="standard"
                    fullWidth
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={jobSeekerSearchFieldsandValue.sex}
                    // label="Age"
                    onChange={(e) => {
                      // setSex(val);
                      handleSearchFieldsandValue("sex", e ? e.target.value : "Any", "jobseeker");
                    }}
                  >
                    {["Any", "male", "female"].map((val) => (
                      <MenuItem value={val}>{val}</MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={12} lg={3} sx={{ mt: 2 }}>
                  <MKTypography
                    display="block"
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    mb={1}
                  >
                    City
                  </MKTypography>
                  <Autocomplete
                    // autoSelect
                    // defaultValue={getCityById(city, "single")}
                    value={getDataFromArray(
                      cities,
                      "TOWN_NAME",
                      jobSeekerSearchFieldsandValue.city,
                      "single"
                    )}
                    options={cities} //TOWN_NAME
                    groupBy={(option) => option.REGION}
                    // limitTags={2}
                    onChange={
                      (e, val) => {
                        handleSearchFieldsandValue(
                          "city",
                          val ? val.TOWN_NAME : "Any",
                          "jobseeker"
                        );
                      }
                      // setCity(val.TOWN_NAME ? val.TOWN_NAME : "");
                    }
                    getOptionLabel={(option) => (option.TOWN_NAME ? option.TOWN_NAME : "")}
                    renderInput={(params) => <MKInput {...params} variant="standard" />}
                  />
                </Grid>
                {jobSeekerSearchFieldsandValue.city && (
                  <>
                    {jobSeekerSearchFieldsandValue.city.toLowerCase() == "addis ababa" && (
                      <>
                        <Grid item xs={12} lg={3} sx={{ mt: 2 }}>
                          <MKTypography
                            display="block"
                            variant="button"
                            fontWeight="regular"
                            color="text"
                            mb={1}
                          >
                            Sub city
                          </MKTypography>
                          <Autocomplete
                            value={getDataFromArray(
                              subCities,
                              "title",
                              jobSeekerSearchFieldsandValue.subCity,
                              "single"
                            )}
                            options={subCities} //TOWN_NAME
                            onChange={
                              (e, val) => {
                                handleSearchFieldsandValue(
                                  "subCity",
                                  val ? val.title : "Any",
                                  "jobseeker"
                                );
                              }
                              // setSubCity(val.title ? val.title : "");
                            }
                            getOptionLabel={(option) => (option.title ? option.title : "")}
                            renderInput={(params) => <MKInput {...params} variant="standard" />}
                          />
                        </Grid>
                        <Grid item xs={12} lg={3} sx={{ mt: 2 }}>
                          <MKTypography
                            display="block"
                            variant="button"
                            fontWeight="regular"
                            color="text"
                            mb={1}
                          >
                            Specific area
                          </MKTypography>
                          <Autocomplete
                            value={getDataFromArray(
                              specificAreas,
                              "title",
                              jobSeekerSearchFieldsandValue.specificArea,
                              "single"
                            )}
                            options={specificAreas} //TOWN_NAME
                            onChange={
                              (e, val) => {
                                handleSearchFieldsandValue(
                                  "specificArea",
                                  val ? val.title : "Any",
                                  "jobseeker"
                                );
                              }
                              // setSpecificArea(val.title ? val.title : "");
                            }
                            getOptionLabel={(option) => (option.title ? option.title : "")}
                            renderInput={(params) => <MKInput {...params} variant="standard" />}
                          />
                        </Grid>
                      </>
                    )}
                  </>
                )}
                <Grid item xs={12} lg={3} sx={{ mt: 4 }}>
                  <Box sx={{ m: 1, position: "relative" }}>
                    <MKButton
                      variant="gradient"
                      disabled={loading || !professionTitle}
                      color="info"
                      onClick={() => {
                        search();
                      }}
                      fullWidth
                    >
                      {/* { (loading?"searching" :"search" ) +" " +professionTitle} */}
                      search
                    </MKButton>
                    {loading && (
                      <CircularProgress
                        size={24}
                        sx={{
                          // color: green[500],
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          marginTop: "-12px",
                          marginLeft: "-12px",
                        }}
                      />
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Container>
            {forceRenderer}
          </MKBox>
        )}
        <Accordion
          expanded={searchExpanded}
          onChange={() => {
            setSearchExpanded(!searchExpanded);
            // console.log("searchExpanded",searchExpanded,e)
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreOutlined />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <MKTypography variant="h6" opacity={0.4}>
              {searchExpanded ? "Hide Search" : "Show Search"}
            </MKTypography>
          </AccordionSummary>
        </Accordion>
      </Card>
      {searchResult && (
        <Card
          sx={{
            my: 2,
            backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
            backdropFilter: "saturate(200%) blur(30px)",
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
            overflow: "hidden",
          }}
        >
          <MKBox component="section">
            <Container>
              <Grid container spacing={{ xs: 0, lg: 3 }} sx={{ pt: 2, pb: 3, px: 2, mx: "auto" }}>
                <Grid item sm={12} lg={2} sx={{ mt: 2 }}>
                  {/* <MKTypography
                display="block"
                variant="button"
                fontWeight="regular"
                color="text"
                mb={1}
              >
                Filter by
              </MKTypography> */}

                  <MKTypography variant="h6" opacity={0.4}>
                    {"Filter by"}
                  </MKTypography>
                </Grid>
                <Grid item xs={12} lg={3} sx={{ mt: 2 }}>
                  <TextField
                    fullWidth
                    id="skillsOrSubjects"
                    defaultValue={""}
                    label="Skills or Subjects"
                    variant="outlined"
                    onChange={(e) => {
                      filterResult("skillsOrSubjects", e.target.value);
                    }}
                  />
                </Grid>
                {/* <Grid item xs={12} lg={3} sx={{ mt: 2 }}> 
              <TextField fullWidth id="outlined-basic" label="Outlined" variant="outlined" 
                    onChange={(e, val) => { 
                      // handleSearchFieldsandValue("jobId",val?val.id:"","job")
                      // setProfessionTitle(val?val.title:""); 
                    }}
              /> 
            </Grid> */}
              </Grid>
            </Container>
            {forceRenderer}
          </MKBox>
        </Card>
      )}
    </>
  );
}

export default Search;
