/**
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import profilePicture from "assets/images/bruce-mars.jpg";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import { Avatar, Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Icon, Stack } from "@mui/material";
import MKButton from "components/MKButton";
import { useNavigate } from "react-router-dom";
import React from "react";
import { isMobile } from "react-device-detect";

  const ratings = {
    0.5: [
      <Icon key={1}>star_outline</Icon>,
      <Icon key={2}>star_outline</Icon>,
      <Icon key={3}>star_outline</Icon>,
      <Icon key={4}>star_outline</Icon>,
      <Icon key={5}>star_outline</Icon>,
    ],
    1: [
      <Icon key={1}>star</Icon>,
      <Icon key={2}>star_outline</Icon>,
      <Icon key={3}>star_outline</Icon>,
      <Icon key={4}>star_outline</Icon>,
      <Icon key={5}>star_outline</Icon>,
    ],
    1.5: [
      <Icon key={1}>star</Icon>,
      <Icon key={2}>star_half</Icon>,
      <Icon key={3}>star_outline</Icon>,
      <Icon key={4}>star_outline</Icon>,
      <Icon key={5}>star_outline</Icon>,
    ],
    2: [
      <Icon key={1}>star</Icon>,
      <Icon key={2}>star</Icon>,
      <Icon key={3}>star_outline</Icon>,
      <Icon key={4}>star_outline</Icon>,
      <Icon key={5}>star_outline</Icon>,
    ],
    2.5: [
      <Icon key={1}>star</Icon>,
      <Icon key={2}>star</Icon>,
      <Icon key={3}>star_half</Icon>,
      <Icon key={4}>star_outline</Icon>,
      <Icon key={5}>star_outline</Icon>,
    ],
    3: [
      <Icon key={1}>star</Icon>,
      <Icon key={2}>star</Icon>,
      <Icon key={3}>star</Icon>,
      <Icon key={4}>star_outline</Icon>,
      <Icon key={5}>star_outline</Icon>,
    ],
    3.5: [
      <Icon key={1}>star</Icon>,
      <Icon key={2}>star</Icon>,
      <Icon key={3}>star</Icon>,
      <Icon key={4}>star_half</Icon>,
      <Icon key={5}>star_outline</Icon>,
    ],
    4: [
      <Icon key={1}>star</Icon>,
      <Icon key={2}>star</Icon>,
      <Icon key={3}>star</Icon>,
      <Icon key={4}>star</Icon>,
      <Icon key={5}>star_outline</Icon>,
    ],
    4.5: [
      <Icon key={1}>star</Icon>,
      <Icon key={2}>star</Icon>,
      <Icon key={3}>star</Icon>,
      <Icon key={4}>star</Icon>,
      <Icon key={5}>star_half</Icon>,
    ],
    5: [
      <Icon key={1}>star</Icon>,
      <Icon key={2}>star</Icon>,
      <Icon key={3}>star</Icon>,
      <Icon key={4}>star</Icon>,
      <Icon key={5}>star</Icon>,
    ],
  };

function TutorCard({ jobSeeker,image, name, position, description }) {
  
  const [openedDialog , setOpenedDialog ] = React.useState("");
  const [forceRerender , setForceRerender ] = React.useState(false);
  const [moreText, setMoreText] = React.useState({content:"",title:""});
  const navigateComp=useNavigate()

  const viewTutorProfile=()=>{
    let path="/pages/JobSeeker-Details?id="+jobSeeker.id//+"&employmentAgencyId="+jobSeeker.employmentAgencyId
    navigateComp(path)
  }

  const truncateString=(str, num, lableName,) =>{
    let res=str
    try {
      if (str) {
        if (str.length > num) { 
        let btn= <Button variant="link" size="small" opacity={0.2} onClick={()=>{setMoreText({content:str,title:lableName}); setOpenedDialog("moreText"); setForceRerender(!forceRerender) }} >
        
        {/* <MKTypography variant="link" > */}
                    {  "View More"  }
                  {/* </MKTypography>  */}
          
          </Button>
        let txt=str.slice(0, num) + "..."
        res= <>{txt} {btn}</>;
        }  
    } 
  }catch (error) {
      // console.log("errrr",error)
    }
    return res
  }
 
  const truncateStringDefualt=(str, num, lableName,) =>{
    let res=""
    try {
      if (str) {
        if (str.length > num) {  
        let txt=str.slice(0, num) + "..."
           res= txt 
        }  
        else{ 
           res= str 
        }
    } 
  }catch (error) {
      // console.log("errrr",error)
    }
    return res
  }
    
    

  return (<>
    <Card sx={{ mt: 3 }}>
      <Grid container>
        <Grid item xs={12} md={5} lg={4} sx={{ mt: -6 }}>
          <MKBox width="100%" pt={2} pb={1} px={2} alignItems="center">

         
      <Grid container>
            <Grid item xs={6} md={6} lg={12} sx={{ my: "auto" }}>
                <Avatar alt="Remy Sharp" 
                    sx={{ width: 100, height:100 }} src={
                      image
                       ? image
                       : profilePicture
                    }/>    
                     </Grid>
                <Grid item xs={6} md={6} lg={12} sx={{ my: "auto" ,pt:{ xs: 5, lg: 2 }}}>
                <MKTypography variant="h5">{name}</MKTypography>

            {(jobSeeker.currentEducationLevelEducatedSchool)&&
            <MKTypography variant="h6" color={position.color} mb={1}>
              { truncateStringDefualt(jobSeeker.currentEducationLevelEducatedField,16) +", "+  truncateStringDefualt(jobSeeker.currentEducationLevelEducatedSchool,16) }
            </MKTypography>}
                
               </Grid>
            </Grid>
          </MKBox>
        </Grid>

        <Grid item xs={12} md={5} lg={5} sx={{ my: "auto" }}>
          <MKBox pt={{ xs: 1, lg: 2.5 }} pb={2.5} pr={4} pl={{ xs: 4, lg: 1 }} lineHeight={1}>
   
            <MKTypography variant="body2" color="text">
              {truncateString(jobSeeker.description,70)}
            </MKTypography>
            {(jobSeeker.skillsOrSubjects)&&<>
            {(jobSeeker.skillsOrSubjects.length)&& <>
            {(jobSeeker.skillsOrSubjects.length>0)&& 
             <Stack direction="row"  useFlexGap flexWrap="wrap" spacing={1}>
                      {truncateStringDefualt(jobSeeker.skillsOrSubjects,isMobile?60:70).split(",").map((skillD)=> 
              <Chip size="small" style={{marginBottom:"3px"}} label={skillD}/> 
            )} </Stack>}</>}</>}
   
            {/* <MKTypography
              variant="h4"
              // color={color === "transparent" || color === "light" ? "text" : "white"}
              sx={{
                display: "flex",
                alignItems: "center",
                ml: 0.375,

                "& .material-icons-round": {
                  ml: -0.375,
                },
              }}
            >
              {ratings[5]}
            </MKTypography> */}
          </MKBox>
        </Grid>
        
        <Grid item xs={12} md={2} lg={2} sx={{ my: "auto" }}>
          
        <MKBox pt={{ xs: 1, lg: 2.5 }} pb={2.5} pr={4} pl={{ xs: 4, lg: 1 }} lineHeight={1}>
             <MKButton onClick={()=>{viewTutorProfile({})}} justifyContent="right" variant="outlined" color="info" size="small">
                  Select
                  <Icon sx={{ ml: 1 }}>favorite</Icon>
              </MKButton>
          </MKBox>
        </Grid>
      </Grid>
    </Card>
    
    <Dialog open={openedDialog=="moreText"} onClose={()=>{setOpenedDialog("")}}>
        {moreText&&<>
       {moreText.title&& <DialogTitle>{moreText.title}</DialogTitle>}
       {moreText.content&& <DialogContent>
          <DialogContentText>
           {moreText.content}
          </DialogContentText> 
        </DialogContent>}</>}
        <DialogActions>
          <Button onClick={()=>{setOpenedDialog("")}}>close</Button> 
        </DialogActions>
      </Dialog>
      {forceRerender}
  </>);
}

// Typechecking props for the TutorCard
TutorCard.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  position: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ]),
    label: PropTypes.string.isRequired,
  }).isRequired,
  description: PropTypes.string.isRequired,
};

export default TutorCard;
