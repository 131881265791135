 
// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import PinterestIcon from "@mui/icons-material/Pinterest";
import GitHubIcon from "@mui/icons-material/GitHub";
import React, { useState ,useEffect} from "react";

// Material Kit 2 PRO React examples
import CenteredFooter from "examples/Footers/CenteredFooter";
import { useCurrentUser } from "Api-contexts/currentUserContext";
import { Icon } from "@mui/material";

function FooterThress() {
  const [currentData] = useCurrentUser();
  const {websitePagesContent } = currentData;
  const [footerPage, setFooterPage] = React.useState(null);
  useEffect(() => {
    // console.log("websitePagesContent",websitePagesContent) 
    if(websitePagesContent){
      if(websitePagesContent.pages){
        if(websitePagesContent.pages.footerPage){
          if(websitePagesContent.pages.footerPage.sections){ 
            setFooterPage(websitePagesContent.pages.footerPage.sections)
          }
        }
      }
    }
  }, [websitePagesContent]);

  const company = { href: "https://bfamily.et/", name: "B-Family" };
  const links = [
    { href: "https://baally.com", name: "Baally.com" }, 
  ];
  var socials = []
  if(footerPage){
    if(footerPage.socialMedias){
      if(footerPage.socialMedias.length){
        
        footerPage.socialMedias.forEach(element => {
          socials.push({
            icon: <span style={{ marginRight: 5,  fontSize: 28,}}> 
              <i className={"fab "+element.icon } />
          </span>,
            link: element.link,
          })
           });
      }
    }
  } 

  return <CenteredFooter company={company} links={links} socials={socials} />;
}

export default FooterThress;
