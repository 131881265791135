/**
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
var convert = require('xml-js');
import parse from 'html-react-parser';
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import queryString from "query-string"; 
// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import bgImage from "assets/images/bg3.jpg";
// HelpCenter page components
import FaqCollapse from "pages/Support/HelpCenter/components/FaqCollapse";
import { HttpService } from "Api-Services/httpService";
import axios from "axios";
import { mostPopularAnswer } from "Api-master-datas/masterDatas";
import { mostPopularAnswer6 } from "Api-master-datas/masterDatas";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";

function AskQuestion() {
  const [collapse, setCollapse] = useState(false);
  const [forceRerender, setForceRerender] = useState(false);
  const [relatedQuestions, setRelatedQuestions] = useState(null);
  const [searchQuestionText, setSearchQuestionText] = useState("");

  const [searchParam, setSearchParam] = useState(window.location?queryString.parse(window.location.search):props.search)
  useEffect(() => {
    (async () => { 
     await getStarted();  
    })() 
  }, []);

  

  const getStarted=async()=>{  
    
    if(searchParam){
      // console.log(  " searchParam: ", searchParam   );  
      setSearchQuestionText(searchParam.question)
        await postDocuments("searchquestion","/search/question?question="+searchParam.question, {"transactionId":0},"api4")//init user offices and data   
//  await getDocumentsAll("content","/content/answers/"+searchParam.answerId,{},"api2")//init user offices and data  
    }   
      
    
    // await getDocumentsAll("content","/content?limit=20&mode=EXTENDED",{},"api1")//init user offices and data   
    // await getDocumentsAll("getCatagories","/categories?limit=1000",{})//init user offices and data   
    // await getDocumentsAll("getContentTypes","/contentTypes",{})//init user offices and data   
    // await getDocumentsAll("getContentType","/contentTypes/3DC39C3342C84A479A1BF2B25B051947/contentSchema",{})//init user offices and data   
  }

  const getDocumentsAll=async( documentName,relativePath ,additionalData )=>{ 
    let responseD={success:false,message:"",record: null};
    let documentsResponse={success:false}  
    ////////// UPDATED/////////////
    documentsResponse=  await HttpService.getService(relativePath,additionalData?additionalData:{},""); 
    // console.log(documentName, " data: ", documentsResponse   );     
    
    if(documentsResponse.success){  
      responseD.success=true;
      responseD.record=documentsResponse.data; 
      responseD.message=documentsResponse.message;  
      let record=responseD.record;
  
      //responseD.record //write conditions down here
  
      if((documentName=="getCatagories")){     
        if(responseD.record){
          // setCatagories(responseD.record.items)  
        }
         }
      if((documentName=="getContentTypes")){    
        if(responseD.record){
        // setContentTypes(responseD.record.items) 
        // getContentTypesSchemas(responseD.record.items) 
        }
         }
      
}
      //  console.log(documentName, " responseD: ", responseD   );   
       setForceRerender(!forceRerender);
       return responseD;
    ////////// UPDATED///////////// 
 
}

const postDocuments=async(documentName,relativePath,data  ,apiType  )=>{   
  let responseD={success:false,message:"",record: null};
  let documentsResponse={success:false}  
  // console.log("getcommonContent,documentsRequest commonContentId",data,relativePath)

  documentsResponse= await HttpService.postService(relativePath,data,{},"" ,apiType); 
  // console.log("getcommonContent,documentsResponse",documentsResponse)
  
  if(documentsResponse.success){  
    responseD.success=true;
    responseD.record=documentsResponse.data; 
    responseD.message=documentsResponse.message;  
    let record = responseD.record
         
  if((documentName=="getCommonContent")){
    // console.log("get commonContent", record)

    if(record.length>0){
      // setCommonContent(record[0])  
      }  
    } 

  if((documentName=="searchquestion")){

    // console.log("record", record.results)
    if(record.results){
    if(record.results.results.length>0){
    // console.log("record.results", record.results.results[0].resultItems)
     setRelatedQuestions(record.results.results[0].resultItems) 
      }  
    } }
    } 
    setForceRerender(!forceRerender);
    
      return responseD;
}

 const searchQuestion=async()=>{
  if(searchQuestionText){

    await postDocuments("searchquestion","/search/question?question="+searchQuestionText, {"transactionId":0},"api4")//init user offices and data   
  }
 }

 const goToPage=(page)=>{   
    // let pagePath="/page/"
    //     pagePath+=pageName.replace(" ","")
    //     pagePath+="?pageId="+pageId+"&pageName="+pageName
      window.location.assign(page);
     
    // else{
    //   window.open(page, "_blank")
    // }

  }
  
  return (<>
  
  <MKBox
        minHeight="20vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      > 
      </MKBox>
    <MKBox component="section" py={12}>
      <Container>
        {forceRerender}
      {relatedQuestions&&
        <Grid container justifyContent="center">
          <Grid item xs={12} md={6} my={6}>
            <MKTypography variant="h2" align="center" fontWeight="bold" gutterBottom>
            Related Questions
            </MKTypography>
            {/* <MKBox mb={2}>
              <MKTypography variant="body2" align="center" color="text">
                A lot of people don&apos;t appreciate the moment until it&apos;s passed. I&apos;m
                not trying my hardest, and I&apos;m not trying to do
              </MKTypography>
            </MKBox> */}
          </Grid>
          <Grid item xs={12} md={10}>
           {(relatedQuestions.map((articleD,index)=>{

            // console.log("artt",articleD,)
            // title.snippets[0].text
          //   var article = convert.xml2json(articleD.xml, {compact: true, spaces: 4});
          //   article = JSON.parse(article)
          //   console.log("artt", article) 
            if(1){
           return (<FaqCollapse
           
              title={articleD.title.snippets[0].text}
              open={collapse === index}
              onClick={() =>{ 
                goToPage("/pages/Article?answerId="+articleD.globalAnswerId)
                // (collapse === index ? setCollapse(false) : setCollapse(index))
              }}
            > 
              {/* {parse(article[articleD.contentType.referenceKey]["ANSWER"]._cdata)} */}
            </FaqCollapse>)}
          } )) }
          </Grid>
        </Grid>}

      <Grid
          container
          item
          justifyContent="center"
          xs={12}
          lg={6}
          sx={{ mx: "auto", pb: 3, textAlign: "center" }}
        > 
          <MKTypography variant="h2" my={1}>
           Type your question below
          </MKTypography> 
        </Grid> 
        <Grid container spacing={3}  
        
            flexDirection="column"
            justifyContent="center"
            alignItems="center">
          <Grid item xs={11} md={8} lg={8}>
            <MKInput label="Type your question here..." onChange={(e)=>{console.log("m",e.target.value);  setSearchQuestionText(e.target.value)}}/>
            </Grid>
            
          <Grid item xs={12} md={6} lg={4}>
          {/* <Grid
            // container
            item
            xs={4}
            lg={4}
            // flexDirection="column"
            // justifyContent="center"
            // alignItems="center"
            // sx={{ mx: "auto", textAlign: "center" }}
          > */}
            <MKButton variant="gradient" color="info" onClick={()=>{searchQuestion()}}>
              search  
            </MKButton>
            </Grid> 
            </Grid> 
      </Container>
    </MKBox>
  </>);
}

export default AskQuestion;
