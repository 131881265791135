// @mui material components 
import Icon from "@mui/material/Icon";

// Material Kit 2 PRO React components  

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import { cities, specificAreas, subCities, citiesOnly } from "Api-master-datas/masterDatas.js";
import queryString from "query-string";
import { toast } from "react-toastify";

import PropTypes from 'prop-types';

import parse from 'html-react-parser';
// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
// import MKDatePicker from "components/MKDatePicker";
import MKButton from "components/MKButton";
import { Link, useNavigate } from "react-router-dom";
import { useCurrentUser } from "Api-contexts/currentUserContext";
import React, { useEffect } from "react";
import { HttpService } from "Api-Services/httpService";

import ReactGA from "react-ga4";
import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { 
  Box,
  Button,
  CardActions,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  Input,
  InputBase,
  Menu,
  MenuItem,
  Paper,
  Select,
  Skeleton,
  Stack, 
  Tab, 
  Tabs, 
  Typography,
} from "@mui/material";
import { Card, TextField } from "@mui/material";
import { element } from "prop-types";
import { ArrowBack, ArrowForwardIosSharp, Delete, Directions, ExpandMore, ExpandMoreOutlined, Favorite, Filter, Phone, RecentActors, Search, Send, Share, Update, VerifiedUser, Work } from "@mui/icons-material";
import { CommonServices } from "Api-Services/commonServices"; 
import { isMobile } from "react-device-detect";


const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderBottom: "darkgreen solid",
  '&:not(:last-child)': {
    // borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharp sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
}));



function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 2,px:1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function MyApplications() { 
  // console.log("cities", cities);
 
  // const [searchResult, setSearchResult] = React.useState([]);
  const [notSearchedYet, setNotSearchedYet] = React.useState(true);
  // const [forceRerender, setForceRerender] = React.useState(false); 
 
  const navigateComp = useNavigate();
  // console.log("cities", cities);

  const [currentData] = useCurrentUser();
  const { myJobSeekerInformation,currentUser,  myJobApplications, jobs, agencyData, isAgentWebsite } = currentData;
  const [currentEducationLevelId, setCurrentEducationLevelId] = React.useState(5);
  const [sex, setSex] = React.useState("Any");
  const [professionTitle, setProfessionTitle] = React.useState("");
  const [jobSeekerSearchFieldsandValue, setJobSeekerSearchFieldsandValue] = React.useState({
    currentEducationLevelId: "Any",
    sex: "Any",
    city: "Any",
    subCity: "Any",
    specificArea: "Any",
  });
  const [jobSearchFieldsandValue, setJobSearchFieldsandValue] = React.useState({
    jobId: "",
  });
  const [jobId, setJobId] = React.useState("");
  const [agencyJobs, setAgencyJobs] = React.useState(null);
  const [jobsToChoose, setJobsToChoose] = React.useState(jobs);
  const [forceRenderer, setForceRenderer] = React.useState(false);
  const [searchExpanded, setSearchExpanded] = React.useState(true);
  const [filterExpanded, setFilterExpanded] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [searchResult, setSearchResult] = React.useState(null);
  const [expanded, setExpanded] = React.useState('');
 
  const [forceRerender, setForceRerender] = React.useState(false);
  const [searchKeyword, setSearchKeyword] = React.useState("");
  const [searchedKeyword, setSearchedKeyword] = React.useState("");
  const [isFirstTimeSearch, setIsFirstTimeSearch] = React.useState(true);
  const [openedDialog, setOpenedDialog] = React.useState("");
  const [moreText, setMoreText] = React.useState({content:"",title:""});
  const [value, setValue] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };


  const viewDetails=(id)=>{
    let path="/pages/jobs/job-details?id="+id//+"&employmentAgencyId="+jobSeeker.employmentAgencyId
    navigateComp(path)
  }

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  useEffect(() => {
    // console.log("myJobApplications", myJobApplications);
    // console.log(" myJobSeekerInformation", myJobSeekerInformation);
    if (location.search) {
      let locationSearch = queryString.parse(location.search);
      if (locationSearch) {
        if (locationSearch.search) {
          searchByKeyword(locationSearch.search);
        }
      }
    }
  }, [location.pathname,location.search]);
 

  useEffect(() => {
    initializeFilter();
    //     const newArr = cities.map(({
    //       EASTING,
    //       ELEVATION
    //  ,     ELEV_TXT
    //   ,    EROSIVITY
    //    ,   ETTOWN01CO
    //     ,  ETTOWN01_1
    //      , FID
    //       ,K_VALUE
    //     ,  LAT
    //      , LON
    //      , NORTHING ,
    //       POP,
    //      POPRUR
    //  ,     POPTOT
    //   ,    POPURB
    //    ,   POP_TXT
    //     ,  RAIN
    //      , RAIN_TXT ,
    //       REGION,
    //       SOIL
    //   ,    WEREDA
    //    ,   ZONES
    //     ,  the_geom
    //       , ...rest}) => {
    //       return rest;
    //     });

    // console.log("cities", cities);

    // console.log(" myJobSeekerInformation", myJobSeekerInformation);

    // console.log("areas", specificAreas)
    // if(location.pathname.indexOf("job")>-1){
    //   ReactGA.send({ hitType: "pageview", page: location.pathname, title: "job search" });
    // }
  }, []);

  useEffect(() => {
    // console.log("agencyData",agencyData)
    let filterdJobs = [];
    if (agencyData) {
      if (agencyData.jobsId) {
        let allagencyjobs = agencyData.jobsId.split(",");
        filterdJobs = jobs.filter((jobD) => {
          let res = false;
          allagencyjobs.forEach((element) => {
            element = parseInt(element);
            if (element) {
              if (element == jobD.id) {
                res = true;
              }
            }
          });
          return res;
        });
        setAgencyJobs(filterdJobs);
        setJobsToChoose(filterdJobs);
      }
    }
     setForceRenderer(!forceRenderer);
  }, [agencyData, jobs]);

  const goToPage = (page) => {
    navigateComp(page);
  };

  const initializeFilter = () => {
    // setJobSeekerSearchFieldsandValue (
    //     )
    // setForceRenderer(!forceRenderer)
  };

  const handleSearchFieldsandValue = async (field, value, objectType) => {
    try {
      let searchFieldsandValueD = {};
      if (objectType == "jobseeker") {
        searchFieldsandValueD = jobSeekerSearchFieldsandValue;
      }
      if (objectType == "job") {
        searchFieldsandValueD = jobSearchFieldsandValue;
      }

      searchFieldsandValueD[field] = value;

      if (objectType == "jobseeker") {
        setJobSeekerSearchFieldsandValue(searchFieldsandValueD);
      }
      if (objectType == "job") {
        setJobSearchFieldsandValue(searchFieldsandValueD);
      }
      // console.log("searchFieldsandValueD", searchFieldsandValueD);

      setForceRenderer(!forceRenderer);
    } catch (error) {}
  };
 
 
  const parseDate = (dt) => {
    let d = new Date(dt);
    let m = d.getMonth() + 1;
    let dateD = m + "/" + d.getDate() + "/" + d.getFullYear();
    return dateD;
  };

  const filterResult = (searchField, searchValue) => {
    let res = { professionTitle: "", employees: null };
    let dataArray = searchResult;
    // console.log("dataArray   ", dataArray, searchField, searchValue);
    try {
      if (dataArray && searchField && searchValue) {
        if (dataArray.length > 0) {
          let filteredLevels = dataArray.filter((level) => {
            if (level[searchField]) {
              return level[searchField].indexOf(searchValue) > -1;
            } else {
              return false;
            }
          });
          if (filteredLevels.length) {
            res = filteredLevels;
          }
        }
      } else if (!searchValue) {
        res = dataArray;
      }
      // console.log("filtered    ", res);
    } catch (error) {
      // console.log("filtered city error", error);
    }

    res.professionTitle = professionTitle || "";
    res.employees = res;

    handleSearchResult(res);
  };

  const getDataFromArray = (dataArray, searchField, searchValue, returnRows, returnField) => {
    let res = {};
    // console.log("dataArray   ", dataArray,searchField, searchValue, returnRows, returnField);
    try {
      if (dataArray && searchField && searchValue) {
        if (dataArray.length > 0) {
          let filteredLevels = dataArray.filter((level) => level[searchField] == searchValue);
          if (filteredLevels.length) {
            res = filteredLevels;
            if (returnRows == "single") {
              let row = filteredLevels[0];
              res = row;
              if (returnField) {
                let rowField = row[returnField];
                res = rowField;
              }
            }
          }
        }
      }
      // console.log("filtered city  ", res);
    } catch (error) {
      // console.log("filtered city error", error);
    }
    return res;
  };

  const postDocuments = async (documentName, relativePath, data) => {
    let responseD = { success: false, message: "", record: null };
    let documentsResponse = { success: false };
    // setLoading(true);

    ////////// UPDATED/////////////
    documentsResponse = await HttpService.postService(relativePath, data, {}, "");
    // console.log(documentName, " data: ", documentsResponse   );
    // setLoading(false);

    if (documentsResponse.data) {
      responseD.record = documentsResponse.data.record;
      responseD.message = documentsResponse.data.message;
    }
    if (documentsResponse.success) {
      responseD.success = true;
      let record = responseD.record;

      //write codtions down here//
    }

    // console.log(documentName, " responseD: ", responseD);
    // setForceRerender(!forceRerender);
    return responseD;
    ////////// UPDATED/////////////
  };

  
  const truncateString=(str, num, lableName) =>{
    let res=str
    try {
      if (str) {
        if (str.length > num) { 
        let btn=lableName? <Button variant="text" onClick={()=>{setMoreText({content:str,title:lableName}); setOpenedDialog("moreText"); setForceRerender(!forceRerender) }} >{"View more"}</Button>:null
        let txt=str.slice(0, num) + "..."
        res= <>{parse(txt)} {btn}</>;
        } 
        else{
          
        res= <>{parse(res)}</>;
        } 
    } 
  }catch (error) {
      // console.log("errrr",error)
    }
    return res
  }
  

  const jobList=(jobsData)=>{
    try {
      
    return (<>
  
    { (jobsData)&& <div style={ {marginBottom:"10px"}}
    >
        { jobsData.map((jobVacancy)=>
         <div style={ {marginBottom:"10px"}}>
        <Accordion expanded={expanded === jobVacancy.id} 
          onChange={handleChange(jobVacancy.id)}
          >
            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header"
                  // expandIcon={<ExpandMoreOutlined/>}
              >
              {/* <MKBox component="section"> */}
                {/* <Container> */}
                  <Grid container   >
                      <Grid item xs={12} lg={9} sx={{ mt: 2 }}>
                        <Grid container spacing={{ xs: 0, lg: 0 }} 
                        // sx={{ pt: 2, pb: 3, px: 2, mx: "auto" }}
                        >

                      <Grid item xs={12} lg={6} sx={{ mt: 2 }}>
                          <MKTypography
                              display="block"
                              variant="h6"
                              fontWeight="bold"
                              // color="text"
                              mb={1}
                            >
                              {truncateString(jobVacancy.employmentJobVacancyTitle,65)}
                            </MKTypography>
                          <MKTypography
                              display="block"
                              variant="button"
                              fontWeight="regular"
                              // color="text"
                              mb={1}
                            >
                            Applied date: {parseDate(jobVacancy.createdAt)}
                            </MKTypography>
                          </Grid> 

                          <Grid item xs={12} lg={4} sx={{ mt: 2 }}>
                            <MKTypography
                              display="block"
                              variant="button"
                              fontWeight="regular"
                              color="text"
                              mb={1}
                            >
                              Status
                            </MKTypography> 
                          <MKTypography
                              display="block"
                              variant="button"
                              fontWeight="regular"
                              // color="text"
                              mb={1}
                            >
                           {jobVacancy.statusName}
                            </MKTypography>
                          </Grid> 
  

                        </Grid>
                      </Grid> 
                      <Grid item xs={12} lg={3} sx={{ mt: 2 }}>
                        
                    {/* <MKButton
                          variant="gradient"
                          disabled={loading || !professionTitle}
                          color="success"
                          onClick={() => {
                            search();
                          }}
                          fullWidth
                        > 
                          Apply Now
                        </MKButton> */}
                        <Stack direction="row" m={1}  spacing={2}>
                        <MKButton
                        size="small"
                          variant="outlined"
                          // disabled={loading || !professionTitle}
                          color="success"
                          onClick={() => {
                            viewDetails(jobVacancy.employmentJobVacancyId);
                          }}
                          fullWidth
                        > 
                          View Job Details
                        </MKButton> 
                        </Stack>
                        <Stack direction="row" m={1}  spacing={2}> 
                        <MKButton fullWidth 
                          size="small"
                          variant="outlined" 
                            // disabled={loading || !professionTitle}
                            color="primary"
                            onClick={() => {
                              let path="/pages/support/contact-us"//+"&employmentAgencyId="+jobSeeker.employmentAgencyId
                              navigateComp(path)
                            }}
                              
                          > 
                            Contact Employer
                          </MKButton>
                        </Stack>
                    </Grid> 
                    
                    {/* <Grid item xs={12} lg={3} sx={{ mt: 4 }}>
                      <Box sx={{ m: 1, position: "relative" }}>
                        <MKButton
                          variant="gradient"
                          disabled={loading || !professionTitle}
                          color="info"
                          onClick={() => {
                            search();
                          }}
                          fullWidth
                        > 
                          search
                        </MKButton>
                        {loading && (
                          <CircularProgress
                            size={24}
                            sx={{ 
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              marginTop: "-12px",
                              marginLeft: "-12px",
                            }}
                          />
                        )}
                      </Box>
                    </Grid> */}
                  </Grid>
                {/* </Container> */}
              {/* </MKBox> */}
            </AccordionSummary> 
            <AccordionDetails>
              <MKBox ml={4}>
                  <MKTypography variant="h6"  color="text" >
                  Your Comment:
                  </MKTypography>
                  <MKTypography variant="body1" fontWeight="light" color="text">
                  {parse(jobVacancy.description)}
                  </MKTypography>
                  <Button
                    size="small"
                      // variant={Link}
                      // disabled={loading || !professionTitle}
                      // color="success"
                      onClick={() => {
                        viewDetails(jobVacancy.employmentJobVacancyId);
                      }}
                        
                    > 
                      Job Details
                    </Button>
                </MKBox>
            </AccordionDetails>
          </Accordion></div>) }


        </div>}


    </>)
    } catch (error) {
      console.log("error",error)
      return <></>
    }

  }
   

  const handleSearchResult = (result) => {
    // console.log("handleSearchResult",result)
    setNotSearchedYet(false)

    setProfessionTitle(result.professionTitle||"")
    let res = false;
    if (result.employees) { 
        res = result.employees;
    }
    if (res) {
      setSearchResult(res);
    } else {
      setSearchResult([]);
    }
    setForceRerender(!forceRerender); 
  };

  return (
    
    <MKBox component="section"  py={1} > 
        
          <MKBox component="section" p={3}  >
            <  >
            <>

            <Grid container  mb={2} >
                  
                  <Grid item xs={12} lg={5} sx={{ mt: 2 }} justifyContent={"flex-end"}>
 
        <MKTypography variant="h3" opacity={0.4}>
          {"My Applications"}
        </MKTypography>
      </Grid>
 

      </Grid>


      <Card
        sx={{
          my: 2,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
          overflow: "hidden",
        }}
      >  
      </Card>
 


      <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
         <Tabs value={value} onChange={handleTabChange} aria-label="basic tabs example">
         <Tab label="Applied Jobs"  {...a11yProps(0)}  icon={<Work />}   />  
         <Tab label="Employee" {...a11yProps(1)}  icon={<VerifiedUser />} />
        </Tabs>  
      </Box>

 
      <CustomTabPanel value={value} index={0}> 
      {jobList(myJobApplications)} 
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
      {/* {jobList(myJobApplications)}  */}
      </CustomTabPanel>  
    </Box>



      {/* </Card> */}

            {forceRenderer}
      {/* {searchResult && (
        <Card
          sx={{
            my: 2,
            backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
            backdropFilter: "saturate(200%) blur(30px)",
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
            overflow: "hidden",
          }}
        >
          <MKBox component="section">
            <Container>
              <Grid container spacing={{ xs: 0, lg: 3 }} sx={{ pt: 2, pb: 3, px: 2, mx: "auto" }}>
                <Grid item sm={12} lg={2} sx={{ mt: 2 }}>
              

                  <MKTypography variant="h6" opacity={0.4}>
                    {"Filter by"}
                  </MKTypography>
                </Grid>
                <Grid item xs={12} lg={3} sx={{ mt: 2 }}>
                  <TextField
                    fullWidth
                    id="skillsOrSubjects"
                    defaultValue={""}
                    label="Skills or Subjects"
                    variant="outlined"
                    onChange={(e) => {
                      filterResult("skillsOrSubjects", e.target.value);
                    }}
                  />
                </Grid>
               
              </Grid>
            </Container>
            {forceRenderer}
          </MKBox>
        </Card>
      )} */}


      
    <Dialog open={openedDialog=="moreText"} onClose={()=>{setOpenedDialog("")}}>
        {moreText&&<>
       {moreText.title&& <DialogTitle>{parse(moreText.title)}</DialogTitle>}
       {moreText.content&& <DialogContent>
          <DialogContentText>
           {parse(moreText.content)}
          </DialogContentText> 
        </DialogContent>}</>}
        <DialogActions>
          <Button onClick={()=>{setOpenedDialog("")}}>close</Button> 
        </DialogActions>
      </Dialog>
    </>
               
            </>
          </MKBox> 
          {forceRerender}  
    </MKBox>
  );
}

export default MyApplications;
