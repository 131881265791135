
var CryptoJS = require("crypto-js");
export class DataManagementService {
  static getFixedData = () => { 
    let agentBrowserUniqueData = navigator.userAgent;
    agentBrowserUniqueData= agentBrowserUniqueData.toString();
    let allFixedDatas={};
        allFixedDatas.localstorageDataSecretkey="dukemesfinlocalstorage"+agentBrowserUniqueData

    return allFixedDatas
  };



  static encryptData = async (data,variableName) => { 
    let allFixedDatas = this.getFixedData(); 
    var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), allFixedDatas.localstorageDataSecretkey).toString();
    return ciphertext
  };
  
  static getHostName = () => { 
    let hostName = location.hostname;
    hostName =  hostName.toString();; 
     
    for(let i=0;i<hostName.length;i++){
      hostName = hostName.replace(".", "");
    }
    return hostName

  } 

  static setLocalstorageDataSecured = async (data,variableName) => { 
    let allFixedDatas = this.getFixedData(); 
    var uIComponentsCiphertext = CryptoJS.AES.encrypt(JSON.stringify(data), allFixedDatas.localstorageDataSecretkey).toString();
   
    variableName=this.getHostName()+""+variableName
    localStorage.setItem(variableName, uIComponentsCiphertext );  

  }; 
  
  static getLocalstorageDataSecured = (variableName) => { 
    let allFixedDatas = this.getFixedData(); 

    variableName=this.getHostName()+""+variableName
    let localEncriptedData = localStorage.getItem(variableName);
      if(localEncriptedData){
      // Decrypt
          var bytes  = CryptoJS.AES.decrypt(localEncriptedData, allFixedDatas.localstorageDataSecretkey); 
          try{
          var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
          if(decryptedData) return decryptedData;
          }
          catch(e){ 
             return null;
          }
         
      }
      else{
        return null
      }

  };
 
 


}
