/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import queryString from "query-string";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKAvatar from "components/MKAvatar";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// Images
import profilePicture from "assets/images/bruce-mars.jpg";
import { useNavigate } from "react-router-dom";
import { useCurrentUser } from "Api-contexts/currentUserContext";
import Card from "@mui/material/Card";
import React, { useEffect } from "react";
import { Edit } from "@mui/icons-material";
import MKInput from "components/MKInput";
import {
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
} from "@mui/material";
import { HttpService } from "Api-Services/httpService";
const ratings = {
  0.5: [
    <Icon key={1}>star_outline</Icon>,
    <Icon key={2}>star_outline</Icon>,
    <Icon key={3}>star_outline</Icon>,
    <Icon key={4}>star_outline</Icon>,
    <Icon key={5}>star_outline</Icon>,
  ],
  1: [
    <Icon key={1}>star</Icon>,
    <Icon key={2}>star_outline</Icon>,
    <Icon key={3}>star_outline</Icon>,
    <Icon key={4}>star_outline</Icon>,
    <Icon key={5}>star_outline</Icon>,
  ],
  1.5: [
    <Icon key={1}>star</Icon>,
    <Icon key={2}>star_half</Icon>,
    <Icon key={3}>star_outline</Icon>,
    <Icon key={4}>star_outline</Icon>,
    <Icon key={5}>star_outline</Icon>,
  ],
  2: [
    <Icon key={1}>star</Icon>,
    <Icon key={2}>star</Icon>,
    <Icon key={3}>star_outline</Icon>,
    <Icon key={4}>star_outline</Icon>,
    <Icon key={5}>star_outline</Icon>,
  ],
  2.5: [
    <Icon key={1}>star</Icon>,
    <Icon key={2}>star</Icon>,
    <Icon key={3}>star_half</Icon>,
    <Icon key={4}>star_outline</Icon>,
    <Icon key={5}>star_outline</Icon>,
  ],
  3: [
    <Icon key={1}>star</Icon>,
    <Icon key={2}>star</Icon>,
    <Icon key={3}>star</Icon>,
    <Icon key={4}>star_outline</Icon>,
    <Icon key={5}>star_outline</Icon>,
  ],
  3.5: [
    <Icon key={1}>star</Icon>,
    <Icon key={2}>star</Icon>,
    <Icon key={3}>star</Icon>,
    <Icon key={4}>star_half</Icon>,
    <Icon key={5}>star_outline</Icon>,
  ],
  4: [
    <Icon key={1}>star</Icon>,
    <Icon key={2}>star</Icon>,
    <Icon key={3}>star</Icon>,
    <Icon key={4}>star</Icon>,
    <Icon key={5}>star_outline</Icon>,
  ],
  4.5: [
    <Icon key={1}>star</Icon>,
    <Icon key={2}>star</Icon>,
    <Icon key={3}>star</Icon>,
    <Icon key={4}>star</Icon>,
    <Icon key={5}>star_half</Icon>,
  ],
  5: [
    <Icon key={1}>star</Icon>,
    <Icon key={2}>star</Icon>,
    <Icon key={3}>star</Icon>,
    <Icon key={4}>star</Icon>,
    <Icon key={5}>star</Icon>,
  ],
};

function EmployeeDetails() {
  const [currentData] = useCurrentUser();
  const { currentUser, educationLevels, jobs } = currentData;
  // const { currentUser, educationLevels, myJobSeekerInformation, jobs } = currentData;
  const [expanded, setExpanded] = React.useState(false);
  const [myJobSeekerInformation, setJobSeekerInformation] = React.useState(null);
  const [user, setUser] = React.useState(null);
  const [forceRerender, setForceRerender] = React.useState(false);

  // user
  // {id: DataTypes.DOUBLE,
  // userFrontImage: DataTypes.TEXT,
  // userEmail: DataTypes.TEXT,
  // googleEmail: DataTypes.TEXT,
  // facebookId: DataTypes.TEXT,
  // userStatus: DataTypes.DOUBLE,

  // currentEducationLevel:DataTypes.DOUBLE ,
  // informationApproved:DataTypes.BOOLEAN ,
  // informationApprovedDate:DataTypes.TEXT ,
  // TotoringEducationLevelStart:DataTypes.DOUBLE ,
  // TotoringEducationLevelEnd:DataTypes.DOUBLE ,

  // isInstructor :DataTypes.BOOLEAN,
  // status :DataTypes.TEXT,
  // registeredDate :DataTypes.TEXT,

  useEffect(() => {
    if (location.search) {
      let locationSearch = queryString.parse(location.search);
      if (locationSearch.id) {
        search(locationSearch.id);
      }
    }
    // search()
    if (currentUser.user) {
      setUser(currentUser.user);
    }
  }, [currentUser, location.pathname, location.search]);

  const search = async (id) => {
    // console.log(" location.search", id);

    try {
      if (id) {
        let relativePath = "/api/employmentagencyjobseeker/getemployeebyid/" + id;
        // let AdditionalData={withCredentials: true, credentials: 'include'}//{  credentials: 'include' }//header can be included here
        let jobSeekerInformationRes = await getDocumentsAll("contactus", relativePath, {});
        if (jobSeekerInformationRes.success) {
          if (jobSeekerInformationRes.record) {
            setJobSeekerInformation(jobSeekerInformationRes.record);
          }
        }
      }
      // setLoading(false);
    } catch (error) {}
  };

  const getDocumentsAll = async (documentName, relativePath, additionalData) => {
    let responseD = { success: false, message: "", record: null };
    let documentsResponse = { success: false };
    ////////// UPDATED/////////////
    documentsResponse = await HttpService.getService(
      relativePath,
      additionalData ? additionalData : {},
      ""
    );
    if (documentsResponse.data) {
      responseD.record = documentsResponse.data.record;
      responseD.message = documentsResponse.data.message;
    }
    if (documentsResponse.success) {
      responseD.success = true;
      let record = responseD.record;
    }
    setForceRerender(!forceRerender);
    return responseD;
    ////////// UPDATED/////////////
  };

  const postDocuments = async (documentName, relativePath, data) => {
    let responseD = { success: false, message: "", record: null };
    let documentsResponse = { success: false };
    // setLoading(true);

    ////////// UPDATED/////////////
    documentsResponse = await HttpService.postService(relativePath, data, {}, "");
    // console.log(documentName, " data: ", documentsResponse   );
    // setLoading(false);

    if (documentsResponse.data) {
      responseD.record = documentsResponse.data.record;
      responseD.message = documentsResponse.data.message;
    }
    if (documentsResponse.success) {
      responseD.success = true;
      let record = responseD.record;

      //write codtions down here//
    }

    // console.log(documentName, " responseD: ", responseD);
    // setForceRerender(!forceRerender);
    return responseD;
    ////////// UPDATED/////////////
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const navigate = useNavigate();
  const goToPage = () => {
    navigate("/pages/SendTutorRequest");
  };

  const filterJobsFromString = (jobsString, field) => {
    let res = [];
    if (jobs && jobsString) {
      let jobsArray = jobsString.split(",");
      if (jobsArray) {
        let filteredLevels = jobs.filter((job) => {
          let ress = false;
          jobsArray.forEach((element) => {
            let id = parseInt(element);
            if (job.id == id) {
              ress = true;
            }
          });

          return ress;
        });
        res = filteredLevels;
      }
      // console.log("filtered jobs", res);
    }
    return res;
  };

  const updateUserInformation = async (dataD, dataParentName) => {
    // console.log("data", dataD);

    try {
      let id;
      let relativePath;
      if (dataParentName == "agencyjobseeker") {
        id = myJobSeekerInformation.id;
        relativePath = "/api/employmentagencyjobseeker/edit";
      } else if (dataParentName == "jobseeker") {
        id = myJobSeekerInformation.id;
        relativePath = "/api/employmentjobseeker/edit";
      }

      if (relativePath && id) {
        let data = {
          id: id,
        };
        data[dataD.fieldName] = dataD.value;
        // console.log("data", data);

        // let toastId = toast.loading("Updating Profile data...",{
        //   position: toast.POSITION.BOTTOM_LEFT
        // })

        let changeUserProfileRes = await postDocuments("changeUserInfo", relativePath, data);
        //  toast.update(toastId, { render: changeUserProfileRes.message, type: changeUserProfileRes.success?"success":"warn", isLoading: false , autoClose: 5000});

        if (changeUserProfileRes.success) {
          // setExpanded2(false)
          // updateContext("currentUser")
        }
      }
    } catch (error) {}
  };

  // const updateEmailInformation= async(action,dataD)=>{
  //   try {
  //    let data= null
  //    let relativePath=""
  //    if(action=="changeemail"){
  //        relativePath="/api/auth/changemyemail"
  //        data={"email":dataD.value}
  //    }
  //    else if(action=="verifyemail"){
  //        relativePath="/api/auth/verifymyemail"
  //       //  data={"pendingEmail":dataD.value}
  //        data={"emailVerificationCode":dataD.value}
  //    }
  //    else if(action=="dismisspendingemail"){
  //        relativePath="/api/auth/dismissmypendingemail"
  //        data={"email":dataD.value}
  //    }

  //   // console.log("data", data);
  //    if(data&&relativePath) {
  //         let toastId = toast.loading("taking action on email...",{
  //           position: toast.POSITION.BOTTOM_LEFT
  //         })

  //         let changeUserProfileRes= await postDocuments("emailaction",relativePath,data)
  //         toast.update(toastId, { render: changeUserProfileRes.message, type: changeUserProfileRes.success?"success":"warn", isLoading: false , autoClose: 5000});

  //         if(changeUserProfileRes.success){
  //           updateContext("currentUser")
  //           if(action=="changeemail"){
  //             setExpanded2("pendingEmail")
  //           }
  //         }
  //           }
  //   } catch (error) {

  //   }

  // }

  const editInputComponent = (
    parentFieldName,
    fieldName,
    fieldLabel,
    valueD,
    fieldType,
    options,
    optionsSelectedField,
    optionsSelectedLable
  ) => {
    // const editInputComponent = (fieldName, fieldLabel, valueD, fieldType, options, selectedField) => {
    // console.log("fieldName", valueD);
    let res = <></>;
    let inputComp = <></>;
    let value = valueD;
    try {
      if (fieldType == "textField" || fieldType == "email" || fieldType == "phone") {
        inputComp = (
          <>
            {" "}
            {value ? (
              <MKInput
                placeholder="Enter text"
                label={fieldLabel}
                defaultValue={value}
                onChange={(e) => {
                  // console.log("aaa", e.target.value);
                  value = e.target.value;
                }}
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            ) : (
              <MKInput
                placeholder="Enter text"
                label={fieldLabel}
                onChange={(e) => {
                  // console.log("aaa", e.target.value);
                  value = e.target.value;
                }}
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            )}
          </>
        );
      } else if (fieldType == "radio") {
        inputComp = (
          <>
            <FormControl>
              <FormLabel id="demo-controlled-radio-buttons-group">{fieldLabel}</FormLabel>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                defaultValue={value}
                onChange={(e) => {
                  // console.log("aaa", e.target.value);
                  value = e.target.value;
                }}
              >
                {options && (
                  <>
                    {options.length ? (
                      <>
                        {options.map((option) => (
                          <FormControlLabel
                            value={optionsSelectedField ? option[optionsSelectedField] : option}
                            control={<Radio />}
                            label={optionsSelectedField ? option[optionsSelectedField] : option}
                          />
                        ))}
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </RadioGroup>
            </FormControl>
          </>
        );
      } else if (fieldType == "fileUrl") {
        // let currentIcon={ c:input.value }
        inputComp = (
          <>
            <FormControl variant="outlined" fullWidth>
              {/* <span style={{ marginRight: 10,  fontSize: 18,}}> 
                <i className={"fab "+input.value } />
            </span>  */}
            </FormControl>
            {/* <GridItem xs={12} sm={12} md={12}> */}
            <Divider variant="middle" />
            <h4 divider> {fieldLabel} </h4>
            <div className="container">
              <div className="row">
                <div className="col s12">
                  {/* <h1>mmm</h1> */}
                  {/* <ServerPhotoGallery fileType="document"  filesLimit={1} initialFiles={[value]} 
                  uplodedImages={(data)=>{
                        if(data){
                          if(data.length){
                               value =data[0]
                            // onInputChange(data[0],input)
                          }
                        } 
                  }}  
                            /> */}
                </div>
              </div>
            </div>
            {/* </GridItem>  */}
          </>
        );
      }

      let accordionTitle = "-";
      if (fieldType == "fileUrl") {
        accordionTitle = (
          <Button
            href={value}
            target="_blank"
            onClick={() => {
              // setDocumentUrl(value);
              // setOpenedDialog("documentviewer")
            }}
            color="primary"
            round
            size="sm"
          >
            open file
          </Button>
        );
      } else if (fieldType == "chip") {
        accordionTitle = (
          <>
            {value.map((chipValue) => (
              <Chip
                label={optionsSelectedLable ? chipValue[optionsSelectedLable] : chipValue}
                variant="outlined"
              />
            ))}
          </>
        );
      } else {
        if (value) {
          accordionTitle = optionsSelectedLable
            ? value[optionsSelectedLable]
              ? value[optionsSelectedLable]
              : ""
            : value;
        }
      }

      res = (
        <>
          {/* <Accordion expanded={expanded === fieldName} onChange={handleChange(fieldName)}> */}
          <Accordion expanded={false}>
            <AccordionSummary
              // expandIcon={<Edit />}
              aria-controls="panel3bh-content"
              id="panel3bh-header"
            >
              <Typography sx={{ width: "53%", flexShrink: 0 }}>{fieldLabel}</Typography>
              <Typography sx={{ color: "text.secondary" }}> <strong> {accordionTitle}</strong></Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container alignItems="center">
                <Grid py={1} item xs={12} lg={6}>
                  {inputComp}
                </Grid>
                <Grid py={1} item xs={12} lg={6}>
                  <MKBox px={4}>
                    <Stack direction="row" spacing={2}>
                      <Button
                        variant="contained"
                        color="success"
                        onClick={() => {
                          let dataDD = {};
                          if (fieldType == "chip") {
                            let valuesD = "";
                            value.forEach((val) => {
                              valuesD =
                                valuesD +
                                (optionsSelectedField ? val[optionsSelectedField] : val) +
                                ",";
                            });
                            dataDD = {
                              value: valuesD,
                              fieldName: fieldName,
                            };
                          } else {
                            dataDD = {
                              value: optionsSelectedField ? value[optionsSelectedField] : value,
                              fieldName: fieldName,
                            };
                          }
                          updateUserInformation(dataDD, parentFieldName);
                          // console.log("value", value);
                        }}
                      >
                        Save
                      </Button>
                      <Button
                        color="secondary"
                        onClick={() => {
                          setExpanded(false);
                        }}
                      >
                        Cancel
                      </Button>
                    </Stack>
                  </MKBox>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </>
      );
    } catch (error) {
      // console.log("inp comp", error);
    }
    return res;
  };

  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      {myJobSeekerInformation && (
        <Container>
          <Grid container item xs={12} justifyContent="center" mx="auto">
            {myJobSeekerInformation && (
              <Grid container justifyContent="center" py={6}>
                <Grid item xs={12} md={4}>
                  <MKBox textAlign="left">
                    {/* <MKAvatar src={profilePicture} alt="Burce Mars" size="xxl" shadow="xl" /> */}
                    <MKBox
                      component="img"
                      src={
                        myJobSeekerInformation.profileImageUrl
                          ? myJobSeekerInformation.profileImageUrl
                          : profilePicture
                      }
                      // alt={name}
                      width="50%"
                      borderRadius="md"
                      shadow="lg"
                    />{" "}
                  </MKBox>
                </Grid>

                <Grid item xs={12} md={8}>
                  <MKBox justifyContent="space-between" alignItems="center" mb={1}>
                    <MKTypography variant="h3">
                      {myJobSeekerInformation.firstName
                        ? myJobSeekerInformation.firstName + " " + myJobSeekerInformation.lastName
                        : "Michael Roven"}
                    </MKTypography>

                    <MKTypography variant="h6" mb={1}>
                      {myJobSeekerInformation.currentEducationLevelEducatedField +
                        " , " +
                        myJobSeekerInformation.currentEducationLevelEducatedSchool}
                    </MKTypography>

                    <MKTypography
                      variant="h4"
                      // color={color === "transparent" || color === "light" ? "text" : "white"}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        ml: 0.375,

                        "& .material-icons-round": {
                          ml: -0.375,
                        },
                      }}
                    >
                      {ratings[5]}
                    </MKTypography>
                  </MKBox>
                  {/* <Grid container spacing={3} mb={3}>
                    <Grid item>
                      <MKTypography component="span" variant="body2" fontWeight="bold">
                        323&nbsp;
                      </MKTypography>
                      <MKTypography component="span" variant="body2" color="text">
                        Posts
                      </MKTypography>
                    </Grid>
                    <Grid item>
                      <MKTypography component="span" variant="body2" fontWeight="bold">
                        3.5k&nbsp;
                      </MKTypography>
                      <MKTypography component="span" variant="body2" color="text">
                        Followers
                      </MKTypography>
                    </Grid>
                    <Grid item>
                      <MKTypography component="span" variant="body2" fontWeight="bold">
                        260&nbsp;
                      </MKTypography>
                      <MKTypography component="span" variant="body2" color="text">
                        Following
                      </MKTypography>
                    </Grid>
                  </Grid> */}
                  <MKTypography variant="body1" fontWeight="light" color="text">
                    {myJobSeekerInformation.description}
                    <MKTypography
                      component="a"
                      href="#"
                      variant="body1"
                      fontWeight="light"
                      color="info"
                      mt={3}
                      sx={{
                        width: "max-content",
                        display: "flex",
                        alignItems: "center",

                        "& .material-icons-round": {
                          transform: `translateX(3px)`,
                          transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                        },

                        "&:hover .material-icons-round, &:focus .material-icons-round": {
                          transform: `translateX(6px)`,
                        },
                      }}
                    ></MKTypography>
                  </MKTypography>
                </Grid>
              </Grid>
            )}

            <Grid container item xs={12} py={6} justifyContent="center">
              <Card sx={{ width: "100%" }}>
                <Grid container alignItems="center">
                  <Grid item xs={12} lg={8}>
                    <MKBox py={3} px={4}>
                      <MKTypography variant="h3" mb={1}>
                        Job and availability Information
                      </MKTypography>
                      {/* <MKTypography variant="body2" color="text" fontWeight="regular">
                      You have Free Unlimited Updates and Premium Support on each package. You also
                      have 30 days to request a refund.
                    </MKTypography> */}
                      <Grid container item xs={12} lg={3} sx={{ mt: 6, mb: 1 }}>
                        <MKTypography variant="h6">Have &apos;'s experiance in</MKTypography>
                      </Grid>
                      <Grid container spacing={3}>
                        {filterJobsFromString(myJobSeekerInformation.jobsId).map((jobData) => (
                          <Grid item xs={12} md={6}>
                            <MKBox display="flex" py={1} pr={1} lineHeight={0}>
                              <MKTypography variant="body1" color="dark">
                                <Icon sx={{ fontWeight: "bold" }}>done</Icon>
                              </MKTypography>
                              <MKTypography
                                variant="body2"
                                color="text"
                                fontWeight="regular"
                                pl={1}
                              >
                                {jobData.title}
                              </MKTypography>
                            </MKBox>
                          </Grid>
                        ))}
                      </Grid>
                    </MKBox>
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <MKBox p={3} textAlign="center">
                      <MKTypography variant="h6" mt={{ xs: 0, sm: 3 }}>
                        Experiance
                      </MKTypography>
                      <MKTypography variant="h1">
                        {myJobSeekerInformation.workExperianceInMonth}
                        <MKBox component="small">{" Month(s)"}</MKBox>
                      </MKTypography>
                      <MKButton variant="gradient" color="error" size="large" sx={{ my: 2 }}>
                        Send Request
                      </MKButton>
                      <MKTypography
                        display="block"
                        variant="button"
                        color="text"
                        fontWeight="regular"
                      >
                        Brought to you by Employment Agency
                      </MKTypography>
                    </MKBox>
                  </Grid>
                </Grid>
                
                
              <Grid container alignItems="center">
                  <Grid item xs={12} lg={12}>
                    <MKBox py={3} px={4}> 
                      {editInputComponent(
                        "jobseeker",
                        "isAvailable",
                        "Available to be hired?",
                        myJobSeekerInformation.isAvailable,
                        "boolean"
                      )}
                      {/* {editInputComponent(
                        "jobseeker",
                        "isUserManaged",
                        "Is Agency Managed account? ",
                        myJobSeekerInformation.isUserManaged,
                        "boolean"
                      )}   */}
                      {editInputComponent(
                        "jobseeker",
                        "expectingSalaryPerHour",
                        "Expecting Salary Per Hour (ETB)", 
                        myJobSeekerInformation.expectingSalaryPerHour,
                        "number"
                      )}  
                      {editInputComponent(
                        "jobseeker",
                        "expectingSalaryPerMonth",
                        "Expecting Salary Per Month (ETB)", 
                        myJobSeekerInformation.expectingSalaryPerMonth,
                        "number"
                      )}  
                    </MKBox>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            {user && myJobSeekerInformation && (
              <Grid container item xs={12} py={6} justifyContent="center">
                <Card sx={{ width: "100%" }}>
                  <Grid container alignItems="center">
                    <Grid item xs={12} lg={12}>
                      <MKBox py={3} px={4}>
                        <MKTypography variant="h3" mb={1}>
                          Personal Informations
                        </MKTypography>
                        {editInputComponent(
                          "jobseeker",
                          "firstName",
                          "First Name",
                          myJobSeekerInformation.firstName,
                          "textField"
                        )}
                        {editInputComponent(
                          "jobseeker",
                          "middleName",
                          "Middle Name",
                          myJobSeekerInformation.middleName,
                          "textField"
                        )}
                        {editInputComponent(
                          "jobseeker",
                          "lastName",
                          "Last Name",
                          myJobSeekerInformation.lastName,
                          "textField"
                        )}
                        {editInputComponent(
                          "jobseeker",
                          "userEmail",
                          "User Email",
                          "-",
                          // myJobSeekerInformation.email,
                          "email"
                        )}
                        {editInputComponent(
                          "jobseeker",
                          "sex",
                          "Sex",
                          myJobSeekerInformation.sex,
                          "radio",
                          ["male", "female"]
                        )}
                        {editInputComponent(
                          "jobseeker",
                          "phone",
                          "Phone",
                          //  myJobSeekerInformation.phone,
                          "-",
                          "phone"
                        )}
                        <MKTypography mt={5} mb={2} mx={2}>
                          Address
                        </MKTypography>
                        {editInputComponent(
                          "jobseeker",
                          "country",
                          "Country",
                          myJobSeekerInformation.country,
                          "textField"
                        )}
                        {editInputComponent(
                          "jobseeker",
                          "city",
                          "City",
                          myJobSeekerInformation.city,
                          "textField"
                        )}
                       { (myJobSeekerInformation.city)&&<>
                      {(myJobSeekerInformation.city.toLowerCase()=="addis ababa")&&
                       <>
                        {editInputComponent(
                          "jobseeker",
                          "subCity",
                          "Sub City",
                          myJobSeekerInformation.subCity,
                          "textField"
                        )}
                        {editInputComponent(
                          "jobseeker",
                          "specificArea",
                          "Specific Area",
                          myJobSeekerInformation.specificArea,
                          "textField"
                        )}</>}
                        </>}
                      </MKBox>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            )}
            {user && myJobSeekerInformation && (
              <Grid container item xs={12} py={6} justifyContent="center">
                <Card sx={{ width: "100%" }}>
                  <Grid container alignItems="center">
                    <Grid item xs={12} lg={12}>
                      <MKBox py={3} px={4}>
                        <MKTypography variant="h3" mb={1}>
                          Educational Informations
                        </MKTypography>

                        <MKTypography mt={5} mb={2} mx={2}>
                          Education Level
                        </MKTypography>
                        {editInputComponent(
                          "jobseeker",
                          "currentEducationLevel",
                          "Education Level",
                          myJobSeekerInformation.currentEducationLevel,
                          "textField"
                        )}
                        {editInputComponent(
                          "jobseeker",
                          "currentEducationLevelEducatedField",
                          "Educated Field",
                          myJobSeekerInformation.currentEducationLevelEducatedField,
                          "textField"
                        )}
                        {editInputComponent(
                          "jobseeker",
                          "currentEducationLevelEducatedCampass",
                          "Educated School",
                          myJobSeekerInformation.currentEducationLevelEducatedSchool,
                          "textField"
                        )}
                        {editInputComponent(
                          "jobseeker",
                          "currentEducationLevelEducatedCountry",
                          "Educated Country",
                          myJobSeekerInformation.currentEducationLevelEducatedCountry,
                          "textField"
                        )}
                        {/* educationalDocumentAttachementUrl */}
                        {/* {editInputComponent(
                        "jobseeker",
                        "educationalDocumentAttachementUrl",
                        "Education Document Attachement",
                        myJobSeekerInformation.educationalDocumentAttachementUrl,
                        "fileUrl"
                      )} */}
                      </MKBox>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            )}
          </Grid>
        </Container>
      )}
    </MKBox>
  );
}

export default EmployeeDetails;
