/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon"; 
// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKAvatar from "components/MKAvatar";
import queryString from "query-string";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography"; 
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// Images
import { cities ,subCities,specificAreas} from "Api-master-datas/masterDatas.js";

import profilePicture from "assets/images/bruce-mars.jpg";
import { Link, useNavigate } from "react-router-dom";
import { useCurrentUser } from "Api-contexts/currentUserContext";
import Card from "@mui/material/Card";
import React, { useEffect } from "react";
import { ArrowDropDown, ArrowDropDownSharp, ArrowForwardIosSharp, Delete, Edit, ExpandMoreOutlined, Favorite, PersonPin, Phone, Visibility, VisibilityOff, Warning, WarningAmber } from "@mui/icons-material";
import MKInput from "components/MKInput";
import {
  Autocomplete,
  Avatar,
  Badge,
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FilledInput,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Input,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  OutlinedInput,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  Tab,
  Tabs, 
  TextField,  
} from "@mui/material";
import { HttpService } from "Api-Services/httpService"; 
import FileUploadComp from "components/common-components/fileUploadComp";
import axios from "axios";
import { CommonServices } from "Api-Services/commonServices";
import { isMobile } from "react-device-detect";
import { pink } from "@mui/material/colors";
import { UseAuth } from "Api-Services/useAuth";

import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

const ratings = {
  0.5: [
    <Icon key={1}>star_outline</Icon>,
    <Icon key={2}>star_outline</Icon>,
    <Icon key={3}>star_outline</Icon>,
    <Icon key={4}>star_outline</Icon>,
    <Icon key={5}>star_outline</Icon>,
  ],
  1: [
    <Icon key={1}>star</Icon>,
    <Icon key={2}>star_outline</Icon>,
    <Icon key={3}>star_outline</Icon>,
    <Icon key={4}>star_outline</Icon>,
    <Icon key={5}>star_outline</Icon>,
  ],
  1.5: [
    <Icon key={1}>star</Icon>,
    <Icon key={2}>star_half</Icon>,
    <Icon key={3}>star_outline</Icon>,
    <Icon key={4}>star_outline</Icon>,
    <Icon key={5}>star_outline</Icon>,
  ],
  2: [
    <Icon key={1}>star</Icon>,
    <Icon key={2}>star</Icon>,
    <Icon key={3}>star_outline</Icon>,
    <Icon key={4}>star_outline</Icon>,
    <Icon key={5}>star_outline</Icon>,
  ],
  2.5: [
    <Icon key={1}>star</Icon>,
    <Icon key={2}>star</Icon>,
    <Icon key={3}>star_half</Icon>,
    <Icon key={4}>star_outline</Icon>,
    <Icon key={5}>star_outline</Icon>,
  ],
  3: [
    <Icon key={1}>star</Icon>,
    <Icon key={2}>star</Icon>,
    <Icon key={3}>star</Icon>,
    <Icon key={4}>star_outline</Icon>,
    <Icon key={5}>star_outline</Icon>,
  ],
  3.5: [
    <Icon key={1}>star</Icon>,
    <Icon key={2}>star</Icon>,
    <Icon key={3}>star</Icon>,
    <Icon key={4}>star_half</Icon>,
    <Icon key={5}>star_outline</Icon>,
  ],
  4: [
    <Icon key={1}>star</Icon>,
    <Icon key={2}>star</Icon>,
    <Icon key={3}>star</Icon>,
    <Icon key={4}>star</Icon>,
    <Icon key={5}>star_outline</Icon>,
  ],
  4.5: [
    <Icon key={1}>star</Icon>,
    <Icon key={2}>star</Icon>,
    <Icon key={3}>star</Icon>,
    <Icon key={4}>star</Icon>,
    <Icon key={5}>star_half</Icon>,
  ],
  5: [
    <Icon key={1}>star</Icon>,
    <Icon key={2}>star</Icon>,
    <Icon key={3}>star</Icon>,
    <Icon key={4}>star</Icon>,
    <Icon key={5}>star</Icon>,
  ],
};


const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-indicatorSpan': {
    maxWidth: 40,
    width: '100%',
    backgroundColor: '#635ee7',
  },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: 'none',
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
      backgroundColor: 'rgb(162 146 53 / 42%)' ,
    color: 'rgba(255, 255, 255, 0.7)',
    '&.Mui-selected': {
      color: '#fff',
      // fontWeight: theme.typography.fontWeightRegular,
      // fontSize: theme.typography.pxToRem(15),
      // backgroundColor: 'rgba(100, 95, 228, 1)',
      fontWeight: 600,
      fontSize: "1.22rem",
      backgroundColor: 'rgb(255 196 73)' 
    },
    '&.Mui-focusVisible': {
      backgroundColor: 'rgba(100, 95, 228, 0.32)',
    },
  }),
);



const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderBottom: "darkgray solid",
  '&:not(:last-child)': {
    // borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharp sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
}));

function NotificationBar() {
  const [currentData,updateContext] = useCurrentUser();
  const { currentUser,agencyData, myAgencies,myAgencyJobSeekerInformation,myJobSeekerInformation, jobs } = currentData;
  const [expanded, setExpanded] = React.useState(false);
  const [warningExpanded, setWarningExpanded] = React.useState(false);
  const [expanded2, setExpanded2] = React.useState(false);
  const [loadingSave, setLoadingSave] = React.useState(false);
  const [loadingBuildJobSeeker, setLoadingBuildJobSeeker] = React.useState(false);
  const [userInformation, setUserInformation] = React.useState(null);
  const [user, setUser] = React.useState(null);
  const [profileSectionIndex, setProfileSectionIndex] = React.useState(0);
  const [forceRerender, setForceRerender] = React.useState(false);
  const [warnings, setWarnings] = React.useState([]);
  const [dropzoneFiles, setDropzoneFiles] = React.useState([]);
  const [openedDialog , setOpenedDialog ] = React.useState("warningList");
  const [moreText, setMoreText] = React.useState({content:"",title:""});
  const [newWorkExperianceData , setNewWorkExperianceData ] = React.useState({
            description :  "",
            expectingSalaryPerHour: null,
            expectingSalaryPerMonth: null, 
            isAvailable: true,
            jobId: null, 
            workExperianceInMonth:null,
            skillsOrSubjects:[],
  });
  // user
  // {id: DataTypes.DOUBLE,
  // userFrontImage: DataTypes.TEXT,
  // userEmail: DataTypes.TEXT,
  // googleEmail: DataTypes.TEXT,
  // facebookId: DataTypes.TEXT,
  // userStatus: DataTypes.DOUBLE,

  // currentEducationLevel:DataTypes.DOUBLE ,
  // informationApproved:DataTypes.BOOLEAN ,
  // informationApprovedDate:DataTypes.TEXT ,
  // TotoringEducationLevelStart:DataTypes.DOUBLE ,
  // TotoringEducationLevelEnd:DataTypes.DOUBLE ,

  // isInstructor :DataTypes.BOOLEAN,
  // status :DataTypes.TEXT,
  // registeredDate :DataTypes.TEXT,
 
  useEffect(() => {
    // console.log("currentUser", currentUser);
    // console.log(" myJobSeekerInformation", myJobSeekerInformation);
  
    if (currentUser.user) {
      setUser(currentUser.user);
      setUserInformation(currentUser.userInformation);
    }
  }, [currentUser]);

  useEffect(() => {
      countWarnings()
  }, [currentUser,agencyData, myAgencies,myAgencyJobSeekerInformation,myJobSeekerInformation, jobs]);
    
  useEffect(() => {
    // console.log("myAgencies", myAgencies.length);
    setForceRerender(!forceRerender)
  }, [myAgencies]);

  useEffect(() => {
    // tab=2 
    if (location.search) {
      let locationSearch = queryString.parse(location.search);
      if (locationSearch.tab) { 
        setProfileSectionIndex(locationSearch.tab);
      }
    }

  }, [location.search]);
 
  const handleExpanded = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
   
  };
 
  const navigate = useNavigate();
  const goToPage = () => {
    navigate("/pages/SendTutorRequest");
  };

  const postDocuments = async (documentName, relativePath, data,additionalData) => {
    let responseD = { success: false, message: "", record: null };
    let documentsResponse = { success: false };
    // setLoading(true);

    ////////// UPDATED/////////////
    documentsResponse = await HttpService.postService(relativePath, data, additionalData||{}, "");
    // console.log(documentName, " data: ", documentsResponse   );
    // setLoading(false);

    if (documentsResponse.data) {
      responseD.record = documentsResponse.data.record;
      responseD.message = documentsResponse.data.message;
    }
    if (documentsResponse.success) {
      responseD.success = true;
      let record = responseD.record;

      //write codtions down here//
    }
    else{
     responseD.record = documentsResponse.data;  
    }

    // console.log(documentName, " responseD: ", responseD);
    // setForceRerender(!forceRerender);
    return responseD;
    ////////// UPDATED/////////////
  };

  
  const getDocumentsAll = async (documentName, relativePath, additionalData) => {
    let responseD = { success: false, message: "", record: null };
    let documentsResponse = { success: false };
    ////////// UPDATED/////////////
    documentsResponse = await HttpService.getService(
      relativePath,
      additionalData ? additionalData : {},
      ""
    );
    if (documentsResponse.data) {
      responseD.record = documentsResponse.data.record;
      responseD.message = documentsResponse.data.message;
    }

    if (documentsResponse.success) {
      responseD.success = true;
    }
    setForceRerender(!forceRerender);
    return responseD;
  }; 
  
  

  const handleNewWorkExperianceData = async (field,value) => {
      try {
        let data=newWorkExperianceData
        if(field&&value){
          data[field]=value
          setNewWorkExperianceData(data)
          setForceRerender(!forceRerender)
        } 
      } catch (error) {
        
      }

  }
 
  const addNewWorkExperiance = async () => {
    // console.log("data", dataD,parentData, dataParentName,fieldType);
// return
    try { 
      let data=newWorkExperianceData
      let relativePath = "/api/employmentjobseekerworkexperiance/new";
      let refreshVariable = "myJobSeekerInformation"
      if(data){ 
       data.jobSeekerId = myJobSeekerInformation.id;

       if(data.skillsOrSubjects){ 
        let skils=""
          if(data.skillsOrSubjects.length){ 
            data.skillsOrSubjects.forEach(element => {
              skils=skils+(skils?",":"")+element
            });
          } 
            data.skillsOrSubjects=skils
      }
    }

      if (data.jobSeekerId) {
        if(data){ 
        // setLoadingSave(true);
        let changeUserProfileRes = await postDocuments("changeUserInfo", relativePath, data);
        //  toast.update(toastId, { render: changeUserProfileRes.message, type: changeUserProfileRes.success?"success":"warn", isLoading: false , autoClose: 5000});
        // setLoadingSave(false);

        if (changeUserProfileRes.success) {
          setExpanded(false)
          setOpenedDialog("")
          updateContext(refreshVariable)
        }
       }
      }
    } catch (error) {}
  };
 
  const truncateString=(str, num, lableName) =>{
    let res=str
    try {
      if (str) {
        if (str.length > num) { 
        let btn= <Button variant="text" onClick={()=>{setMoreText({content:str,title:lableName}); setOpenedDialog("moreText"); setForceRerender(!forceRerender) }} >{"View more"}</Button>
        let txt=str.slice(0, num) + "..."
        res= <>{txt} {btn}</>;
        }  
    } 
  }catch (error) {
      // console.log("errrr",error)
    }
    return res
  }
  
  const truncateStringDefualt=(str, num, lableName) =>{
    let res=str
    try {
      if (str) {
        if (str.length > num) { 
        let txt=str.slice(0, num) + "..."
        res= <>{txt} </>;
        }  
    } 
  }catch (error) {
      // console.log("errrr",error)
    }
    return res
  }
     
  const didIJoinedCurrentAgency = () => {
    let res = false;
      // console.log("agencyData&&myAgencies",agencyData,myAgencies)
    if (agencyData && myAgencies) {
      if (myAgencies.length) {
        myAgencies.forEach((element) => {
          if (agencyData.id == element.id) {
            res = true;
          }
        });
      }
    }

    return res;
  };
  
  const uploadFiles = async (filesD,filePathD) => {
    // console.log("uploadFiles",filesD,filePathD);
    let res=[]
    let dropzoneTempImages1=filesD||dropzoneFiles; 
    try {
      let filePath="./public/files/users/user_25/new-folder3" 
      const formData = new FormData();
      _.filter(dropzoneTempImages1, file => {
          // formData.append("folderPath", uploadingFolderPath);
          formData.append("folderPath", filePath);
          formData.append("file", file);
          return file;
      });

     let getHeaderAndURLRes=await HttpService.getHeaderAndURL()  
     let baseUrl=getHeaderAndURLRes.baseUrl
     let additionalData={
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        // onUploadProgress: progressEvent => {
        //   if(progressEvent){
        //     // console.log("progressEvent", Math.round((progressEvent.loaded * 100) / progressEvent.total))
        //       setuploadPercentage(parseInt(
        //       Math.round((progressEvent.loaded * 100) / progressEvent.total)
        //         ))
        //         }
        // }
      }
  

      let uploadFileRes = await postDocuments("uploadFile", "/api/file/photos", formData,additionalData);
      if(uploadFileRes){
        if(uploadFileRes.record){
        if(uploadFileRes.record.length){
          uploadFileRes.record.forEach(imgFile => {
                // var destination=imgFile.destination.replace('./public',"");
                var destination=imgFile.destination.replace('./public',"/public");
                var filename="/"+imgFile.filename 
                var pathN=baseUrl+destination+filename; 
                var imgpath=pathN
                res.push(imgpath) 
              }); 
    }}}
      // console.log("uploadFileRes  ",uploadFileRes);
      // console.log("uploadFileRes res",res);
        // await axios.post(baseUrl+"/api/file/photos", formData,{additionalData})
        // .then(function (response) {
        //   let imgs=[]  
        //   // console.log("file  dropzoneval, uploaded",dropzoneTempImages1,response.data);
        //   response.data.forEach(imgFile => {
        //     var destination=imgFile.destination.replace('./public',"");
        //     var filename="/"+imgFile.filename 
        //     var pathN=baseUrl+destination+filename; 
        //     var imgpath=pathN
        //     imgs.push(imgpath) 
        //   });
        //   // props.uplodedImages(imgs,props.uploadingImageType);
        //     // setuploadedFiles([...uploadedFiles, ...imageFiles])  
        // })
        // .catch(function (error) { 
        // });  
      
    } catch (err) { 
      // console.log("uploadFiles err",err);

    }
    return res
  }

  const countWarnings =async ()=>{
    let warningList=[]
    let isUserLoggidIn = await UseAuth.verifyUser()
    try {
      if(isUserLoggidIn){
      if(!myJobSeekerInformation){
        
        warningList.push({
          title:"Build Profile Information",
          message:"To start finding job , you must build profile information about your self.",
          action:"Profile page",
          link:"/profile"})
 
      }
      else{
        if(agencyData){
          if((!didIJoinedCurrentAgency())){
           
            warningList.push({
              title: "You did not join '"+ agencyData.brandName+"' yet! ",
              message:"To start working with "+ agencyData.brandName+ ", you must accept the privacy policy, terms and conditions and follow the instructions.",
              action:"Join ",
              link:"/join-agency"})
          }
          else{ 
            if((myAgencyJobSeekerInformation?(myAgencyJobSeekerInformation.isActive?false:true):true)){

              warningList.push({
                title:"Your "+(agencyData.brandName||agencyData.fullName)+" membership is inactive!" ,
                message:"Dear "+ myJobSeekerInformation.firstName+ ", You have joined an  "+(agencyData.brandName||agencyData.fullName)+", but your account has not yet been activated due to payment or certain settings.",
                action:"view",
                link:"/join-agency"})
              }
          }
        }
        if(myJobSeekerInformation){
          if((myJobSeekerInformation.workExperiances?(myJobSeekerInformation.workExperiances.length?((myJobSeekerInformation.workExperiances.length>0)?false:true):true):true)){
          
            warningList.push({
              title: "No professions have been added yet!",
              message:"Dear "+ myJobSeekerInformation.firstName+ ", you have not added any profession or work experience! Please fill in your information to increase your chances of being hired ",
              action:"Add",
              link:"/profile?tab=1"})
          
           
          }
        }
        else{ 
        }
      }
    }

      setWarnings(warningList)
      setForceRerender(!forceRerender)
 
    } catch (error) {
      
    } 
  }
 

  const warningsListComp =()=>{ 

    let res=<></>
    let res2=<></>
    try {
      res= <>
          {(warnings?((warnings.length>0)?true:false):false)&&<Grid container item xs={12} justifyContent="center" mx="auto">
          {warnings.map((warningComp)=><>
          {<>  
            <div style={ {marginBottom:"10px"}}>
        <Accordion expanded={expanded === warningComp.title} 
          onChange={handleExpanded(warningComp.title)}
          >
            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header"
                  expandIcon={<ExpandMoreOutlined/>}
              > 
                  <Grid container   >
                  
                      <Grid item xs={9} lg={10}  >  
                          <MKTypography
                              display="block" variant="subtitle2"  color="warning"    fontWeight="bold">
                         <WarningAmber/> 
                      <small>     {truncateString(warningComp.title,65)}</small>
                            </MKTypography>
                      </Grid> 
                      <Grid item xs={2} lg={2}  >
                         
                        {/* <Stack direction="row" spacing={2}> */}

                        <MKButton variant="outlined" size="small" color="success"   onClick={()=>{
                           setExpanded("");
                           setOpenedDialog(""); 
                          navigate(warningComp.link)}}  
                    sx={{ boxShadow: "none" }}>
                  {warningComp.action}
                  </MKButton>


                        {/* </Stack> */}
                    </Grid> 
                    
                     
                  </Grid>
                {/* </Container> */}
              {/* </MKBox> */}
            </AccordionSummary>
            <AccordionDetails>
              <MKBox ml={1}>
                  <MKTypography variant="h6"  color="text" >
                  message:
                  </MKTypography> 
                  
                 <MKTypography variant="body2" 
                  // fontWeight="light"
                   color="text" >
                <small>
                    {warningComp.message}
                  </small>  </MKTypography> 

                  <MKButton variant="text" size="small" color="success"    onClick={()=>{
                     setExpanded("");
                    setOpenedDialog(""); 
                    navigate(warningComp.link)}}   
                    sx={{ boxShadow: "none" }}>
                  {warningComp.action}
                  </MKButton>

                  {/* <Button
                    size="small" 
                      onClick={() => { 
                      }}
                        
                    > 
                    {warningComp.action}
                    </Button> */}
                </MKBox>
            </AccordionDetails>
          </Accordion></div>
       
        </>}
          </>)
          }
          </Grid>
          }
       </>
 
    } catch (error) {
      console.log("error",error)
    }
     
    return res
  }

  function WarningsComp(){
    let res=<></>
 
      
    res=<>
     {/* <Grid item xs={12} md={8} sx={{ ml: 2 }}>
        <MKTypography variant="h6" color="warning" 
          onClick={() => {
            setWarningExpanded(!warningExpanded); 
          }} >
          {"2 Warnings "}
          <Warning/>
        </MKTypography>
      </Grid> */}
      <Card
        sx={{
          my: 0,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
          overflow: "hidden",
        }}
      >
   {/* {(warningExpanded)&&<>
      {warningsListComp()}
       </>} */}
         <Accordion  sx={{my:0, py:0}} 
          expanded={openedDialog=="warningList"}
          onChange={() => {
            setExpanded("");
            if(openedDialog!="warningList"){
             setOpenedDialog("warningList")
            }
            else{
             setOpenedDialog("")
            }
            // setWarningExpanded(!warningExpanded); 
          }}
        >
          <AccordionSummary  sx={{my:0, py:0}} 
            expandIcon={<ExpandMoreOutlined />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <MKTypography variant="h6"   sx={{px:1}} color="warning"  >
              {openedDialog=="warningList" ? "Hide Warning" : warnings.length+" Warning(s)"}
            
            </MKTypography>  <WarningAmber   color="warning" /> 
          </AccordionSummary>
        </Accordion> 
    </Card>
    
    </>


    return res

  }

  return (
    <>
      
      {/* <Grid container item xs={12}  justifyContent="center"> */}
      
     {(warnings?((warnings.length>0)?true:false):false)&&
      <WarningsComp/> }

      {forceRerender} 
      <Dialog open={openedDialog=="addWorkExperiance"} onClose={()=>{setOpenedDialog("")}}>
        <DialogTitle>Add Profession and Work Experiance</DialogTitle>
        <DialogContent style={{paddingTop:"15px"}}>
          {/* <DialogContentText>
            To subscribe to this website, please enter your email address here. We
            will send updates occasionally.
          </DialogContentText> */}

           {(jobs)&&<Autocomplete fullWidth 
                // sx={{ width: 400 }}
                onChange={(e, val) => {
                    if(val){ 
                      // console.log("val",val);
                      //  value=val;
                      handleNewWorkExperianceData("jobId",val.id)
                      // console.log("value",value);
                      } 
                }}
                options={jobs}
                // defaultValue={options?(optionsSelectedField?value[optionsSelectedLable]:value):""}
                getOptionLabel={(option) =>  option.title}
                renderInput={(params) => <MKInput {...params} label="Profession or Job" variant="outlined" />}
              />}

          <TextField
            autoFocus
            margin="dense"
            id="expectingSalaryPerMonth"
            defaultValue={newWorkExperianceData.expectingSalaryPerMonth}
            label="Expecting Salary (Per Month)"
            type="number"
            onChange={(e) => { 
                handleNewWorkExperianceData("expectingSalaryPerMonth",e.target.value)
                } 
              } 
            fullWidth
            variant="outlined"
          />
          <TextField
            autoFocus
            margin="dense"
            id="workExperianceInMonth"
            defaultValue={newWorkExperianceData.workExperianceInMonth}
            onChange={(e) => { 
                handleNewWorkExperianceData("workExperianceInMonth",e.target.value)
                } 
              } 
            label="Work Experiance (In Month)"
            type="number"
            fullWidth
            variant="outlined"
          />

          
      <Autocomplete
        multiple
        id="tags-filled"
        options={[]}
        // defaultValue={[top100Films[13].title]}
            // defaultValue={newWorkExperianceData.skillsOrSubjects}
        onChange={(e,data)=>{
          handleNewWorkExperianceData("skillsOrSubjects",data)
        }}
        freeSolo 
        renderTags={(value, getTagProps) =>  
           value.map((option, index) => 
            (
            <Chip variant="outlined" label={option} {...getTagProps({ index })} />
          ) )  
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="filled"
            label="Skills Or Subjects"
            placeholder="Add Skill"
          />
        )}
      />
 

          <TextField
            autoFocus
            margin="dense"
            multiline
            rows={4}
            id="Describe"
            defaultValue={newWorkExperianceData.description}
            onChange={(e) => { 
                handleNewWorkExperianceData("description",e.target.value)
                } 
              } 
            label="More Describtion (about your skills) "
            // type="number"
            fullWidth
            variant="outlined"
          />
          {/* newWorkExperianceData */}
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={()=>{setOpenedDialog("")}}>Cancel</Button> */}
          <MKButton
                        color="success"
                        // variant="text"
                        // size="small"
                        disabled={!(newWorkExperianceData.jobId)} onClick={()=>{addNewWorkExperiance() }}
                      >
                       <strong> Save </strong>
                      </MKButton>
        </DialogActions>
      </Dialog>

      <Dialog open={openedDialog=="moreText"} onClose={()=>{setOpenedDialog("")}}>
        {moreText&&<>
       {moreText.title&& <DialogTitle>{moreText.title}</DialogTitle>}
       {moreText.content&& <DialogContent>
          <DialogContentText>
           {moreText.content}
          </DialogContentText> 
        </DialogContent>}</>}
        <DialogActions>
          <Button onClick={()=>{setOpenedDialog("")}}>close</Button> 
        </DialogActions>
      </Dialog>



     {(warnings?((warnings.length>0)?true:false):false)&&
      <Dialog open={openedDialog=="warningList"} onClose={()=>{
        setExpanded("");
        setOpenedDialog("")}}>
        <DialogTitle> 
          
        <MKTypography variant="subtitle2"     fontWeight="bold">
                { "Please read and complete the below!"}
                </MKTypography>
          
          </DialogTitle> 
         <DialogContent>
          <DialogContentText>
           
           {warningsListComp()}
          </DialogContentText> 
        </DialogContent> 
        <DialogActions>
          <Button onClick={()=>{
            setExpanded("");
            setOpenedDialog("")}}>Skip</Button> 
        </DialogActions>
      </Dialog>}

    </>
  );
}

export default NotificationBar;
