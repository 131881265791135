/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
 

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import React, { useState ,useEffect} from "react";

// Rental page components
import FaqCollapse from "pages/LandingPages/Rental/components/FaqCollapse";
import { useCurrentUser } from "Api-contexts/currentUserContext";

function Faq() { 
  const [collapse, setCollapse] = useState(false);
  const [currentData] = useCurrentUser();
  const {websitePagesContent } = currentData;
  const [FAQPage, setFAQPage] = React.useState(null);
  useEffect(() => {
    // console.log("websitePagesContent",websitePagesContent) 
    if(websitePagesContent){
      if(websitePagesContent.pages){
        if(websitePagesContent.pages.FAQPage){
          if(websitePagesContent.pages.FAQPage.sections){ 
            setFAQPage(websitePagesContent.pages.FAQPage.sections)
          }
        }
      }
    }
  }, [websitePagesContent]);

  return (<> 
      {(FAQPage)&&<> 
      {(FAQPage.faq)&&<>
        {((FAQPage.faq.length?true:false))&& 
    <MKBox component="section" py={12}>
      <Container>
        <Grid container justifyContent="center">
          {(FAQPage.header)&&<Grid item xs={12} md={8}>
            <MKTypography variant="h2" align="center" fontWeight="bold" gutterBottom>
              Frequently Asked Questions
            </MKTypography>
            {(FAQPage.header.description)&&
            <MKBox mb={2}>
              <MKTypography variant="body2" align="center" color="text">
              { FAQPage.header.description}
              </MKTypography>
            </MKBox>}
          </Grid>}
         {(FAQPage.faq) &&<Grid item xs={12} md={10}>
           {FAQPage.faq.map((faq,i) => <FaqCollapse
              title={faq.question}
              open={collapse === i}
              onClick={() => (collapse === i ? setCollapse(false) : setCollapse(i))}
            >{faq.answer}
            </FaqCollapse>)}
           
            
         
          </Grid>}
        </Grid>
      </Container>
    </MKBox> }
        </>}
        </>} 
  </>);
}

export default Faq;
