/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";

// Material Kit 2 PRO React examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";

function Information(props) {
  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid container spacing={3}  >
          {(props.companyDescription)&&
          <Grid item xs={12} lg={6}>
            {/* <Grid container justifyContent="flex-start"> */}
             <Grid item xs={12} md={12}>
                {/* <MKBox mb={5}> */}
                  <DefaultInfoCard
                    // icon="public"
                    title={props.companyDescription.title}
                    description= {props.companyDescription.description}
                  />
                {/* </MKBox> */}
              {/* </Grid>    */}
            </Grid>
          </Grid>}
          {(props.card)&&<Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <CenteredBlogCard
              image={props.card.image}
              title={props.card.title}
              description={props.card.description}
              action={{
                type: "internal",
                route: "pages/search",
                color: "info",
                label: "Get Employees",
              }}
            />
          </Grid>}
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
