import { HttpService } from "../Api-Services/httpService";
import { createContext, useEffect, useState, useContext } from "react";
import { DataManagementService } from "../Api-Services/dataManagementService";
// import { SideBarRoutes } from 'sideBarRoutes';
import { UseAuth } from "Api-Services/useAuth";
import groupArray from "group-array";
import ReactGA from "react-ga4";


const CurrentUserContext = createContext();
//now we have a context object

function CurrentUserProvider(props) {
  //create the provider and its functionality
  const shape = {
    type: "mesfin", //films, planets, people
    id: 0, //id of film, or planet, or person
    data: {}, //the actual data object
  };
  const currentWebsiteShape = null;
  const [currentWebsite, setCurrentWebsite] = useState({
    website: null,
    websiteMasterFields: null,
  });
  const [currentUser, setCurrentUser] = useState({});
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [myWebsites, setMyWebsites] = useState(null);
  const [employmentTypes, setEmploymentTypes] = useState([]);
  const [employmentJobLocationTypes, setEmploymentJobLocationTypes] = useState([]);
  const [employmentSalaryDealTypes, setEmploymentSalaryDealTypes] = useState([]);
  const [educationLevels, setEducationLevels] = useState([]);
  const [websiteTestDomainSuffixs, setWebsiteTestDomainSuffixs] = useState([]);
  const [websiteFiles, setWebsiteFiles] = useState({});
  const [userFiles, setUserFiles] = useState({});
  const [jobs, setJobs] = useState([]); //
  const [myAgencies, setMyAgencies] = useState([]);
  const [myJobApplications, setMyJobApplications] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState(null);
  const [employmentAgencyPaymentAccounts, setEmploymentAgencyPaymentAccounts] = useState([]);
  const [employmentAgencyJobVacancies, setEmploymentAgencyJobVacancies] = useState([]);
  const [newJobSeekers, setNewJobSeekers] = useState([]);
  const [myJobSeekerInformation, setMyJobSeekerInformation] = useState(null);
  const [myAgencyJobSeekerInformation, setMyAgencyJobSeekerInformation] = useState(null);
  const [isAgentWebsite, setIsAgentWebsite] = useState(false);
  const [agencyData, setAgencyData] = useState(null);
  const [websitePagesContent, setWebsitePagesContent] = useState(null);

  const [adminUIComponent, setAdminUIComponent] = useState({
    sidebarUiComponents: [],
    customUiComponents: [],
  });
  const [forceRerender, setForceRerender] = useState(true);
  const [officeUiComponents, setOfficeUiComponents] = useState([]);
  const websitePagesContentD= {
    pages:{
      joinUsPage:{
        sections:{
          instruction:{
            title:"instructions",
            body:`<p>
            ONLINE REGISTRATION INSTRUCTIONS
            Note: The majority of returning students are eligible to use online registration. You are eligible unless you fall within
            any of the following categories:
            1) You are a new job seeker.
            2) You are a returning seeker who must reapply because of a lapse in continuous enrollment.
            3) You have earned less than 12 hours of college credit.
            4) You have a 1005–General College major AND have 30 or more hours of college credit. You may register online
            once you declare a specific major. Contact the Admissions Office to declare a specific major.
            5) You are non-degree seeking.
            6) You need to enroll in college preparatory/remedial courses.
            7) Your cumulative GPA is less than 2.00.
            8) You are a dual enrolled or early admission student.
            9) You are a clock-hour student (automotive, cosmetology, law enforcement, CNA, etc.)
            10) You have a hold on your record.
            11) You wish to take advantage of a state employee fee waiver.</p>
            `
          },
          card:{
            cardImage:"https://images.unsplash.com/photo-1539803442075-48618f39bb3d?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;ixlib=rb-1.2.1&amp;auto=format&amp;fit=crop&amp;w=963&amp;q=80"
            }
          } 
      },
        homePage:{
            sections:{
                banner:{
                    backgroundImage:"",
                    title:"",
                    description:" The time is now for it be okay to be great. People in this world shun people for being nice."
                }
            }
        },
        contactUsPage:{
            sections:{
                card:{
                    image:"https://images.unsplash.com/photo-1539803442075-48618f39bb3d?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;ixlib=rb-1.2.1&amp;auto=format&amp;fit=crop&amp;w=963&amp;q=80",
                    title:"",
                    description:""
                },
                formSection:{
                    note:`For further questions,, please contact using our contact form.`
                }
            }},
        aboutUsPage:{
            sections:{
                banner:{
                    backgroundImage:"",
                    title:"",
                    description:"We are constantly trying to express ourselves and actualize our dreams. If you  have the opportunity to play this game"
                }, 
               jobs:[
                        {
                            title:"tutor",
                            description:"We get insulted by others, lose trust for those We get back freezes"
                        }] ,
                card:{
                     image :"https://images.unsplash.com/photo-1544717302-de2939b7ef71?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80",
                     description:"Website visitors today demand a frictionless user expericence — especially when using search. Because of the hight standards.",
                     title:"Get insights on Search",
                  
                },
                teams:[
                        {
                            image:"https://wfcsradio.com/wp-content/uploads/2019/08/team-member-01.jpg",
                            name:"Alec Thompson",
                            profession:"CEO / Co-Founder", 
                            description:"And I love you like Kanye loves Kanye. We need to restart the human foundation."
                        },
                        {
                            image:"https://wfcsradio.com/wp-content/uploads/2019/08/team-member-01.jpg",
                            name:"Alec Thompson",
                            profession:"CEO / Co-Founder", 
                            description:"And I love you like Kanye loves Kanye. We need to restart the human foundation."
                        },
                        {
                            image:"https://wfcsradio.com/wp-content/uploads/2019/08/team-member-01.jpg",
                            name:"Alec Thompson",
                            profession:"CEO / Co-Founder", 
                            description:"And I love you like Kanye loves Kanye. We need to restart the human foundation."
                        }],

            }},
        FAQPage:{
            sections:{
                header:{
                    title:"",
                    description:""
                },
                faq:[
                    {
                        question:"How do I order a tutor?",
                        answer:`  We&apos;re not always in the position that we want to be at. We&apos;re constantly
                        growing. We&apos;re constantly making mistakes. We&apos;re constantly trying to
                        express ourselves and actualize our dreams. If you have the opportunity to play this
                        game of life you need to appreciate every moment. A lot of people don&apos;t
                        appreciate the moment until it&apos;s passed.`
                    },
                    {
                        question:"How can i make the payment?",
                        answer:`   It really matters and then like it really doesn&apos;t matter. What matters is the
                        people who are sparked by it. And the people who are like offended by it, it
                        doesn&apos;t matter. Because it&apos;s about motivating the doers. Because I&apos;m
                        here to follow my dreams and inspire other people to follow their dreams, too.
                        We&apos;re not always in the position that we want to be at. We&apos;re constantly
                        growing. We&apos;re constantly making mistakes. We&apos;re constantly trying to
                        express ourselves and actualize our dreams. If you have the opportunity to play this
                        game of life you need to appreciate every moment. A lot of people don&apos;t
                        appreciate the moment until it&apos;s passed.`
                    },
                    {
                        question:"How much time does it take to get orderd tutor?",
                        answer:`The time is now for it to be okay to be great. People in this world shun people for
                        being great. For being a bright color. For standing out. But the time is now to be
                        okay to be the greatest you. Would you believe in what you believe in, if you were the
                        only one who believed it? If everything I did failed - which it doesn&apos;t, it
                        actually succeeds - just the fact that I&apos;m willing to fail is an inspiration.
                        People are so scared to lose that they don&apos;t even try. Like, one thing people
                        can&apos;t say is that I&apos;m not trying, and I&apos;m not trying my hardest, and
                        I&apos;m not trying to do the best way I know how.`
                    },
                  ]
            }
        },
        footerPage:{
            sections:{
                socialMedias:[
                { 
                  icon:"facebook" , 
                  link: "https://www.facebook.com/BFamilyPromotion" }, 
                {
                  icon: "YouTube",
                  link: "https://www.youtube.com/channel/UCBdLexsyb4xyeA7XPAOyqEw",
                },
              ]
            }
        },
        testimonialsPage:{
            sections:{ 
                header:{
                    title:"Some thoughts from our clients",
                    description:` If you are selected for them you&apos;ll also get three tickets, opportunity to
                    access Investor Office Hours and Mentor Hours and much more all for free.
                 `
                },
                testimonials:[
                        {
                            image:"",
                            name:"Biniam Asefa",
                            role:"Tutor", 
                            review:"The connections you make at Web Summit are unparalleled, we met users all over the world."
          
                        },
                        {
                            image:"",
                            name:"Kibrom Ayele",
                            role:"trainee parent", 
                            review:"The connections you make at Web Summit are unparalleled, we met users all over the world."
          
                        },
                        {
                            image:"",
                            name:"Meron Kidane",
                            role:"trainee", 
                            review:"The connections you make at Web Summit are unparalleled, we met users all over the world."
           
                        },
                      ],

            }}
    }
}
  useEffect(() => {
    // setWebsitePagesContent(websitePagesContentD)
    getStarted();
  }, []);

  const getStarted = async () => {
    // getWebsiteContentTypes()
    try {
      let agencyDataD=null
      let GAMeasurementId="G-9WH6WP5G6X"//Baally jobs GA ID
      let isUserLoggedInD = await UseAuth.verifyUser();
      let isWebsiteLoggedIn = await UseAuth.verifyWebsite(); 
      setIsUserLoggedIn(isUserLoggedInD);
      if (isUserLoggedInD) {
        getMyJobSeekerInformation();
      }
      getEducationLevels();
      getEmploymentTypes();
      getEmploymentSalaryDealTypes();
      getEmploymentJobLocationTypes();
      getJobs();
      // if(false){
      if(1){
       let agencyDataD2 =await getAgentDataForWebsite_byWebsiteDomain()
        //  agencyDataD2=await getAgentDataForWebsite_byId(agentId)
       
       if(agencyDataD2){
        if(agencyDataD2.id){
          agencyDataD=agencyDataD2
          if(agencyDataD.googleAnalyticsMeasurementId){
            if((agencyDataD.googleAnalyticsMeasurementId!=null)&&(agencyDataD.googleAnalyticsMeasurementId=="")){
              GAMeasurementId=agencyDataD.googleAnalyticsMeasurementId
            }
          }
           getPaymentMethods()
           getNewJobSeekers(agencyDataD.id)
        }
       }
      }

      ////initialize google Analytics 
      if(GAMeasurementId){ 
           ReactGA.initialize(GAMeasurementId);
      }

      if (agencyDataD&&isUserLoggedInD) {
        getMyJobSeekerInformation(agencyDataD);
      }

      if (isUserLoggedInD) {
        getCurrentUser();
        // console.log("agencyDataD",agencyDataD)
        getMyAgencies();
        getMyJobApplications();
        // getMyWebsites()
        getUserFiles();
      }

      
    } catch (error) {
      
    }
  };


  const assignInitializeDatas = (initData) => { 
    try {
    if(initData){
      if(initData.favIcon){
      // console.log(initData,"init assign")
      // websiteFavIcon
      let hd = document.getElementById('htmlheadtag1');  
      let  itm = document.createElement('link');
      itm.id = 'whatever';
      itm.rel = 'shortcut icon';
      itm.type = 'image/png';
      itm.href = initData.favIcon
      hd.appendChild(itm);
      // console.log("head tags",hd)
      }
    }
      
    } catch (error) {
      // console.log("head tags",error)
      
    }
  }

  const getCurrentUser = () => {
    let mydetails;
    getDocumentsAll("mydetails", "/api/auth/mydetails").then((mydetailsRes) => {
      if (mydetailsRes.success) {
        mydetails = mydetailsRes.record;
        setCurrentUser(mydetails);
      }
      // console.log( mydetails, "getCurrentUser  CurrentUserContext")
    });
  };

  
  const getEmploymentAgencyPaymentAccounts = (id) => {
    let mydetails;
    let agentId=id
    if(!id){
      if(agencyData){
        if(agencyData.id){
         agentId=agencyData.id
        }
      }
    }  
    let relativePath = "/api/employmentagencypaymentaccount/getbyfield";
    // agentId
    
    try {
        
    if(agentId){
    let data =  { employmentAgencyId:agentId}
    postDocuments("contactus", relativePath, data, {}).then((mydetailsRes) => {
      if (mydetailsRes.success) {
        mydetails = mydetailsRes.record;
        if(mydetails.length){ 
        setEmploymentAgencyPaymentAccounts(mydetails);  
        } 
      }
      else{
        setEmploymentAgencyPaymentAccounts([]); 
      }
      // console.log(mydetails ,jobSeekersD , "getNewJobSeekers  ")
      
    }
    );
  }
  } catch (error) {
      // console.log(error , "error  ")
        
      }


  };

  const getEmploymentAgencyJobVacancies = (id) => {
    let mydetails;
    let agentId=id
    if(!id){
      if(agencyData){
        if(agencyData.id){
         agentId=agencyData.id
        }
      }
    }  
    let relativePath = "/api/employmentjobvacancy/getbyfield";
    // agentId
    
    try {
        
    if(agentId){
    let data =  { employmentAgencyId:agentId}
    postDocuments("contactus", relativePath, data, {}).then((mydetailsRes) => {
      if (mydetailsRes.success) {
        mydetails = mydetailsRes.record;
        if(mydetails.length){  
          mydetails.reverse();
          setEmploymentAgencyJobVacancies(mydetails);  
        } 
      }
      else{
        setEmploymentAgencyJobVacancies([]); 
      }
      // console.log(mydetails , "setEmploymentAgencyJobVacancies  ")
      
    }
    );
  }
  } catch (error) {
      // console.log(error , "error  ")
        
      }


  };

  
  
  const getNewJobSeekers = (id) => {
    let mydetails;
    let agentId=id
    if(!id){
      if(agencyData){
      agentId=agencyData.id
      }
    } 
    let jobSeekersD=[]
    let relativePath = "/api/employmentjobseeker/search";
    // agentId
    let data = {
      jobSeeker: {},
      job: {},//jobId:223
      agencyJobSeeker: { employmentAgencyId:agentId},
    }; 
    postDocuments("contactus", relativePath, data, {}).then((mydetailsRes) => {
      try {
        
      if (mydetailsRes.success) {
        mydetails = mydetailsRes.record;

        if(mydetails.length){
         mydetails = groupArray(mydetails, 'id');
         mydetails = Object.entries(mydetails); 
          if(mydetails.length){
            mydetails.forEach(element => {
              let keyValue=element
              let allValue=[]
              let value={}
              if(keyValue.length){
                allValue=keyValue[1]
              if(allValue.length){
                  value=allValue[0]
                  // console.log( allValue[0] ,keyValue[0])
                // value.allWorkExperiances=allValue
                if(value){
                  jobSeekersD.push(value)
                }
                }
              }
         });
        }
        jobSeekersD.reverse();
        setNewJobSeekers(jobSeekersD);  
        } 
      }
      else{
        setNewJobSeekers([]); 
      }
      // console.log( jobSeekersD , "getNewJobSeekers  ")
      } catch (error) {
      // console.log(error , "error  ")
        
      }
    });
  };

  
  const getAgentDataForWebsite_byId =async (agentId) => {
    let mydetails=null;
    let agencyWebsiteContentsData
   await getDocumentsAll(
      "myjobseekerinformation",
      "/api/employmentagency/getagentdataforwebsite/"+agentId
    ).then((mydetailsRes) => {
      if (mydetailsRes.success) {
        mydetails = mydetailsRes.record;
        setAgencyData(mydetails);
        assignInitializeDatas(mydetails)

        if(mydetails.agencyWebsiteContents){
          agencyWebsiteContentsData=mydetails.agencyWebsiteContents
          agencyWebsiteContentsData=JSON.parse(agencyWebsiteContentsData)
          setWebsitePagesContent(agencyWebsiteContentsData)
        }
      }
      else{
        setAgencyData(null);
        setWebsitePagesContent(null);
      }
        //  console.log( mydetails, "getAgentDataForWebsite")
      // console.log( agencyWebsiteContentsData, "agency Website ContentsData   ")
    });
      return mydetails
  };

  const getAgentDataForWebsite_byWebsiteDomain = async() => {
    let mydetails;
    let agencyWebsiteContentsData 
    let originUrl=location.origin
    // let originUrl="https://salemhealthcare.baally.com"
    let isLocalHost=null
    if(originUrl){
      isLocalHost= (originUrl.indexOf("localhost")>-1)?true:false
    }
    let isMegenagnaDomain=originUrl.indexOf("megenagna") 
    
    if((isMegenagnaDomain)){
        setWebsitePagesContent(websitePagesContentD)
    } 
    if((isLocalHost)){
        originUrl="http://extremetutors.et"///
        // originUrl="https://salemhealthcare.baally.com"
        // setWebsitePagesContent(websitePagesContentD)
    } 
      //agent data
      // let agentId=1
      // let websiteDomain=""
      setIsAgentWebsite(true)

    if(originUrl){ 
      await postDocuments(
        "myjobseekerinformation",
        "/api/employmentagency/getagentdatabywebsitedoamin",{originUrl:originUrl}
      ).then((mydetailsRes) => {
        if (mydetailsRes.success) {
          mydetails = mydetailsRes.record;
          setAgencyData(mydetails);
          assignInitializeDatas(mydetails)
          if(mydetails.id){
          getEmploymentAgencyPaymentAccounts(mydetails.id)
          getEmploymentAgencyJobVacancies(mydetails.id)
          }
          if(mydetails.agencyWebsiteContents){
            agencyWebsiteContentsData=mydetails.agencyWebsiteContents
            agencyWebsiteContentsData=JSON.parse(agencyWebsiteContentsData)
            setWebsitePagesContent(agencyWebsiteContentsData)
          }
        }
        else{
          setAgencyData(null);
          setWebsitePagesContent(null);
        }
          //  console.log( mydetails, "getAgentDataForWebsite")
        // console.log( agencyWebsiteContentsData, "agency Website ContentsData   ")
      });
        return mydetails
      // then((mydetailsRes) => {
      //   if (mydetailsRes.success) {
      //     mydetails = mydetailsRes.record;
      //     setAgencyData(mydetails);
      //     if(mydetails.id){
      //     getEmploymentAgencyPaymentAccounts(mydetails.id)
      //     }
      //     if(mydetails.agencyWebsiteContents){
      //       agencyWebsiteContentsData=mydetails.agencyWebsiteContents
      //       agencyWebsiteContentsData=JSON.parse(agencyWebsiteContentsData)
      //       setWebsitePagesContent(agencyWebsiteContentsData)
      //     //  console.log( agencyWebsiteContentsData, "getAgentDataForWebsite")
      //     }
      //   }
      //   // console.log( agencyWebsiteContentsData, "agency Website ContentsData   ")
      // });

    } 

  };

  const getMyJobSeekerInformation = (agencyDataDDD) => {
    let mydetails;
    getDocumentsAll(
      "myjobseekerinformation",
      "/api/employmentjobseeker/myjobseekerinformation"
    ).then((mydetailsRes) => {
        setMyJobSeekerInformation(null);
      if (mydetailsRes.success) {
        mydetails = mydetailsRes.record;
        setMyJobSeekerInformation(mydetails);
        let agencyDataD= agencyDataDDD||agencyData
        if(agencyDataD){
          if(agencyDataD.id){
            // let dataaa = {jobseekerId:mydetails.id,employmentAgencyId:agencyDataD.id} 
            getMyAgencyJobSeekerInformation(mydetails,agencyDataD);
          }
        }
      }
      // console.log( mydetails, "getCurrentUser  CurrentUserContext")
    });
  };


  const getMyAgencyJobSeekerInformation = (jobseekerD,agencyDataD) => {

    let agencyDataDD= agencyDataD||agencyData
    let jobseekerDD= jobseekerD||myJobSeekerInformation
    
    let dataa = {jobseekerId:jobseekerDD.id,employmentAgencyId:agencyDataDD.id} 
    // console.log(" location.search", dataa);
    let res=null
    try {
      if (agencyDataDD&&jobseekerDD) {
          let relativePath = "/api/employmentagencyjobseeker/getbyfield" 
          postDocuments("employmentagencyjobseeker", relativePath, dataa).then((AgencyJobSeekerInformation)=>{
            if (AgencyJobSeekerInformation.success) {
              if (AgencyJobSeekerInformation.record) {
                if (AgencyJobSeekerInformation.record.length) { 
                    let agencyJobSeekerInformationD=AgencyJobSeekerInformation.record[0] 
                    if (agencyJobSeekerInformationD) {
                      res=agencyJobSeekerInformationD;
                    }  
                }
              }
            }
                    setMyAgencyJobSeekerInformation(res);

          })
      }
      // setLoading(false);
    } catch (error) {}
  };




  const getPaymentMethods = () => {
    let mydetails;
    getDocumentsAll(
      "paymentMethods",
      "/api/paymentmethod/all"
    ).then((mydetailsRes) => {
      if (mydetailsRes.success) {
        mydetails = mydetailsRes.record;
        setPaymentMethods(mydetails);
      }
      else{
        setPaymentMethods(null);
      }
      // console.log( mydetails, "getCurrentUser  CurrentUserContext")
    });
  };

  const getMyJobApplications = () => {
    let mydetails;
    getDocumentsAll(
      "myJobApplications",
      "/api/employmentjobseeker/myjobapplications"
    ).then((mydetailsRes) => {
      if (mydetailsRes.success) {
        mydetails = mydetailsRes.record;
        
        mydetails.reverse();
        setMyJobApplications(mydetails);
      }
      else{
        setMyJobApplications([]);
      }
      // console.log( mydetails, "getCurrentUser  CurrentUserContext")
    });
  };


  const getMyAgencies = () => {
    let mydetails;
    getDocumentsAll(
      "myjobseekerinformation",
      "/api/employmentjobseeker/agenciesijoined"
    ).then((mydetailsRes) => {
      if (mydetailsRes.success) {
        mydetails = mydetailsRes.record;
        
        mydetails.reverse();
        setMyAgencies(mydetails);
      }
      else{
        setMyAgencies([]);
      }
      // console.log( mydetails, "getCurrentUser  CurrentUserContext")
    });
  };

  const getEmploymentTypes = () => {
    let mydetails;
    getDocumentsAll("mydetails", "/api/employmenttype/all").then((mydetailsRes) => {
      if (mydetailsRes.success) {
        mydetails = mydetailsRes.record;
        setEmploymentTypes(mydetails);
      }
      else{
        setEmploymentTypes([]);
      }
      // console.log( mydetails, "setEmploymentTypes  setEmploymentTypes")
    });
  };

  const getEmploymentSalaryDealTypes = () => {
    let mydetails;
    getDocumentsAll("mydetails", "/api/employmentsalarydealtype/all").then((mydetailsRes) => {
      if (mydetailsRes.success) {
        mydetails = mydetailsRes.record;
        setEmploymentSalaryDealTypes(mydetails);
      }
      else{
        setEmploymentSalaryDealTypes([]);
      }
      // console.log( mydetails, "employmentSalaryDealTypes    ")
    });
  };
  
  const getEmploymentJobLocationTypes = () => {
    let mydetails;
    getDocumentsAll("mydetails", "/api/employmentJobLocationType/all").then((mydetailsRes) => {
      if (mydetailsRes.success) {
        mydetails = mydetailsRes.record;
        setEmploymentJobLocationTypes(mydetails);
      }
      else{
        setEmploymentJobLocationTypes([]);
      }
      // console.log( mydetails, "employmentSalaryDealTypes    ")
    });
  };

  const getEducationLevels = () => {
    let mydetails;
    getDocumentsAll("mydetails", "/api/educationLevel/all").then((mydetailsRes) => {
      if (mydetailsRes.success) {
        mydetails = mydetailsRes.record;
        setEducationLevels(mydetails);
      }
      else{
        setEducationLevels([]);
      }
      // console.log( mydetails, "getCurrentUser  CurrentUserContext")
    });
  };

  const getJobs = () => {
    getDocumentsAll("job", "/api/job/all").then((websiteUserTypesRes) => {
      //  console.log("currentloggedwebsiteRes",currentloggedwebsiteRes)
      if (websiteUserTypesRes.success) {
        if (websiteUserTypesRes.record) {
          setJobs(websiteUserTypesRes.record);
        }
      }
      else{
        setJobs([]);
      }
    });
  };

  const getUserFiles = () => {
    let fileRelativePath = "/api/file/allFoldersAndFiles_user";
    getDocumentsAll("getphotosalbum", fileRelativePath).then((resdata) => {
      if (resdata.success) {
        setUserFiles(resdata.record);
      } 
      else{
        setUserFiles({});
      }
      // console.log( mydetails, "getCurrentUser  CurrentUserContext")
    });
  };

  const getWebsiteFiles = () => {
    let fileRelativePath = "/api/file/allFoldersAndFiles";
    getDocumentsAll("getphotosalbum", fileRelativePath).then((resdata) => {
      if (resdata.success) {
        setWebsiteFiles(resdata.record);
      }
      else{
        setWebsiteFiles({});
      }
      // console.log( mydetails, "getCurrentUser  CurrentUserContext")
    });
  };

  const getDocumentsAll = async (documentName, relativePath, additionalData) => {
    let responseD = { success: false, message: "", record: null };
    let documentsResponse = { success: false };
    ////////// UPDATED/////////////
    documentsResponse = await HttpService.getService(
      relativePath,
      additionalData ? additionalData : {},
      ""
    );
    if (documentsResponse.data) {
      responseD.record = documentsResponse.data.record;
      responseD.message = documentsResponse.data.message;
    }
    if (documentsResponse.success) {
      responseD.success = true; 
      let record = responseD.record;
      if (documentName == "allOffices") {
        await setOfficesUIelements(record);
        DataManagementService.setLocalstorageDataSecured(record, "allOffices");
      }
    }
    setForceRerender(!forceRerender);
    return responseD;
    ////////// UPDATED/////////////
  };
  
  const postDocuments = async (documentName, relativePath, data) => {
    let responseD = { success: false, message: "", record: null };
    let documentsResponse = { success: false };
    // setLoading(true);

    ////////// UPDATED/////////////
    documentsResponse = await HttpService.postService(relativePath, data, {}, "");
   
    if (documentsResponse.data) {
      responseD.record = documentsResponse.data.record;
      responseD.message = documentsResponse.data.message;
    }
    if (documentsResponse.success) {
      responseD.success = true; 
    } 
    setForceRerender(!forceRerender);
    return responseD;
    ////////// UPDATED/////////////
  };


  async function updateCurrentUser(variableName) {
    if (variableName) {
      if (variableName == "currentUser") {
        getCurrentUser();
      }
      if (variableName == "myAgencyJobSeekerInformation") {
        getMyAgencyJobSeekerInformation();
      }
      if (variableName == "myAgencies") {
        getMyAgencies();
      }
      if (variableName == "myJobApplications") {
        getMyJobApplications();
      }
      if (variableName == "newJobSeekers") {
        getNewJobSeekers();
      }
      if (variableName == "paymentMethods") {
        getPaymentMethods();
      }
      if (variableName == "employmentAgencyPaymentAccounts") {
        getEmploymentAgencyPaymentAccounts();
      }
      if (variableName == "employmentAgencyJobVacancies") {
        getEmploymentAgencyJobVacancies();
      }
      if (variableName == "websiteFiles") {
        getWebsiteFiles();
      }
      if (variableName == "userFiles") {
        getUserFiles();
      }
      if (variableName == "employmentTypes") {
        getEmploymentTypes();
      }
      if (variableName == "employmentJobLocationTypes") {
        getEmploymentJobLocationTypes();
      }
      if (variableName == "employmentSalaryDealTypes") {
        getEmploymentSalaryDealTypes();
      }
      if (variableName == "jobs") {
        getJobs();
      }
      if (variableName == "myJobSeekerInformation") {
        getMyJobSeekerInformation();
      }
    } else {
      getStarted();
    }
    // await getDocumentsAll("allOffices","/api/officeUserRole/myoffices",{})
    // await getDocumentsAll("currentloggedwebsite","/api/auth/currentloggedwebsite")
  }

  return (
    <CurrentUserContext.Provider
      value={[
        {
          paymentMethods: paymentMethods,
          employmentAgencyPaymentAccounts: employmentAgencyPaymentAccounts,
          employmentAgencyJobVacancies: employmentAgencyJobVacancies,
          myAgencies: myAgencies,
          myJobApplications: myJobApplications,
          myAgencyJobSeekerInformation: myAgencyJobSeekerInformation,
          newJobSeekers: newJobSeekers,
          websitePagesContent: websitePagesContent,
          isAgentWebsite: isAgentWebsite,
          agencyData: agencyData,
          educationLevels: educationLevels,
          employmentSalaryDealTypes: employmentSalaryDealTypes,
          employmentTypes: employmentTypes,
          employmentJobLocationTypes: employmentJobLocationTypes,
          isUserLoggedIn: isUserLoggedIn,
          currentUser: currentUser,
          userFiles: userFiles,
          jobs: jobs,
          websiteFiles: websiteFiles,
          forceRerenderContext: forceRerender,
          myJobSeekerInformation: myJobSeekerInformation,
        },
        updateCurrentUser,
      ]}
      {...props}
    />
  );
}

function useCurrentUser() {
  //for pages that want to access the context object's value
  //custom hook use...
  try {
    const context = useContext(CurrentUserContext);
    // console.log('  inside the Provider',context);
    // if (!context) throw new Error('Not inside the Provider');
    return context; // [fav, updateFav]
  } catch (err) {}
}

export { useCurrentUser, CurrentUserProvider };
