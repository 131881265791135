/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import React, { useState, useEffect } from "react";
import { FreeMode, Navigation, Thumbs, EffectCube, EffectFlip, EffectCards, EffectCreative,EffectCoverflow, Pagination } from "swiper";
// Direct React component imports
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';

// import { makeStyles } from "@material-ui/core/styles"; 
// Styles must use direct files imports
// import 'swiper/swiper.scss'; // core Swiper
// import 'swiper/modules/effect-coverflow/effect-coverflow.scss'; // Navigation module
// import 'swiper/modules/navigation/navigation.scss'; // Navigation module
// import 'swiper/modules/pagination/pagination.scss'; // Pagination module

import './swiperStyles.css';

// import 'swiper/css/pagination';
import 'swiper/swiper.min.css'
import 'swiper/modules/pagination/pagination.min.css'
// import 'swiper/modules/thumbs/thumbs.scss'; // Pagination module
// import 'swiper/modules/free-mode/free-mode.scss'; // Pagination module
// import 'swiper/modules/effect-cube/effect-cube.scss'; // Pagination module 
// import 'swiper/modules/effect-flip/effect-flip.scss'; // Pagination module 
// import 'swiper/modules/effect-cards/effect-cards.scss'; // Pagination module 
// import 'swiper/modules/effect-creative/effect-creative.scss'; // Pagination module 

// import required modules 
// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";
import image from "assets/images/examples/blog2.jpg";

// Rental page sections
// import Search from "pages/LandingPages/Extreme/sections/Search";
import Places from "pages/LandingPages/Extreme/sections/Places";
import Testimonials from "pages/LandingPages/Extreme/sections/Testimonials";
import Faq from "pages/LandingPages/Extreme/sections/Faq";
import Contact from "pages/LandingPages/Extreme/sections/Contact";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/bg-rental.jpeg";
import { useCurrentUser } from "Api-contexts/currentUserContext";
import Search from "pages/Search/SearchEmployee/Search";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Icon, Stack, Switch  } from "@mui/material";
import { makeStyles } from '@mui/styles';
import MKButton from "components/MKButton";

import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
import TransparentTeamCard from "examples/Cards/TeamCards/TransparentTeamCard";
import Typography from "layouts/sections/elements/typography";
import MKAvatar from "components/MKAvatar";
import SearchJob from "pages/Search/SearchJobs";

 
const useStylesSwiper = makeStyles((theme) => ({
  swiperStyle: {  
    '& .swiper': {
      "width": "100%",
      paddingTop: "50px",
      paddingBottom: "50px" 
    }, 
    '& .swiper-slide': {
      backgroundPosition: "center",
      backgroundSize: "cover",
      width: "300px",
      maxHeight: "300px" 
    }, 
    '& .swiper-slide img': {
      display: "block",
      width: "100%"
    }  
  }, 
  
  swiperPaginationNavigationStyle: {   
    
        "& .swiper" :{
          width: "100%",
          height:" 100%"
        },

        "& .swiper-slide": {
          textAlign: "center",
          fontSize: "18px",
          background: "#fff",

          /* Center slide text vertically */
          display: "-webkit-box",
          display: "-ms-flexbox",
          display: "-webkit-flex",
          display: "flex",
          // -webkit-box-pack: "center",
          // -ms-flex-pack: "center",
          // -webkit-justify-content: "center",
          // justify-content: "center",
          // -webkit-box-align: "center",
          // -ms-flex-align: "center",
          // -webkit-align-items: "center",
          // align-items: "center",
        },

        "& .swiper-slide img": {
          display: "block",
          width:" 100%",
          height: "100%",
          objectFit: "cover",
        },

        "& .swiper": {
          marginLeft: "auto",
          marginRight: "auto",
}
  }, 

  swiperEffectFlipStyle: {  
    '& .swiper': {
      width: "100%",
      height:" 100%",
      // width: "300px",
      // maxHeight: "500px",
      padding: "50px" 
    }, 
    '& .swiper-slide': {
      backgroundPosition: "center",
      backgroundSize: "cover",
      width: "300px",
      maxHeight: "500px" 
    }, 
    '& .swiper-slide img': {
      display: "block",
      width: "100%"
    }  
  }, 

  swiperEffectCubeStyle: {  
    '& .swiper': { 
      
      width: "100%",
      height:" 100%",
        // width: "300px", 
        // maxHeight: "300px" ,
        // position: "absolute",
        // left: "50%",
        // top: "50%",
        // marginLeft: "-150px",
        // marginTop: "-150px",
    },
    '& .swiper-slide': {
      
      backgroundPosition: "center",
      backgroundSize: "cover", 

      textAlign: "center",
      fontSize: "18px",
      // background: "#fff",

      /* Center slide text vertically */
      display: "-webkit-box",
      display: "-ms-flexbox",
      display: "-webkit-flex",
      display: "flex",
      // backgroundPosition: "center",
      // backgroundSize: "cover" 
    },
    '& .swiper-slide img': {
      display: "block",
      width: "100%"
    }  
  }, 

  swiperEffectCardsStyle: {  
    '& .swiper': { 
      width: "100%",
      height:" 100%",
      // "width": "240px",
      // "height": "320px" 
    },
    '& .swiper-slide': {
      "display": "flex",
      "alignItems": "center",
      "justifyContent": "center",
      "borderRadius": "18px",
      "fontSize": "22px",
      "fontWeight": "bold",
      // "color": "#fff"
    },
    '& .swiper-slide img': {
      display: "block",
      width: "100%"
    }  
  }, 

  swiperEffectCreativeStyle: {  
    '& .swiper': { 
      width: "100%",
      height:" 100%",
      // "width": "320px" ,
      // "height": "240px",
      "margin": "100px auto"
    },
    '& .swiper-slide': {
      "display": "flex",
      "alignItems": "center",
      "justifyContent": "center", 
      "fontSize": "22px",
      "fontWeight": "bold",
      "color": "#fff" 
    },
    '& .swiper-slide img': {
      display: "block",
      width: "100%"
    }  
  }, 

  swiperEffectThumbsStyle: {   
    
"& .swiper": {
  width: "100%",
  height: "100%" 
},

"& .swiper-slide ": {
  textAlign: "center",
  fontSize: "18px",
  background: "#fff",

  /* Center slide text vertically */
  display: "-webkit-box",
  display: "-ms-flexbox",
  display: "-webkit-flex",
  display: "flex",
  // -webkit-box-pack: "center",
  // -ms-flex-pack: "center",
  // -webkit-justify-content: "center",
  // justify-content: "center",
  // -webkit-box-align: "center",
  // -ms-flex-align: "center",
  // -webkit-align-items: "center",
  // align-items: "center" 
},

"& .swiper-slide img": {
  display: "block",
  width: "100%",
  height: "100%",
  objectFit: "cover" 
},
 

"& .swiper": {
  width: "100%",
  height: "300px",
  marginLeft: "auto",
  marginRight: "auto",
},

"& .swiper-slide" :{
  backgroundSize: "cover",
  backgroundPosition: "center",
},

"& .mySwiper2 ":{
  height: "80%",
  width: "100%",
},

"& .mySwiper": {
  height: "20%",
  boxSizing: "border-box",
  padding: "10px 0",
},

"& .mySwiper .swiper-slide" :{
  width: "25%",
  height: "100%",
  opacity: "0.4",
},

"& .mySwiper .swiper-slide-thumb-active": {
  opacity: 1,
},

".swiper-slide img": {
  display: "block",
  width: "100%",
  height: "100%",
  objectFit: "cover",
}, 
  }, 

}));
function ExtremeLandingPage() {
  const [currentData] = useCurrentUser();
  const swiperStyles  = useStylesSwiper();
  const {
    websitePagesContent,
    myJobSeekerInformation,
    isAgentWebsite,
    isUserLoggedIn,
    agencyData,
    myAgencies,
    newJobSeekers
  } = currentData;
  const [homePage, setHomePage] = React.useState(null);
  const [moreText, setMoreText] = React.useState({content:"",title:""});
  const [openedDialog, setOpenedDialog] = React.useState("");
  const [aboutUsPage, setAboutUsPage] = React.useState(null);
  useEffect(() => {
    // console.log("websitePagesContent",websitePagesContent)
    if (websitePagesContent) {
      if (websitePagesContent.pages) {
        if (websitePagesContent.pages.homePage) {
          if (websitePagesContent.pages.homePage.sections) {
            setHomePage(websitePagesContent.pages.homePage.sections);
          }
        }

        if (websitePagesContent.pages.aboutUsPage) {
          if (websitePagesContent.pages.aboutUsPage.sections) {
            // aboutUsPageD=websitePagesContent.pages.aboutUsPage.sections
            setAboutUsPage(websitePagesContent.pages.aboutUsPage.sections);
          }
        }
      }
    }
  }, [websitePagesContent]);

  const didIJoinedCurrentAgency = () => {
    let res = false;
    if (agencyData && myAgencies) {
      // console.log("agencyData&&myAgencies",agencyData,myAgencies)
      if (myAgencies.length) {
        myAgencies.forEach((element) => {
          if (agencyData.id == element.id) {
            res = true;
          }
        });
      }
    }

    return res;
  };

  const getAllSkills=(workExperiances) =>{
    
    // return truncateString( JobSeeker.skillsOrSubjects,10 )}
    let res=""
    try {
      if (workExperiances) {
        if (workExperiances.length) { 
             let skills=""
           workExperiances.forEach(element => {
               skills=skills+((skills.length)?", ":"")+element.skillsOrSubjects 
            });
            skills= skills.replace(/,/g,", ")
         res= skills;
        }  
    } 
  }catch (error) {
      // console.log("errrr",error)
    }
    return res
  }

  const truncateString=(str, num, lableName) =>{
    let res=str
    try {
      if (str) {
        if (str.length > num) { 
        let btn= <Button variant="text" onClick={()=>{setMoreText({content:str,title:lableName}); setOpenedDialog("moreText"); setForceRerender(!forceRerender) }} >{"more"}</Button>
        let txt=<MKTypography variant="body2" >{str.slice(0, num) + "..."}</MKTypography>
        res= <>{txt} {btn}</>;
        }  
    } 
  }catch (error) {
      // console.log("errrr",error)
    }
    return res
  }
    

  const truncateStringDefualt=(str, num, lableName) =>{
    let res=str
    try {
      if (str) {
        if (str.length > num) { 
        let txt=<MKTypography variant="body2" >{str.slice(0, num) + "..."}</MKTypography>
        res= txt   
        }  
    } 
  }catch (error) {
      // console.log("errrr",error)
    }
    return res
  }
    
  return (
    <>
      {/* {(homePage)&&
      <MKBox
        minHeight="50vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.5),
              rgba(gradients.dark.state, 0.5)
            )}, url(${homePage.banner.backgroundImage||bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h2"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              {homePage.banner.title||"Select your Employee"}
            </MKTypography>
              
            {(homePage.banner.description)&&
            <MKTypography variant="body1" color="white" mt={1}>
             {homePage.banner.description}
            </MKTypography>}
          </Grid>
        </Container>
      </MKBox>} */}
      {homePage && (
        <MKBox
          minHeight="65vh"
          width="100%"
          sx={{
            backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
              `${linearGradient(
                rgba(gradients.dark.main, 0.5),
                rgba(gradients.dark.state, 0.5)
              )}, url(${homePage.banner.backgroundImage || bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "grid",
            placeItems: "center",
          }}
        >
          <Container>
            <Grid
              container
              item
              xs={12}
              md={7}
              justifyContent={{ xs: "center", md: "start" }}
              sx={{ textAlign: { xs: "center", md: "left" } }}
            >
              {homePage.banner.title && (
                <MKTypography
                  variant="h1"
                  color="white"
                  sx={({ breakpoints, typography: { size } }) => ({
                    [breakpoints.down("md")]: {
                      fontSize: size["3xl"],
                    },
                  })}
                >
                  {homePage.banner.title}
                </MKTypography>
              )}

              {homePage.banner.description && (
                <MKTypography
                  variant="body1"
                  color="white"
                  mt={1}
                  pr={{ md: 12, lg: 24, xl: 32 }}
                  opacity={0.8}
                >
                  {" "}
                  {homePage.banner.description}
                </MKTypography>
              )}
              <Stack direction="row" spacing={1} mt={6} mb={13}>
                <MKButton
                  variant="gradient"
                  color="success"
                  component={Link}
                  to={"/pages/search"}
                  size="large"
                >
                  Employees
                </MKButton>

                <MKButton
                  variant="gradient"
                  color="info"
                  component={Link}
                  to={"/pages/jobs"}
                  size="large"
                >
                  Jobs
                </MKButton>

                {(isAgentWebsite&&agencyData) && (
                  <>
                    {" "}
                    {isUserLoggedIn ? (
                      <>
                        {" "}
                        {myJobSeekerInformation ? (
                          <>
                            {!didIJoinedCurrentAgency() && (
                              <MKButton
                                component={Link}
                                variant="outlined"
                                color="default"
                                to={"/join-agency"}
                                // sx={{ color: ({ palette: { dark } }) => dark.main }}
                              >
                                {"Join " + agencyData.brandName}
                              </MKButton>
                            )}
                          </>
                        ) : (
                          <MKButton
                            component={Link}
                            variant="outlined"
                            color="default"
                            to={"/profile"}
                            // sx={{ color: ({ palette: { dark } }) => dark.main }}
                          >
                            {"Build your career information"}
                          </MKButton>
                        )}
                      </>
                    ) : (
                      <MKButton
                        component={Link}
                        // variant="outlined"
                        // color="default"
                        variant="gradient"
                        color="info"
                        to={isAgentWebsite ? "/login?redirectTo=/profile" : "/login"}
                        // sx={{ color: ({ palette: { dark } }) => dark.main }}
                      >
                        {"Login / Register"}
                      </MKButton>
                    )}
                  </>
                )}

                {/* <MKButton variant="outlined" color="white">
                Join
              </MKButton> */}
              </Stack>
            </Grid>
          </Container>
        </MKBox>
      )}
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -13,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
          overflow: "hidden",
        }}
      >
        {/* <Search /> */}
        {/* <Places /> */}

        <SearchJob/>

      </Card>



      {aboutUsPage && (
          <MKBox component="section" pt={5} pb={5}>
            <Container>
              <Grid container spacing={3}>
                {/* {aboutUsPage.card && (
                  <Grid item xs={12} lg={12}>
                    <CenteredBlogCard
                      image={aboutUsPage.card.image||"https://siliconnigeria.ng/wp-content/uploads/2020/09/ROAM-Africa.jpg"}
                      title={aboutUsPage.card.title}
                      description={aboutUsPage.card.description}
                      action={{
                        type: "internal",
                        route: "pages/search",
                        color: "info",
                        label: "Get Employees",
                      }}
                    />
                  </Grid>
                )} */}
                {aboutUsPage.companyDescription && (
                  <Grid item xs={12} lg={12} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
                    {/* <Grid container justifyContent="flex-start"> */}
                    <Grid item xs={12} md={12}>
                      {/* <MKBox mb={5}> */}
                      <DefaultInfoCard
                        // icon="public"
                        title={aboutUsPage.companyDescription.title}
                        description={aboutUsPage.companyDescription.description}
                      />
    



                      {/* </MKBox> */}
                      {/* </Grid>    */}
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Container>
          </MKBox>
        )}

                   
{(newJobSeekers)&&<>
{(newJobSeekers.length?true:false)&&<MKBox component="section" pt={6} >
      <Container>
        <Grid container item flexDirection="column" xs={12} lg={7} mb={6}>
          {/* <MKBox
            display="flex"
            // alignItems="center"
            // justifyContent="center"
            width="3rem"
            height="3rem"
            variant="gradient"
            bgColor="info"
            color="white"
            shadow="md"
            borderRadius="lg"
            mb={2}
          >
            <Icon>supervisor_account</Icon>
          </MKBox> */}
          <MKTypography variant="h3" mb={1}>
            New Employees
          </MKTypography>
          {/* <MKTypography variant="body2" color="text">
            This is the paragraph where you can write more details about your team. Keep you user
            engaged by providing meaningful information.
          </MKTypography> */}
        </Grid>
        {/* <Grid container spacing={3} mt={6}> */}


        <div className={swiperStyles.swiperPaginationNavigationStyle}>
            <Swiper
                slidesPerView={isMobile?3:6}
                spaceBetween={10}
                // loop={true}
                pagination={{
                  clickable: true,
                }}
                navigation={true}
                modules={[Pagination, Navigation]}
                className="mySwiper"
                breakpoints={{
                  640: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  768: {
                    slidesPerView: 3,
                    spaceBetween: 40,
                  },
                  1024: {
                    slidesPerView: 4,
                    spaceBetween: 50,
                  },
                }}
            >
 {newJobSeekers.map((JobSeeker)=>{
if(JobSeeker.profileImageUrl){
      return <SwiperSlide style={{width:"auto"}} > 
  {/* <Grid item xs={12} md={6} lg={4}> */}

            <MKBox display="flex" flexDirection="column">
                  <MKBox position="relative" width="max-content">
                    <MKAvatar
                      variant="rounded"
                      size="xxl"
                      src={JobSeeker.profileImageUrl||""}
                      alt={ truncateString( JobSeeker.firstName,10 )}
                      sx={{
                        borderRadius: ({ borders: { borderRadius } }) => borderRadius.xl,
                        position: "relative",
                        zIndex: 2,
                      }}
                    />
                    <MKBox
                      borderRadius="lg"
                      shadow="md"
                      width="100%"
                      height="100%"
                      position="absolute"
                      left={0}
                      top="3%"
                      zIndex={1}
                      sx={{
                        backgroundImage: `url(${JobSeeker.profileImageUrl})`,
                        transform: "scale(0.87)",
                        filter: "blur(12px)",
                        backgroundSize: "cover",
                      }}
                    />
                  </MKBox>
                  <MKBox py={2}>
                    <MKTypography variant="h5">{truncateStringDefualt( JobSeeker.firstName,10 )}</MKTypography>
                    <MKTypography variant="body2" color="text" >
                    { truncateString( getAllSkills(JobSeeker.allWorkExperiances ),20 )} 
                    </MKTypography>
                    <MKTypography variant="body2" color="text" mb={2}>
                      <Button component={Link} to={"/pages/JobSeeker-Details?id="+JobSeeker.id}  variant="text"   >{"more"}</Button>
                    </MKTypography>
                    {/* <Stack direction="row"  mt={3}>
    
                    </Stack> */}
                  </MKBox>
                </MKBox>










          {/* </Grid> */}
              </SwiperSlide>}  }) }
          </Swiper>
           </div> 
            
           <Grid container item flexDirection="column" xs={12} lg={12} mb={12}>
            <MKTypography variant="body2" color="text" > 
               <Button startIcon={ <Icon>supervisor_account</Icon>} component={Link} to={"/pages/search"}  variant="text"  >{" see more employees"}</Button>
            </MKTypography> 
        </Grid>
        {/* </Grid> */}
      </Container>
    </MKBox>}
</>}
 
       
{isUserLoggedIn? (
                  <>
                    {" "}
                    {isAgentWebsite ? (
                      <>
                        {" "}
                        {(!(myJobSeekerInformation)||!didIJoinedCurrentAgency()) && 
                          <>
                           
                                {/* <Grid container spacing={{ xs: 0, lg: 3 }} sx={{ mt: { xs: 0, lg: 12 } }}>
                                  <Grid item xs={12} lg={12} justifyContent="center" flexDirection="column"> */}
                                    <   >
                                      
                         {(myJobSeekerInformation)? <>
                            {(!didIJoinedCurrentAgency()&&agencyData) && (<> 
                                      
                                      
                             <MKBox component="section"  py={5}>
                            <MKBox bgColor="grey-200" py={12} px={{ xs: 3, lg: 0 }}>
                              <Grid container spacing={3}>
                                <Grid item xs={12} xl={5} ml="auto">
                                  <MKTypography variant="h4" mb={1}>
                                   { "You did not join '"+ agencyData.brandName+"' yet! "}
                                  </MKTypography>
                                  <MKTypography variant="body2" color="text" >
                                    {"To start working with "+ agencyData.brandName+ ", you must accept the privacy policy, terms and conditions and follow the instructions."}
                                  </MKTypography>
                                  <MKTypography variant="body2" color="text" mb={3}>
                                    {"Click the button below to view the instructions and accept the rules." }
                                  </MKTypography>
                                  <Grid container spacing={2}>
                                    {/* <Grid item xs={12} sm={5}>
                                      <MKInput label="Email Here" fullWidth />
                                    </Grid> */}
                                    <Grid item xs={12} sm={4}>
                                      <MKButton component={Link}  variant="gradient" color="success"   fullWidth  to={"/join-agency"} sx={{ height: "100%" }}
                                             
                                            >
                                            {"Join " + agencyData.brandName}
                                      </MKButton>
                                    </Grid>
                                  </Grid>
                                </Grid>
                               { (!isMobile)&&<Grid item xs={12} xl={5} position="relative">
                                  <MKBox
                                    component="img"
                                    src={"https://img.freepik.com/premium-photo/team-young-african-people-office-table-with-laptop_219728-4569.jpg"}
                                    alt="image"
                                    maxWidth="24.75rem"
                                    width="100%"
                                    borderRadius="lg"
                                    shadow="xl"
                                    mt={{ lg:-24}}
                                    // display={{ md: "none", lg: "block" }}
                                  />
                                </Grid>}
                              </Grid>
                            </MKBox>
                          </MKBox>  
                                      
                                      
                                      
                                      </> )}</>:<>
                                      
                             <MKBox component="section"  py={5} >
                            <MKBox bgColor="grey-200" py={12} px={{ xs: 3, lg: 0 }}>
                              <Grid container spacing={3}>
                                <Grid item xs={12} xl={5} ml="auto">
                                  <MKTypography variant="h4" mb={1}>
                                    Be the first to see the news
                                  </MKTypography>
                                  <MKTypography variant="body2" color="text" mb={3}>
                                    Your company may not be in the software business, but eventually, a software company
                                    will be in your business.
                                  </MKTypography>
                                  <Grid container spacing={2}>
                                    {/* <Grid item xs={12} sm={5}>
                                      <MKInput label="Email Here" fullWidth />
                                    </Grid> */}
                                    <Grid item xs={12} sm={4}>
                                      <MKButton component={Link}  variant="gradient" color="success" sx={{ height: "100%" }}
                                              to={"/profile"}
                                              // sx={{ color: ({ palette: { dark } }) => dark.main }}
                                            >
                                              {"Build your career information"}
                                      </MKButton>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item xs={12} xl={5} position="relative">
                                  <MKBox
                                    component="img"
                                    src={image}
                                    alt="image"
                                    maxWidth="24.75rem"
                                    width="100%"
                                    borderRadius="lg"
                                    shadow="xl"
                                    mt={-24}
                                    display={{ xs: "none", lg: "block" }}
                                  />
                                </Grid>
                              </Grid>
                            </MKBox>
                          </MKBox> 
                              </>}
                                
                            </>
                          {/* </Grid>
                        </Grid>  */}
                          </>
                       }
                      </>
                    ) : null}
                  </>
                ) : 
                 
                <MKBox component="section" mb={5}  mt={{xs:0, sm: 3, lg: 2 }} >
                <MKBox bgColor="grey-200" pb={2} pt={{ xs: 0, lg: 2 }} px={{ xs: 3, lg: 0 }}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} xl={5} ml="auto">
                      <MKTypography variant="h4" mb={1}>
                       { "Are you a job seeker? "}
                      </MKTypography>
                      <MKTypography variant="body2" color="text"  mb={3}>
                        {"Sign up to this site and get a job in your field."}
                      </MKTypography>
                      {/* <MKTypography variant="body2" color="text" mb={3}>
                        {"Click the button below to view the instructions and accept the rules." }
                      </MKTypography> */}
                      <Grid container spacing={2}>
                        {/* <Grid item xs={12} sm={5}>
                          <MKInput label="Email Here" fullWidth />
                        </Grid> */}
                        <Grid item xs={12} sm={4}>
                          <MKButton component={Link}  variant="gradient" color="success"   fullWidth  to={"/register"} sx={{ height: "100%" }}
                                 
                                >
                                {"Sign up"}
                          </MKButton>
                        </Grid>
                      </Grid>
                    </Grid>
                   { (true)&&<Grid item xs={12} xl={5} position="relative">
                      <MKBox
                        component="img"
                        src={"https://image.cnbcfm.com/api/v1/image/105783687-1552080443844laptopwhileworkingfromhome.jpg?v=1591701691&w=740&h=416&ffmt=webp&vtcrop=y"}
                        alt="image"
                        maxWidth="24.75rem"
                        width="100%"
                        borderRadius="lg"
                        shadow="xl"
                        mt={{xs:0, lg:-14}}
                        // display={{ md: "none", lg: "block" }}
                      />
                    </Grid>}
                  </Grid>
                </MKBox>
              </MKBox>  
                }

        {/* <Testimonials /> */}
        {/* <Faq /> */}
        <Contact id="contact" />
      
    <Dialog open={openedDialog=="moreText"} onClose={()=>{setOpenedDialog("")}}>
        {moreText&&<>
       {moreText.title&& <DialogTitle>{moreText.title}</DialogTitle>}
       {moreText.content&& <DialogContent>
          <DialogContentText>
           {moreText.content}
          </DialogContentText> 
        </DialogContent>}</>}
        <DialogActions>
          <Button onClick={()=>{setOpenedDialog("")}}>close</Button> 
        </DialogActions>
      </Dialog>
    
    </>
  );
}

export default ExtremeLandingPage;
