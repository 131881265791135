// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import TransparentTeamCard from "examples/Cards/TeamCards/TransparentTeamCard";
 
function Team(props) {
  const socialIcons = (
    <>
      <Tooltip placement="top" title="Follow me!">
        <MKBox
          component="i"
          fontSize="1.125rem"
          className="fab fa-twitter"
          sx={{
            color: ({ palette: { socialMediaColors } }) => socialMediaColors.twitter.main,
            cursor: "pointer",
          }}
        />
      </Tooltip>
      <Tooltip placement="top" title="Follow me!">
        <MKBox
          component="i"
          fontSize="1.125rem"
          className="fab fa-dribbble"
          sx={{
            color: ({ palette: { socialMediaColors } }) => socialMediaColors.dribbble.main,
            cursor: "pointer",
          }}
        />
      </Tooltip>
      <Tooltip placement="top" title="Follow me!">
        <MKBox
          component="i"
          fontSize="1.125rem"
          className="fab fa-linkedin"
          sx={{
            color: ({ palette: { socialMediaColors } }) => socialMediaColors.linkedin.main,
            cursor: "pointer",
          }}
        />
      </Tooltip>
    </>
  );

  return (
    <MKBox component="section" py={6}>
      <Container>
        {(props.teams)&&<>
        {((props.teams.length>0)?true:false)&&<>
        

          <MKBox component="section" py={12}>
      <Container>
        <Grid container justifyContent="center">
           <Grid item xs={12} md={8}>
            <MKTypography variant="h2" align="center" fontWeight="bold" gutterBottom>
              Our Team
            </MKTypography> 
          </Grid> 



         {(props.teams) &&
         <Grid   item xs={12} md={12}>
         <Grid container spacing={3} justifyContent="center" >
            {props.teams.map((team)=><Grid item  >
            <TransparentTeamCard
              image={team.image}
              name={ team.name}
              position={ team.profession}
              description= {team.description}
              // socials={socialIcons}
            />
          </Grid>  
          )}
            
         
          </Grid>
          </Grid>
          }
        </Grid>
      </Container>
    </MKBox>

 
         </>}
         </>}
      </Container>
    </MKBox>
  );
}

export default Team;
