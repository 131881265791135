/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import React, { useState ,useEffect} from "react";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKBadge from "components/MKBadge";

// Material Kit 2 PRO React components
import SimpleReviewCard from "examples/Cards/ReviewCards/SimpleReviewCard";

// Images
import bgPattern from "assets/images/shapes/pattern-lines.svg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import { useCurrentUser } from "Api-contexts/currentUserContext";

function Testimonials() {  
  const [currentData] = useCurrentUser();
  const {websitePagesContent } = currentData;
  const [testimonialsPage, setTestimonialsPage] = React.useState(null);
  useEffect(() => {
    // console.log("websitePagesContent",websitePagesContent) 
    if(websitePagesContent){
      if(websitePagesContent.pages){
        if(websitePagesContent.pages.testimonialsPage){
          if(websitePagesContent.pages.testimonialsPage.sections){ 
            setTestimonialsPage(websitePagesContent.pages.testimonialsPage.sections)
          }
        }
      }
    }
  }, [websitePagesContent]);
  return (<>
   { (testimonialsPage)&&<>
   {(testimonialsPage.testimonials)&&<>
   {((testimonialsPage.testimonials.length?true:false))&& 
   <MKBox position="relative" variant="gradient" bgColor="dark" mt={6} py={2} mx={-2}>
      <MKBox
        component="img"
        src={bgPattern}
        alt="background-pattern"
        position="absolute"
        top={0}
        left={0}
        width="100%"
        opacity={0.2}
        display={{ xs: "none", md: "block" }}
      />
      <Container>
       {(testimonialsPage.header)&& 
       <Grid
          container
          justifyContent="center"
          sx={{ pt: 8, pb: 5, position: "relative", zIndex: 3 }}
        >
          <Grid item xs={12} md={6} sx={{ textAlign: "center" }}>
            <MKBadge
              badgeContent="testimonials"
              variant="contained"
              color="white"
              size="sm"
              container
              sx={{ mb: 1 }}
            />
            <MKTypography variant="h2" color="white" mb={1}>
              {testimonialsPage.header.title||"Some thoughts from our clients"}
            </MKTypography>
            <MKTypography variant="body1" color="white" fontWeight="light">
              {testimonialsPage.header.description||
              
              `If you are selected for them you&apos;ll also get three tickets, opportunity to
              access Investor Office Hours and Mentor Hours and much more all for free.
            `}</MKTypography>
          </Grid>
        </Grid>}
       {(testimonialsPage.testimonials)&&
        <>
       <Grid container spacing={3} sx={{ mt: 10, mb: 6 }}>
       { testimonialsPage.testimonials.map((testimonial)=>
          <Grid item xs={12} md={4}>
            <SimpleReviewCard
              image={testimonial.image||team2}
              name={testimonial.name}
              username={testimonial.role}
              review={testimonial.review}
            />
        </Grid>)}
          </Grid> </>}
      </Container>
    </MKBox>}
    </>}
     </>}
  </>);
}

export default Testimonials;
