// @mui material components 
import Icon from "@mui/material/Icon";

// Material Kit 2 PRO React components  

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import { cities, specificAreas, subCities, citiesOnly } from "Api-master-datas/masterDatas.js";
import queryString from "query-string";
import { toast } from "react-toastify";

import PropTypes from 'prop-types';

import parse from 'html-react-parser';
// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
// import MKDatePicker from "components/MKDatePicker";
import MKButton from "components/MKButton";
import { Link, useNavigate } from "react-router-dom";
import { useCurrentUser } from "Api-contexts/currentUserContext";
import React, { useEffect } from "react";
import { HttpService } from "Api-Services/httpService";

import ReactGA from "react-ga4";
import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { 
  Box,
  Button,
  CardActions,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  Input,
  InputBase,
  Menu,
  MenuItem,
  Paper,
  Select,
  Skeleton,
  Stack, 
  Tab, 
  Tabs, 
  Typography,
} from "@mui/material";
import { Card, TextField } from "@mui/material";
import { element } from "prop-types";
import { ArrowBack, ArrowForwardIosSharp, Delete, Directions, ExpandMore, ExpandMoreOutlined, Favorite, Filter, Phone, RecentActors, Search, Send, Share, Update } from "@mui/icons-material";
import { CommonServices } from "Api-Services/commonServices"; 
import { isMobile } from "react-device-detect";


const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderBottom: "darkgreen solid",
  '&:not(:last-child)': {
    // borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharp sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
}));



function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 2,px:1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function SearchJob() { 
  // console.log("cities", cities);
 
  // const [searchResult, setSearchResult] = React.useState([]);
  const [notSearchedYet, setNotSearchedYet] = React.useState(true);
  // const [forceRerender, setForceRerender] = React.useState(false); 
 
  const navigateComp = useNavigate();
  // console.log("cities", cities);

  const [currentData] = useCurrentUser();
 
  const {employmentTypes,employmentJobLocationTypes,employmentSalaryDealTypes, myJobSeekerInformation,currentUser,employmentAgencyJobVacancies, educationLevels, jobs, agencyData, isAgentWebsite } = currentData;
  const [currentEducationLevelId, setCurrentEducationLevelId] = React.useState(5);
  const [sex, setSex] = React.useState("Any");
  const [professionTitle, setProfessionTitle] = React.useState("");
  const [jobSeekerSearchFieldsandValue, setJobSeekerSearchFieldsandValue] = React.useState({
    currentEducationLevelId: "Any",
    sex: "Any",
    city: "Any",
    subCity: "Any",
    specificArea: "Any",
  });
  const [jobSearchFieldsandValue, setJobSearchFieldsandValue] = React.useState({
    jobId: "",
  });
  const [jobId, setJobId] = React.useState("");
  const [agencyJobs, setAgencyJobs] = React.useState(null);
  const [jobsToChoose, setJobsToChoose] = React.useState(jobs);
  const [forceRenderer, setForceRenderer] = React.useState(false);
  const [searchExpanded, setSearchExpanded] = React.useState(true);
  const [filterExpanded, setFilterExpanded] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [searchResult, setSearchResult] = React.useState(null);
  const [expanded, setExpanded] = React.useState('');
 
  const [forceRerender, setForceRerender] = React.useState(false);
  const [searchKeyword, setSearchKeyword] = React.useState("");
  const [searchedKeyword, setSearchedKeyword] = React.useState("");
  const [isFirstTimeSearch, setIsFirstTimeSearch] = React.useState(true);
  const [openedDialog, setOpenedDialog] = React.useState("");
  const [moreText, setMoreText] = React.useState({content:"",title:""});
  const [value, setValue] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };


  const viewDetails=(id)=>{
    let path="/pages/jobs/job-details?id="+id//+"&employmentAgencyId="+jobSeeker.employmentAgencyId
    navigateComp(path)
  }

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  useEffect(() => {
    // console.log("employmentAgencyJobVacancies", employmentAgencyJobVacancies);
    // console.log(" myJobSeekerInformation", myJobSeekerInformation);
    if (location.search) {
      let locationSearch = queryString.parse(location.search);
      if (locationSearch) {
        if (locationSearch.search) {
          searchByKeyword(locationSearch.search);
        }
      }
    }
  }, [location.pathname,location.search]);
 

  useEffect(() => {
    initializeFilter();
    //     const newArr = cities.map(({
    //       EASTING,
    //       ELEVATION
    //  ,     ELEV_TXT
    //   ,    EROSIVITY
    //    ,   ETTOWN01CO
    //     ,  ETTOWN01_1
    //      , FID
    //       ,K_VALUE
    //     ,  LAT
    //      , LON
    //      , NORTHING ,
    //       POP,
    //      POPRUR
    //  ,     POPTOT
    //   ,    POPURB
    //    ,   POP_TXT
    //     ,  RAIN
    //      , RAIN_TXT ,
    //       REGION,
    //       SOIL
    //   ,    WEREDA
    //    ,   ZONES
    //     ,  the_geom
    //       , ...rest}) => {
    //       return rest;
    //     });

    // console.log("cities", cities);

    // console.log(" myJobSeekerInformation", myJobSeekerInformation);

    // console.log("areas", specificAreas)
    if(location.pathname.indexOf("job")>-1){
      ReactGA.send({ hitType: "pageview", page: location.pathname, title: "job search" });
    }
  }, []);

  useEffect(() => {
    // console.log("agencyData",agencyData)
    let filterdJobs = [];
    if (agencyData) {
      if (agencyData.jobsId) {
        let allagencyjobs = agencyData.jobsId.split(",");
        filterdJobs = jobs.filter((jobD) => {
          let res = false;
          allagencyjobs.forEach((element) => {
            element = parseInt(element);
            if (element) {
              if (element == jobD.id) {
                res = true;
              }
            }
          });
          return res;
        });
        setAgencyJobs(filterdJobs);
        setJobsToChoose(filterdJobs);
      }
    }
     setForceRenderer(!forceRenderer);
  }, [agencyData, jobs]);

  const goToPage = (page) => {
    navigateComp(page);
  };

  const initializeFilter = () => {
    // setJobSeekerSearchFieldsandValue (
    //     )
    // setForceRenderer(!forceRenderer)
  };

  const handleSearchFieldsandValue = async (field, value, objectType) => {
    try {
      let searchFieldsandValueD = {};
      if (objectType == "jobseeker") {
        searchFieldsandValueD = jobSeekerSearchFieldsandValue;
      }
      if (objectType == "job") {
        searchFieldsandValueD = jobSearchFieldsandValue;
      }

      searchFieldsandValueD[field] = value;

      if (objectType == "jobseeker") {
        setJobSeekerSearchFieldsandValue(searchFieldsandValueD);
      }
      if (objectType == "job") {
        setJobSearchFieldsandValue(searchFieldsandValueD);
      }
      // console.log("searchFieldsandValueD", searchFieldsandValueD);

      setForceRenderer(!forceRenderer);
    } catch (error) {}
  };

  const search = async (searchD) => {
    try {
      let jobSeekerSearchData;
      let jobSearchData;
      let pathSearch;
      if (searchD) {
        let pathSearchData = CommonServices.decryptLocalData(searchD);
        // console.log("pathSearchData", pathSearchData);
        jobSeekerSearchData = pathSearchData.jobSeekerSearch;
        jobSearchData = pathSearchData.jobSearch;
      } else {
        jobSeekerSearchData = jobSeekerSearchFieldsandValue;
        jobSearchData = jobSearchFieldsandValue;
        pathSearch = { jobSeekerSearch: jobSeekerSearchData, jobSearch: jobSearchData };
        pathSearch = CommonServices.encryptLocalData(pathSearch);
        let path = "/pages/search?search=" + pathSearch; //+"&employmentAgencyId="+jobSeeker.employmentAgencyId
        navigateComp(path);
      }

      let jobSeekerSearch = {};
      let jobSearch = {};
      let agencyJobSeekerSearch = {};
      if (isAgentWebsite) {
        if (agencyData) {
          if (agencyData.id) {
            agencyJobSeekerSearch.employmentAgencyId = agencyData.id;
          }
        }
      }

      if (jobSeekerSearchData) {
        Object.entries(jobSeekerSearchData).forEach((element) => {
          if (element[1]) {
            if (element[1] != "Any") {
              jobSeekerSearch[element[0]] = element[1];
            }
          }
          if (searchD) {
            handleSearchFieldsandValue(element[0], element[1], "jobseeker");
          }
        });
      }

      if (jobSearchData) {
        Object.entries(jobSearchData).forEach((element) => {
          if (element[1]) {
            if (element[1] != "Any") {
              jobSearch[element[0]] = element[1];
            }
          }
          if (searchD) {
            handleSearchFieldsandValue(element[0], element[1], "job");
          }
        });
      }

      let data = {
        jobSeeker: jobSeekerSearch || {},
        job: jobSearch || {},
        agencyJobSeeker: agencyJobSeekerSearch || {},
      };

      // console.log("data", data);

      if (location.pathname.indexOf("pages/search") < 0) {
        let searchFields = "";
        Object.entries(data).forEach((element) => {
          searchFields = searchFields + element[0] + "=" + element[1] + "&";
        });
        navigateComp("/pages/search?" + searchFields);

        // pages/search
      }

      try {
        // let valid = false;
        // setLoginResponse(null);
        // setInvalidResponse("");
        // setIsFormValid(true);
        // if (fullName && message) {
        //   setIsFormValid(true);
        //   valid = true;
        // } else {
        //   setIsFormValid(false);
        //   valid = false;
        //   setInvalidResponse(" Please provide your full name and your message");
        // }
        // console.log("userEmail&&password",userEmail,password, valid)
        if (true) {
          if (sex == "All") {
            delete data.sex;
          }
          // let relativePath = "/api/employmentagencyjobseeker/search";
          let relativePath = "/api/employmentjobseeker/search";

          // let toastId = toast.loading("Serching job seekers...",{
          //   position: toast.POSITION.BOTTOM_LEFT
          // })
          setLoading(true);

          // let AdditionalData={withCredentials: true, credentials: 'include'}//{  credentials: 'include' }//header can be included here
          let contactUsRes = await postDocuments("contactus", relativePath, data, {});
          setLoading(false);

          // toast.update(toastId, { render: contactUsRes.message, type: contactUsRes.success?"success":"warn", isLoading: false , autoClose: 5000});
          contactUsRes.professionTitle = professionTitle || "";
          contactUsRes.employees = null;
          if (contactUsRes.success) {
            if (contactUsRes.record) {
              contactUsRes.employees = contactUsRes.record;
              if (contactUsRes.record.length) {
                setSearchExpanded(false);
              }
            }
          }
          setSearchResult(contactUsRes.employees);
          handleSearchResult(contactUsRes);
          if (contactUsRes.success) {
            // toast.success(contactUsRes.message, {
            //   position: toast.POSITION.TOP_CENTER,
            // });
          } else {
            // toast.error(contactUsRes.message, {
            //   position: toast.POSITION.TOP_LEFT,
            // });
          }
        }
        // setLoading(false);
      } catch (error) {}
    } catch (error) {}
  };

  
  const searchByKeyword = async (searchD) => {
    let pathSearch;
    let searchKeywordD="";
    try { 
       
      if (searchD) {
        let pathSearchData = CommonServices.decryptLocalData(searchD);
        // console.log("pathSearchData", pathSearchData);
        searchKeywordD = pathSearchData.keyword;
        setSearchKeyword(searchKeywordD)
        // jobSeekerSearchData = pathSearchData.jobSeekerSearch;
        // jobSearchData = pathSearchData.jobSearch;
      }
       else {
        searchKeywordD = searchKeyword;
        if(searchKeyword){
        // jobSearchData = jobSearchFieldsandValue;
        pathSearch = { keyword:searchKeywordD };
        pathSearch = CommonServices.encryptLocalData(pathSearch);
        let path = "/pages/jobs?search=" + pathSearch; //+"&employmentAgencyId="+jobSeeker.employmentAgencyId
        navigateComp(path);

        }
      }
      
 
      let data = {
        keyword:searchKeywordD,
      };

        if(agencyData.id){
              data.employmentAgencyId=agencyData.id
        }

      try {  
        setIsFirstTimeSearch(false)
          // let relativePath = "/api/employmentagencyjobseeker/search";
          let relativePath = "/api/employmentjobvacancy/getmyagencyjobvacanciesbykeyword";
 
          setLoading(true);

          // let AdditionalData={withCredentials: true, credentials: 'include'}//{  credentials: 'include' }//header can be included here
          let contactUsRes = await postDocuments("contactus", relativePath, data, {});
          setSearchedKeyword(searchKeywordD)
          setLoading(false);
          // console.log("contactUsRes",contactUsRes)
          // toast.update(toastId, { render: contactUsRes.message, type: contactUsRes.success?"success":"warn", isLoading: false , autoClose: 5000});
          // contactUsRes.professionTitle = professionTitle || "";
          // contactUsRes.employees = null;
          let searchResultD=[]
          if (contactUsRes.success) {
            if (contactUsRes.record) { 
              if (contactUsRes.record.length) {
                searchResultD=contactUsRes.record;
                setSearchExpanded(false);
              }
            }
          }
          
        if (contactUsRes.success) {  
            ReactGA.event({
              category: "job search",
              action: "job search found",
              label: searchKeywordD, // optional
              value: contactUsRes.record?contactUsRes.record.length:0, // optional, must be a number
              nonInteraction: true, // optional, true/false
              transport: "xhr", // optional, beacon/xhr/image
            });
        }
        else{  
          ReactGA.event({
            category: "job search",
            action: "job search not found",
            label: searchKeywordD, // optional
            value: 0, // optional, must be a number
            nonInteraction: true, // optional, true/false
            transport: "xhr", // optional, beacon/xhr/image
          });
        }
          setSearchResult(searchResultD);
          // handleSearchResult(contactUsRes);
          if (contactUsRes.success) {
            // toast.success(contactUsRes.message, {
            //   position: toast.POSITION.TOP_CENTER,
            // });
          } else {
            // toast.error(contactUsRes.message, {
            //   position: toast.POSITION.TOP_LEFT,
            // });
          }
        }
        // setLoading(false);
       catch (error) {
          setLoading(false);}
        } catch (error) {
          setLoading(false);

        }
  };

  
  const parseDate = (dt) => {
    let d = new Date(dt);
    let m = d.getMonth() + 1;
    let dateD = m + "/" + d.getDate() + "/" + d.getFullYear();
    return dateD;
  };

  const filterResult = (searchField, searchValue) => {
    let res = { professionTitle: "", employees: null };
    let dataArray = searchResult;
    // console.log("dataArray   ", dataArray, searchField, searchValue);
    try {
      if (dataArray && searchField && searchValue) {
        if (dataArray.length > 0) {
          let filteredLevels = dataArray.filter((level) => {
            if (level[searchField]) {
              return level[searchField].indexOf(searchValue) > -1;
            } else {
              return false;
            }
          });
          if (filteredLevels.length) {
            res = filteredLevels;
          }
        }
      } else if (!searchValue) {
        res = dataArray;
      }
      // console.log("filtered    ", res);
    } catch (error) {
      // console.log("filtered city error", error);
    }

    res.professionTitle = professionTitle || "";
    res.employees = res;

    handleSearchResult(res);
  };

  const getDataFromArray = (dataArray, searchField, searchValue, returnRows, returnField) => {
    let res = {};
    // console.log("dataArray   ", dataArray,searchField, searchValue, returnRows, returnField);
    try {
      if (dataArray && searchField && searchValue) {
        if (dataArray.length > 0) {
          let filteredLevels = dataArray.filter((level) => level[searchField] == searchValue);
          if (filteredLevels.length) {
            res = filteredLevels;
            if (returnRows == "single") {
              let row = filteredLevels[0];
              res = row;
              if (returnField) {
                let rowField = row[returnField];
                res = rowField;
              }
            }
          }
        }
      }
      // console.log("filtered city  ", res);
    } catch (error) {
      // console.log("filtered city error", error);
    }
    return res;
  };

  const postDocuments = async (documentName, relativePath, data) => {
    let responseD = { success: false, message: "", record: null };
    let documentsResponse = { success: false };
    // setLoading(true);

    ////////// UPDATED/////////////
    documentsResponse = await HttpService.postService(relativePath, data, {}, "");
    // console.log(documentName, " data: ", documentsResponse   );
    // setLoading(false);

    if (documentsResponse.data) {
      responseD.record = documentsResponse.data.record;
      responseD.message = documentsResponse.data.message;
    }
    if (documentsResponse.success) {
      responseD.success = true;
      let record = responseD.record;

      //write codtions down here//
    }

    // console.log(documentName, " responseD: ", responseD);
    // setForceRerender(!forceRerender);
    return responseD;
    ////////// UPDATED/////////////
  };

  
  const truncateString=(str, num, lableName) =>{
    let res=str
    try {
      if (str) {
        if (str.length > num) { 
        let btn=lableName? <Button variant="text" onClick={()=>{setMoreText({content:str,title:lableName}); setOpenedDialog("moreText"); setForceRerender(!forceRerender) }} >{"View more"}</Button>:null
        let txt=str.slice(0, num) + "..."
        res= <>{parse(txt)} {btn}</>;
        } 
        else{
          
        res= <>{parse(res)}</>;
        } 
    } 
  }catch (error) {
      // console.log("errrr",error)
    }
    return res
  }

  const getEducationLevelById = (id, rows, field) => {
    let res = {};
    try {
      if (educationLevels && id) {
        let educationLevelsD = [...[{ title: "Any", id: "Any" }], ...educationLevels];

        let filteredLevels = educationLevelsD.filter((level) => level.id == id);
        if (filteredLevels.length) {
          res = filteredLevels;
          if (rows == "single") {
            let row = filteredLevels[0];
            res = row;
            if (field) {
              let rowField = row[field];
              res = rowField;
            }
          }
        }
      }
    } catch (error) {
      // console.log("filtered error ", error);
    }
    // console.log("filtered educat ", res);
    return res;
  };

  const getCityById = (city, rows, field) => {
    let res = {};
    try {
      if (cities && city) {
        let filteredLevels = cities.filter((level) => level.TOWN_NAME == city);
        if (filteredLevels.length) {
          res = filteredLevels;
          if (rows == "single") {
            let row = filteredLevels[0];
            res = row;
            if (field) {
              let rowField = row[field];
              res = rowField;
            }
          }
        }
      }
    } catch (error) {
      // console.log("filtered city error", error);
    }
    return res;
  };

  const jobList=(jobsData)=>{
    try {
      
    return (<>





    { (jobsData)&& <div style={ {marginBottom:"10px"}}
    >
        { jobsData.map((jobVacancy)=> 
        ((!(jobVacancy.isJobClosed))&&((new Date(jobVacancy.deadLineDateTime).getTime())>(new Date().getTime())))&&
         <div style={ {marginBottom:"10px"}}>
        <Accordion expanded={expanded === jobVacancy.id} 
          onChange={handleChange(jobVacancy.id)}
          >
            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header"
                  expandIcon={<ExpandMoreOutlined/>}
              >
              {/* <MKBox component="section"> */}
                {/* <Container> */}
                  <Grid container   >
                  
                      <Grid item xs={12} lg={10} sx={{ mt: 2 }}>
                        
                        <Grid container spacing={{ xs: 0, lg: 0 }} 
                        // sx={{ pt: 2, pb: 3, px: 2, mx: "auto" }}
                        >
                          

                      <Grid item xs={12} lg={5} sx={{ mt: 2 }}>

                                              
                          <MKTypography
                              display="block"
                              variant="h6"
                              fontWeight="bold"
                              // color="text"
                              mb={1}
                            >
                              {truncateString(jobVacancy.title,65)}
                            </MKTypography>
                          <MKTypography
                              display="block"
                              variant="button"
                              fontWeight="regular"
                              // color="text"
                              mb={1}
                            >
                            Deadline: {parseDate(jobVacancy.deadLineDateTime)}
                            </MKTypography>
                          </Grid> 

                          <Grid item xs={12} lg={4} sx={{ mt: 2 }}>
                            <MKTypography
                              display="block"
                              variant="button"
                              fontWeight="regular"
                              color="text"
                              mb={1}
                            >
                              Location
                            </MKTypography>
                            {/* <Autocomplete
                              // defaultValue="High School"
                              onChange={(e, val) => { 
                                  // setCurrentEducationLevelId(val.id);
                                handleSearchFieldsandValue("currentEducationLevelId",val?val.id:"Any","jobseeker")}
                              }  
                              options={[...[{title:"Any",id:"Any"}], ...educationLevels]}
                              defaultValue={getEducationLevelById(jobSeekerSearchFieldsandValue.currentEducationLevelId, "single")}
                              getOptionLabel={(option) => option.title}
                              renderInput={(params) => <MKInput {...params} variant="standard" />}
                            /> */}

                           <MKTypography
                              display="block"
                              variant="button"
                              fontWeight="regular"
                              // color="text"
                              mb={1}
                            >
                             {jobVacancy.city? <> 
                              {jobVacancy.city+" , "+jobVacancy.subCity }
                               {" , "+jobVacancy.specificArea}
                            </>
                            :"Not Mentioned"}
                          
                            </MKTypography> 
                          </Grid> 

                          {/* <Grid item xs={12} lg={3} sx={{ mt: 2 }}>
                          <MKTypography
                            display="block"
                            variant="button"
                            fontWeight="regular"
                            color="text"
                            mb={1}
                          >
                            Catagory
                          </MKTypography>
                           
                          <MKTypography
                              display="block"
                              variant="button"
                              fontWeight="regular"
                              // color="text"
                              mb={1}
                              
                            >
                              {"Employee profesion"}
                            </MKTypography>
                            </Grid> */}
                          <Grid item xs={12} lg={3} sx={{ mt: 2 }}>
                          <MKTypography
                            display="block"
                            variant="button"
                            fontWeight="regular"
                            color="text"
                            mb={1}
                          >
                            Salary
                          </MKTypography>
                          {/* <Autocomplete
                            onChange={(e, val) => {
                              // setSex(val);
                              handleSearchFieldsandValue("sex",val?val:"Any","jobseeker")

                            }}
                            value={jobSeekerSearchFieldsandValue.sex}
                            options={["Any", "male", "female"]}
                            getOptionLabel={(option) =>  option }
                            renderInput={(params) => <MKInput {...params} variant="standard" placeholder="select gender"/>}
                          /> */}
                          <MKTypography
                              display="block"
                              variant="button"
                              fontWeight="regular"
                              // color="text"
                              mb={1}
                            >
                              {jobVacancy.salary?jobVacancy.salary+" "+jobVacancy.salaryCurrency+" ("+jobVacancy.salaryDuration+")":""}
                            </MKTypography>
                            </Grid>

                        </Grid>
                      </Grid> 
                      <Grid item xs={12} lg={2} sx={{ mt: 2 }}>
                        
                    {/* <MKButton
                          variant="gradient"
                          disabled={loading || !professionTitle}
                          color="success"
                          onClick={() => {
                            search();
                          }}
                          fullWidth
                        > 
                          Apply Now
                        </MKButton> */}
                        <Stack direction="row" mt={{lg:2}} spacing={2}>
                        <MKButton
                        size="small"
                          variant="gradient"
                          // disabled={loading || !professionTitle}
                          color="success"
                          onClick={() => {
                            viewDetails(jobVacancy.id);
                          }}
                          fullWidth
                        > 
                          Apply Now
                        </MKButton>
                        </Stack>
                    </Grid> 
                    
                    {/* <Grid item xs={12} lg={3} sx={{ mt: 4 }}>
                      <Box sx={{ m: 1, position: "relative" }}>
                        <MKButton
                          variant="gradient"
                          disabled={loading || !professionTitle}
                          color="info"
                          onClick={() => {
                            search();
                          }}
                          fullWidth
                        > 
                          search
                        </MKButton>
                        {loading && (
                          <CircularProgress
                            size={24}
                            sx={{ 
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              marginTop: "-12px",
                              marginLeft: "-12px",
                            }}
                          />
                        )}
                      </Box>
                    </Grid> */}
                  </Grid>
                {/* </Container> */}
              {/* </MKBox> */}
            </AccordionSummary>
            <AccordionDetails>
              <MKBox ml={4}>
                  <MKTypography variant="h6"  color="text" >
                    Overview:
                  </MKTypography>
                <MKBox ml={1}>
                  <MKTypography variant="subtitle2" fontWeight="light" color="text">
                    Employment type :<strong style={{fontWeight:"430"}}> { getDataFromArray(
                            employmentTypes,
                            "id",
                            jobVacancy.employmentTypeId,
                            "single",
                            "title"
                          )}</strong>
                  </MKTypography> 
                  <MKTypography variant="subtitle2" fontWeight="light" color="text">
                    Salary deal : <strong style={{fontWeight:"430"}}>{  getDataFromArray(
                            employmentSalaryDealTypes,
                            "id",
                            jobVacancy.salaryDealTypeId,
                            "single",
                            "title"
                          ) }</strong>
                  </MKTypography> 
                  <MKTypography variant="subtitle2" fontWeight="light" color="text">
                    Gender :<strong style={{fontWeight:"430"}}> {jobVacancy.applicantGender}</strong>
                  </MKTypography> 
                  <MKTypography variant="subtitle2" fontWeight="light" color="text">
                    Experiance : <strong style={{fontWeight:"430"}} >{((jobVacancy.minimumExperianceInYear!=null)?"starting from "+jobVacancy.minimumExperianceInYear+" ":"" )
                              + 
                              ((jobVacancy.maximumExperianceInYear>jobVacancy.minimumExperianceInYear)?" to "+jobVacancy.maximumExperianceInYear:"" )
                              +(" year(s) ")}</strong> 
                  </MKTypography> 
                </MKBox>
                </MKBox>
              <MKBox ml={4} mt={2}>
                  <MKTypography variant="h6"  color="text" >
                    Job Description:
                  </MKTypography>
                <MKBox ml={1}>
                  <MKTypography variant="subtitle2" fontWeight="light" color="text">
                  {parse(jobVacancy.description)}
                  </MKTypography>
                  </MKBox>
                  <Button
                    size="small"
                      // variant={Link}
                      // disabled={loading || !professionTitle}
                      // color="success"
                      onClick={() => {
                        viewDetails(jobVacancy.id);
                      }}
                        
                    > 
                      Read More
                    </Button>
                </MKBox>
            </AccordionDetails>
          </Accordion></div>) }


        </div>}


    </>)
    } catch (error) {
      return <></>
    }

  }
  
  const jobsLoading=(num)=>{
    try {
      
    return (<>


    
{/* {<Stack spacing={1}> 
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} /> 
            <Skeleton variant="circular" width={40} height={40} />
            <Skeleton variant="rectangular" width={210} height={60} />
            <Skeleton variant="rounded" width={210} height={60} />
          </Stack>
} */}


  <div style={ {marginBottom:"10px"}}
    >
        { [...Array(num?num:5)].map(()=> 
         <div style={ {marginBottom:"10px"}}>
        <Accordion expanded={false}  
          >
            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" 
              >
              {/* <MKBox component="section"> */}
                {/* <Container> */}
                  <Grid container   >
                  
                      <Grid item xs={12} lg={10} sx={{ mt: 2 }}>
                        
                        <Grid container spacing={{ xs: 0, lg: 0 }} 
                        // sx={{ pt: 2, pb: 3, px: 2, mx: "auto" }}
                        >
                          

                      <Grid item xs={12} lg={4} sx={{ mt: 2 }}>

                                              
                          <MKTypography
                              display="block"
                              variant="h6"
                              fontWeight="bold"
                              // color="text"
                              mb={1}
                            >
                               <Skeleton variant="rectangular" width={210} height={30} />
                             
                            </MKTypography>
                          <MKTypography
                              display="block"
                              variant="button"
                              fontWeight="regular"
                              // color="text"
                              mb={1}
                            >
                            <Skeleton variant="text" width={250} sx={{ fontSize: '1rem' }} />
                            </MKTypography>
                          </Grid> 

                          <Grid item xs={12} lg={3} sx={{ mt: 2 }}>
                            <MKTypography
                              display="block"
                              variant="button"
                              fontWeight="regular"
                              color="text"
                              mb={1}
                            >
                              
                            <Skeleton variant="text" width={150} sx={{ fontSize: '1rem' }} />
                            </MKTypography>
                            {/* <Autocomplete
                              // defaultValue="High School"
                              onChange={(e, val) => { 
                                  // setCurrentEducationLevelId(val.id);
                                handleSearchFieldsandValue("currentEducationLevelId",val?val.id:"Any","jobseeker")}
                              }  
                              options={[...[{title:"Any",id:"Any"}], ...educationLevels]}
                              defaultValue={getEducationLevelById(jobSeekerSearchFieldsandValue.currentEducationLevelId, "single")}
                              getOptionLabel={(option) => option.title}
                              renderInput={(params) => <MKInput {...params} variant="standard" />}
                            /> */}

                          <MKTypography
                              display="block"
                              variant="button"
                              fontWeight="regular"
                              // color="text"
                              mb={1}
                            >
                            <Skeleton variant="text" width={200} sx={{ fontSize: '1rem' }} />
                            </MKTypography>
                          </Grid> 

                          <Grid item xs={12} lg={3} sx={{ mt: 2 }}>
                          <MKTypography
                            display="block"
                            variant="button"
                            fontWeight="regular"
                            color="text"
                            mb={1}
                          >
                            
                            <Skeleton variant="text" width={150} sx={{ fontSize: '1rem' }} />
                          </MKTypography>
                          {/* <Autocomplete
                            onChange={(e, val) => {
                              // setSex(val);
                              handleSearchFieldsandValue("sex",val?val:"Any","jobseeker")

                            }}
                            value={jobSeekerSearchFieldsandValue.sex}
                            options={["Any", "male", "female"]}
                            getOptionLabel={(option) =>  option }
                            renderInput={(params) => <MKInput {...params} variant="standard" placeholder="select gender"/>}
                          /> */}
                          <MKTypography
                              display="block"
                              variant="button"
                              fontWeight="regular"
                              // color="text"
                              mb={1}
                              
                            >
                              
                            <Skeleton variant="text" width={200} sx={{ fontSize: '1rem' }} />
                            </MKTypography>
                            </Grid>
                          <Grid item xs={12} lg={2} sx={{ mt: 2 }}>
                          <MKTypography
                            display="block"
                            variant="button"
                            fontWeight="regular"
                            color="text"
                            mb={1}
                          >
                           
                           <Skeleton variant="text" width={150} sx={{ fontSize: '1rem' }} />
                          </MKTypography>
                          {/* <Autocomplete
                            onChange={(e, val) => {
                              // setSex(val);
                              handleSearchFieldsandValue("sex",val?val:"Any","jobseeker")

                            }}
                            value={jobSeekerSearchFieldsandValue.sex}
                            options={["Any", "male", "female"]}
                            getOptionLabel={(option) =>  option }
                            renderInput={(params) => <MKInput {...params} variant="standard" placeholder="select gender"/>}
                          /> */}
                          <MKTypography
                              display="block"
                              variant="button"
                              fontWeight="regular"
                              // color="text"
                              mb={1}
                            >
                            <Skeleton variant="text" width={200} sx={{ fontSize: '1rem' }} /> </MKTypography>
                            </Grid>

                        </Grid>
                      </Grid> 
                   
                  </Grid>
                {/* </Container> */}
              {/* </MKBox> */}
            </AccordionSummary>
            <AccordionDetails> 
            </AccordionDetails>
          </Accordion>
          </div>
          ) }


        </div> 


    </>)
    } catch (error) {
      return <></>
    }

  }
  

  const handleSearchResult = (result) => {
    // console.log("handleSearchResult",result)
    setNotSearchedYet(false)

    setProfessionTitle(result.professionTitle||"")
    let res = false;
    if (result.employees) { 
        res = result.employees;
    }
    if (res) {
      setSearchResult(res);
    } else {
      setSearchResult([]);
    }
    setForceRerender(!forceRerender); 
  };

  return (
    
    <MKBox component="section"  py={1} > 
        
          <MKBox component="section" p={(window.location.href.indexOf("jobs")>0)?3:0}  >
            <  >
            <>

            <Grid container  mb={2} >
                  
                  <Grid item xs={12} lg={5} sx={{ mt: 2 }} justifyContent={"flex-end"}>
 
        <MKTypography variant="h3" opacity={0.4}>
          {"Search Job"}
        </MKTypography>
      </Grid>

      <Grid item xs={12} md={7} sx={{ mt: 2 }} alignItems={"right"}>
      {   <Paper
          // component="form"
          sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width:"100%" }}
        >
          <IconButton sx={{ p: '10px' }} aria-label="menu">
            <Menu/>
          </IconButton>
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Search "
            value={searchKeyword}
            
            onKeyDown={(event)=>{
              if (event.keyCode) {
                if (event.keyCode === 13) {searchByKeyword()}
                }
             }}
            onChange={(e)=>{
              setSearchKeyword(e.target.value)
              // console.log(e.target.value)
            }}
            inputProps={{ 'aria-label': 'search google maps' }}
          />
          <IconButton disabled={!searchKeyword} onClick={()=>{searchByKeyword()}} type="button" sx={{ p: '10px' }} aria-label="search">
            <Search />
          </IconButton>
          {/* <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
          <IconButton color="primary" sx={{ p: '10px' }} aria-label="directions">
            <Directions />
          </IconButton> */}
        </Paper>}
      </Grid>

      </Grid>


      <Card
        sx={{
          my: 2,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
          overflow: "hidden",
        }}
      >
        {(false) && (
        // {/* {(searchExpanded == true) && ( */}
          <MKBox component="section">
            <Container>
              <Grid container spacing={{ xs: 0, lg: 3 }} sx={{ pt: 2, pb: 3, px: 2, mx: "auto" }}>
                {jobs.length > 0 && (
                  <Grid item xs={12} lg={3} sx={{ mt: 2 }}>
                    <MKTypography
                      display="block"
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      mb={1}
                    >
                      Employee profesion
                    </MKTypography>
                    {/* <Autocomplete
                      // defaultValue={jobsToChoose.length?jobsToChoose[0]:{}}
                      onChange={(e, val) => { 
                        handleSearchFieldsandValue("jobId",val?val.id:"","job")
                        setProfessionTitle(val?val.title:""); 
                      }}
                      // defaultValue={jobId?getCityById(jobId):}
                      options={agencyJobs||jobs}
                      getOptionLabel={(option) => option.title}
                      renderInput={(params) => <MKInput placeholder="select profession" {...params} variant="standard" />}
                    /> */}

                    <Select
                      variant="standard"
                      fullWidth
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      // defaultValue={getEducationLevelById(jobSeekerSearchFieldsandValue.currentEducationLevelId, "single")}
                      // defaultValue={jobSeekerSearchFieldsandValue.currentEducationLevelId}
                      defaultValue={jobSearchFieldsandValue.jobId || "Any"}
                      // defaultValue={"Any"}
                      // defaultValue={jobsToChoose.length?jobsToChoose[0]:{}}
                      // label="Age"
                      onChange={(e) => {
                        // setSex(val);
                        let idD = e.target.value;
                        try {
                          if (idD) {
                            handleSearchFieldsandValue("jobId", e ? idD : "Any", "job");

                            // handleSearchFieldsandValue("jobId",val?val.id:"","job")
                            if (jobs) {
                              let jTitle = jobs.filter((job) => job.id == idD);
                              if (jTitle.length) {
                                jTitle = jTitle[0];
                                setProfessionTitle(jTitle.title);
                              }
                            }
                          }
                        } catch (error) {}
                      }}
                    >
                      <MenuItem value={"Any"}>{"Any"}</MenuItem>
                      {(agencyJobs || jobs).map((val) => (
                        <MenuItem value={val.id}>{val.title}</MenuItem>
                      ))}
                    </Select>
                  </Grid>
                )}
                {educationLevels.length > 0 && (
                  <Grid item xs={12} lg={3} sx={{ mt: 2 }}>
                    <MKTypography
                      display="block"
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      mb={1}
                    >
                      Employee education level
                    </MKTypography>
                    {/* <Autocomplete
                      // defaultValue="High School"
                      onChange={(e, val) => { 
                          // setCurrentEducationLevelId(val.id);
                        handleSearchFieldsandValue("currentEducationLevelId",val?val.id:"Any","jobseeker")}
                      }  
                      options={[...[{title:"Any",id:"Any"}], ...educationLevels]}
                      defaultValue={getEducationLevelById(jobSeekerSearchFieldsandValue.currentEducationLevelId, "single")}
                      getOptionLabel={(option) => option.title}
                      renderInput={(params) => <MKInput {...params} variant="standard" />}
                    /> */}

                    <Select
                      variant="standard"
                      fullWidth
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      // defaultValue={getEducationLevelById(jobSeekerSearchFieldsandValue.currentEducationLevelId, "single")}
                      defaultValue={jobSeekerSearchFieldsandValue.currentEducationLevelId}
                      // label="Age"
                      onChange={(e) => {
                        // setSex(val);
                        let idD = e.target.value;
                        if (idD) {
                          handleSearchFieldsandValue(
                            "currentEducationLevelId",
                            e ? idD : "Any",
                            "jobseeker"
                          );
                        }
                      }}
                    >
                      <MenuItem value={"Any"}>{"Any"}</MenuItem>
                      {educationLevels.map((val) => (
                        <MenuItem value={val.id}>{val.title}</MenuItem>
                      ))}
                    </Select>
                  </Grid>
                )}
                <Grid item xs={12} lg={3} sx={{ mt: 2 }}>
                  <MKTypography
                    display="block"
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    mb={1}
                  >
                    Employee gender
                  </MKTypography>
                  {/* <Autocomplete
                    onChange={(e, val) => {
                      // setSex(val);
                      handleSearchFieldsandValue("sex",val?val:"Any","jobseeker")

                    }}
                    value={jobSeekerSearchFieldsandValue.sex}
                    options={["Any", "male", "female"]}
                    getOptionLabel={(option) =>  option }
                    renderInput={(params) => <MKInput {...params} variant="standard" placeholder="select gender"/>}
                  /> */}
                  <Select
                    variant="standard"
                    fullWidth
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={jobSeekerSearchFieldsandValue.sex}
                    // label="Age"
                    onChange={(e) => {
                      // setSex(val);
                      handleSearchFieldsandValue("sex", e ? e.target.value : "Any", "jobseeker");
                    }}
                  >
                    {["Any", "male", "female"].map((val) => (
                      <MenuItem value={val}>{val}</MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={12} lg={3} sx={{ mt: 2 }}>
                  <MKTypography
                    display="block"
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    mb={1}
                  >
                    City
                  </MKTypography>
                  <Autocomplete
                    // autoSelect
                    // defaultValue={getCityById(city, "single")}
                    value={getDataFromArray(
                      cities,
                      "TOWN_NAME",
                      jobSeekerSearchFieldsandValue.city,
                      "single"
                    )}
                    options={cities} //TOWN_NAME
                    groupBy={(option) => option.REGION}
                    // limitTags={2}
                    onChange={
                      (e, val) => {
                        handleSearchFieldsandValue(
                          "city",
                          val ? val.TOWN_NAME : "Any",
                          "jobseeker"
                        );
                      }
                      // setCity(val.TOWN_NAME ? val.TOWN_NAME : "");
                    }
                    getOptionLabel={(option) => (option.TOWN_NAME ? option.TOWN_NAME : "")}
                    renderInput={(params) => <MKInput {...params} variant="standard" />}
                  />
                </Grid>
                {jobSeekerSearchFieldsandValue.city && (
                  <>
                    {jobSeekerSearchFieldsandValue.city.toLowerCase() == "addis ababa" && (
                      <>
                        <Grid item xs={12} lg={3} sx={{ mt: 2 }}>
                          <MKTypography
                            display="block"
                            variant="button"
                            fontWeight="regular"
                            color="text"
                            mb={1}
                          >
                            Sub city
                          </MKTypography>
                          <Autocomplete
                            value={getDataFromArray(
                              subCities,
                              "title",
                              jobSeekerSearchFieldsandValue.subCity,
                              "single"
                            )}
                            options={subCities} //TOWN_NAME
                            onChange={
                              (e, val) => {
                                handleSearchFieldsandValue(
                                  "subCity",
                                  val ? val.title : "Any",
                                  "jobseeker"
                                );
                              }
                              // setSubCity(val.title ? val.title : "");
                            }
                            getOptionLabel={(option) => (option.title ? option.title : "")}
                            renderInput={(params) => <MKInput {...params} variant="standard" />}
                          />
                        </Grid>
                        <Grid item xs={12} lg={3} sx={{ mt: 2 }}>
                          <MKTypography
                            display="block"
                            variant="button"
                            fontWeight="regular"
                            color="text"
                            mb={1}
                          >
                            Specific area
                          </MKTypography>
                          <Autocomplete
                            value={getDataFromArray(
                              specificAreas,
                              "title",
                              jobSeekerSearchFieldsandValue.specificArea,
                              "single"
                            )}
                             
                            options={specificAreas} //TOWN_NAME
                            onChange={
                              (e, val) => {
                                handleSearchFieldsandValue(
                                  "specificArea",
                                  val ? val.title : "Any",
                                  "jobseeker"
                                );
                              }
                              // setSpecificArea(val.title ? val.title : "");
                            }
                            getOptionLabel={(option) => (option.title ? option.title : "")}
                            renderInput={(params) => <MKInput {...params} variant="standard" />}
                          />
                        </Grid>
                      </>
                    )}
                  </>
                )}
                <Grid item xs={12} lg={3} sx={{ mt: 4 }}>
                  <Box sx={{ m: 1, position: "relative" }}>
                    <MKButton
                      variant="gradient"
                      disabled={loading || !professionTitle}
                      color="info"
                      onClick={() => {
                        search();
                      }}
                      fullWidth
                    >
                      {/* { (loading?"searching" :"search" ) +" " +professionTitle} */}
                      search
                    </MKButton>
                    {loading && (
                      <CircularProgress
                        size={24}
                        sx={{
                          // color: green[500],
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          marginTop: "-12px",
                          marginLeft: "-12px",
                        }}
                      />
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Container>
            {forceRenderer}
          </MKBox>
        )}
        {/* <Accordion
          expanded={searchExpanded}
          onChange={() => {
            setSearchExpanded(!searchExpanded); 
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreOutlined />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <MKTypography variant="h6" opacity={0.4}>
              {searchExpanded ? "Hide Search" : "Show Search"}
            </MKTypography>
          </AccordionSummary>
        </Accordion> */}
      </Card>

      {/* <Card
          sx={{
            my: 2,
            backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
            backdropFilter: "saturate(200%) blur(30px)",
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
            overflow: "hidden",
          }}
        > */}
       


      <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        {((location.pathname.indexOf("pages/jobs") > -1)&&(!isFirstTimeSearch))?<>
        {myJobSeekerInformation?<Tabs value={value} onChange={handleTabChange} aria-label="basic tabs example">
         {<Tab  label="Search"  {...a11yProps(0)}  icon={<Filter />}   />} 
         <Tab label="Saved" {...a11yProps(1)}  icon={<Favorite />} />
        </Tabs>:<Tab  label="Search"  {...a11yProps(0)}  icon={<Filter />}   />}</>
        :
         <>
       { myJobSeekerInformation?<Tabs value={value} onChange={handleTabChange} aria-label="basic tabs example"> 
          <Tab  label="Best Match"  {...a11yProps(0)}  icon={<Filter />}   /> 
          <Tab label="Recent" {...a11yProps(1)}  icon={<Update />} /> 
          <Tab label="Saved" {...a11yProps(2)}  icon={<Favorite />} />
        </Tabs>:
        <Tabs value={value} onChange={handleTabChange} aria-label="basic tabs example">  
        <Tab label="Recent" {...a11yProps(0)}  icon={<Update />} />  
      </Tabs>}
        </>}
      </Box>





      {((location.pathname.indexOf("pages/jobs") > -1)&&(!isFirstTimeSearch))?
      <>{myJobSeekerInformation?<>
      <CustomTabPanel value={value} index={0}>
          {loading?<>{jobsLoading(5)}
          </>:<> 
         {(searchResult?(searchResult.length?true:false):false)?
         <>  {jobList(searchResult)}</>:
         <> 
         
             <MKBox component="section">
            <Container>
              <Grid container  spacing={{ xs: 0, lg: 3 }} sx={{ pt: 2, pb: 3, px: 2, mx: "auto" }} justifyContent={"center"}>
                <Grid item sm={12} lg={10} sx={{ mt: 2 }}>  <Search fontSize={"large"}/>
                  
                   <MKTypography variant={"h3"}>{"There are no results that match your search : '"+searchedKeyword+"'"}</MKTypography>
                   <MKTypography variant={"text"} >Please try adjusting your search keywords or filters.</MKTypography>
                  </Grid>
                  </Grid>
                  </Container>
                  </MKBox>
           </>}
           </>}
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
      {jobList(employmentAgencyJobVacancies)} 
      </CustomTabPanel>
      </>:
       <CustomTabPanel value={value} index={0}>
       {loading?<>{jobsLoading(5)}
       </>:<> 
      {(searchResult?(searchResult.length?true:false):false)?
      <>  {jobList(searchResult)}</>:
      <> 
      
          <MKBox component="section">
         <Container>
           <Grid container  spacing={{ xs: 0, lg: 3 }} sx={{ pt: 2, pb: 3, px: 2, mx: "auto" }} justifyContent={"center"}>
             <Grid item sm={12} lg={10} sx={{ mt: 2 }}>  <Search fontSize={"large"}/>
                 <MKTypography variant={"h3"}>{"There are no results that match your search : '"+searchedKeyword+"'"}</MKTypography>
                 <MKTypography variant={"text"} >Please try adjusting your search keywords or filters.</MKTypography>
               </Grid>
               </Grid>
               </Container>
               </MKBox>
        </>}
        </>}
   </CustomTabPanel>}
      </>:
      <>
      {myJobSeekerInformation?<>
      <CustomTabPanel value={value} index={0}>
        {jobList(employmentAgencyJobVacancies)}  
      </CustomTabPanel> 
      <CustomTabPanel value={value} index={1}>
      {jobList(employmentAgencyJobVacancies)} 
      </CustomTabPanel> 
      <CustomTabPanel value={value} index={2}>
      {jobList(employmentAgencyJobVacancies)} 
      </CustomTabPanel></>:
      <CustomTabPanel value={value} index={0}>
      {jobList(employmentAgencyJobVacancies)}   {/* //recent */}
      </CustomTabPanel>}
      </>
    }
    </Box>



      {/* </Card> */}

            {forceRenderer}
      {/* {searchResult && (
        <Card
          sx={{
            my: 2,
            backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
            backdropFilter: "saturate(200%) blur(30px)",
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
            overflow: "hidden",
          }}
        >
          <MKBox component="section">
            <Container>
              <Grid container spacing={{ xs: 0, lg: 3 }} sx={{ pt: 2, pb: 3, px: 2, mx: "auto" }}>
                <Grid item sm={12} lg={2} sx={{ mt: 2 }}>
              

                  <MKTypography variant="h6" opacity={0.4}>
                    {"Filter by"}
                  </MKTypography>
                </Grid>
                <Grid item xs={12} lg={3} sx={{ mt: 2 }}>
                  <TextField
                    fullWidth
                    id="skillsOrSubjects"
                    defaultValue={""}
                    label="Skills or Subjects"
                    variant="outlined"
                    onChange={(e) => {
                      filterResult("skillsOrSubjects", e.target.value);
                    }}
                  />
                </Grid>
               
              </Grid>
            </Container>
            {forceRenderer}
          </MKBox>
        </Card>
      )} */}


      
    <Dialog open={openedDialog=="moreText"} onClose={()=>{setOpenedDialog("")}}>
        {moreText&&<>
       {moreText.title&& <DialogTitle>{parse(moreText.title)}</DialogTitle>}
       {moreText.content&& <DialogContent>
          <DialogContentText>
           {parse(moreText.content)}
          </DialogContentText> 
        </DialogContent>}</>}
        <DialogActions>
          <Button onClick={()=>{setOpenedDialog("")}}>close</Button> 
        </DialogActions>
      </Dialog>
    </>
               
            </>
          </MKBox> 
          {forceRerender}  
    </MKBox>
  );
}

export default SearchJob;
