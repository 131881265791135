/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

var convert = require('xml-js');
import parse from 'html-react-parser';
// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import { useEffect, useState } from "react";

function ContentInformation(props) { 
  const [content, setContent] = useState(null); 

  useEffect(() => {  
    // let content = convert.xml2json(props.content.xml, {compact: true, spaces: 4});
    // content = JSON.parse(content)
    // content=Object.entries(content[props.content.contentType.referenceKey])

    setContent(props.content)

  }, []);

  return (<>
   { content&&
   <MKBox component="section" py={6} mt={6}>
      <Container>
        <Grid container spacing={3} item xs={12} lg={8} mx="auto">
          {content.map((cnt,index)=>{
            return <Grid  item xs={12} lg={8}  >
            <MKTypography
            component="h6"
            variant="button"
            opacity={0.7}
            textTransform="uppercase"
            fontWeight="bold"
          >
          {parse(cnt[0])}
          </MKTypography>  
          {(index==0)&&<MKTypography variant="h3" mb={3}>
            {parse(cnt[1]["_cdata"])}
          </MKTypography>}
          {(index!=0)&&<MKTypography variant="body2">
            {parse(cnt[1]["_cdata"])}
          </MKTypography>}
            </Grid>
          }) 
        }
        </Grid>
      </Container>
    </MKBox>}
  </>);
}

export default ContentInformation;
