/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React,{ useEffect, useRef } from "react";

// rellax
import Rellax from "rellax";

// typed-js
import * as Typed from "typed.js";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// About Us page sections
import Information from "pages/Company/AboutUs/sections/Information";
import Team from "pages/Company/AboutUs/sections/Team";
import Featuring from "pages/Company/AboutUs/sections/Featuring";
import Newsletter from "pages/Company/AboutUs/sections/Newsletter";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/bg-about-us.jpg";
import { useCurrentUser } from "Api-contexts/currentUserContext";

function AboutUs() {
  const [currentData] = useCurrentUser();
  const {websitePagesContent,myJobSeekerInformation,isAgentWebsite,isUserLoggedIn,agencyData,myAgencies } = currentData;
  const [aboutUsPage, setAboutUsPage] = React.useState(null);
   
  const headerRef = useRef(null);
  // websitePagesContent
  // Setting up rellax
  useEffect(() => {
    const parallax = new Rellax(headerRef.current, {
      speed: -6,
    });
    // if(parallax){
    // return () => parallax.destroy();
    // }
  }, []);

  // Setting up typedJS
  useEffect(() => { 
    // if(typedJS){
    // return () => typedJS.destroy();
    // }
  }, []);

  useEffect(() => {
    // console.log("websitePagesContent",websitePagesContent) 
    let aboutUsPageD={}
    if(websitePagesContent){
      if(websitePagesContent.pages){
        if(websitePagesContent.pages.aboutUsPage){
          if(websitePagesContent.pages.aboutUsPage.sections){
            aboutUsPageD=websitePagesContent.pages.aboutUsPage.sections
            setAboutUsPage(websitePagesContent.pages.aboutUsPage.sections)
          }
        }
      }
    }

    // console.log("aboutUsPageD",aboutUsPageD) 
  }, [websitePagesContent]);

  const didIJoinedCurrentAgency = () => {
    let res=false
    if(agencyData&&myAgencies){
      // console.log("agencyData&&myAgencies",agencyData,myAgencies)
        if(myAgencies.length){
          myAgencies.forEach(element => {
            if(agencyData.id==element.id){
              res=true
            }
          });
        }
    }

    return res 
  };

  return (
    <>
   {(aboutUsPage) &&<>
     {(aboutUsPage.banner)&&
      <MKBox
        ref={headerRef}
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${aboutUsPage.banner.backgroundImage?aboutUsPage.banner.backgroundImage:bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
           {(aboutUsPage.banner.description)&& <MKTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3}>
              {aboutUsPage.banner.description}
            </MKTypography>}
          {(isAgentWebsite)&&
           <> {(isUserLoggedIn)?
           <> { myJobSeekerInformation?
                <>{(!didIJoinedCurrentAgency())&&
                  <MKButton color="default" href={"/join-agency"} 
                    sx={{ color: ({ palette: { dark } }) => dark.main }}>
                    {"Join "+agencyData.brandName}
                  </MKButton>}</>:
                  <MKButton color="default" href={"/profile"} 
                    sx={{ color: ({ palette: { dark } }) => dark.main }}>
                      {"Build your career information"}
                </MKButton>}</>:
           <MKButton color="default" href={"/login?redirectTo=/join-agency"} 
            sx={{ color: ({ palette: { dark } }) => dark.main }}>
            {"Login / Register then join us"}
            </MKButton>}</>}
          </Grid>
        </Container>
      </MKBox>}
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
       {(aboutUsPage.card||aboutUsPage.companyDescription)&& <Information card={aboutUsPage.card} companyDescription={aboutUsPage.companyDescription}/>}
       {(aboutUsPage.teams)&& <Team teams={aboutUsPage.teams}/>}
        {/* <Featuring />
        <Newsletter /> */}
      </Card>
      </>}
    </>
  );
}

export default AboutUs;
