/**
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";

// Authentication layout components
import CoverLayout from "pages/Authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/bg-sign-up-cover.jpeg";
import { Autocomplete, CircularProgress, Grid } from "@mui/material";
import { HttpService } from "Api-Services/httpService";

function Cover() {
  const [forceRerender, setForceRerender] = React.useState(true);
  //local constants
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  const [selectedUserType, setSelectedUserType] = React.useState(1);
  const [loading, setLoading] = useState(false);
  const [isFormValid, setIsFormValid] = useState(true);
  const [invalidResponse, setInvalidResponse] = useState("");
  const [loginResponse, setLoginResponse] = useState(null);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [gender, setGender] = useState("male");
  const [phone, setPhone] = useState("male");
  const [isTutor, setIsTutor] = useState(true);
  const [city, setCity] = useState("addis ababa");
  const navigate = useNavigate();

  useEffect(() => { 
    console.log("location", location);
    let baseUrl="https://auth.bfamily.et/register"
    let redirectTo=""
    let searchData=""
    if (location.search) {
      let locationSearch = queryString.parse(location.search);
      if (locationSearch) {
        if (locationSearch.redirectTo) {
          redirectTo=locationSearch.redirectTo
        }
        setRouteSearch(locationSearch);
      }
    }
    // searchData="?redirectTo='"+location.origin+"/"+redirectTo+"'"
    searchData="?redirectTo="+location.origin+redirectTo

    if (location.hostname) {
      if(location.hostname=="localhost"){
        baseUrl="http://localhost:5004/register"
      }
    }
    
    // window.open(baseUrl+searchData, '_blank');
    window.location.assign(baseUrl+searchData);
     
  }, [location.pathname, location.search]);
 
  const register = async () => {
    localStorage.clear();
    let valid = false;
    setLoginResponse(null);
    setInvalidResponse("");
    setIsFormValid(true);
    if (userEmail && firstName && lastName) {
      setIsFormValid(true);
      valid = true;
    } else {
      setIsFormValid(false);
      valid = false;
      setInvalidResponse(" Please provide all information requested");
    }
    // console.log("userEmail&&password",userEmail,password, valid)
    if (valid) {
      setLoading(true);
      let data = {
        // "platformUserName":companyName,
        userEmail: userEmail,
        firstName: firstName,
        lastName: lastName,
      };
      let relativePath = "/api/authorization/registernewuser";
      // let AdditionalData={withCredentials: true, credentials: 'include'}//{  credentials: 'include' }//header can be included here
      await postDocuments("registernewuser", relativePath, data, {});
    }
    setLoading(false);
  };

  const postDocuments = async (documentName, relativePath, data) => {
    let responseD = { success: false, message: "", record: null };
    let documentsResponse = { success: false };
    setLoading(true);

    ////////// UPDATED/////////////
    documentsResponse = await HttpService.postService(relativePath, data, {}, "");
    // console.log(documentName, " data: ", documentsResponse);
    setLoading(false);

    if (documentsResponse.success) {
      responseD.success = true;
      responseD.record = documentsResponse.data.record;
      responseD.message = documentsResponse.data.message;
      let record = responseD.record;

      //write codtions down here//

      if (documentName == "registernewuser") {
        localStorage.clear();
        setLoginResponse(documentsResponse);
      }
    } else {
      responseD.message = documentsResponse.message;
      if (documentName == "registernewuser") {
        setLoginResponse(responseD);
      }
    }

    // console.log(documentName, " responseD: ", responseD   );
    setForceRerender(!forceRerender);
    return responseD;
    ////////// UPDATED/////////////
  };

  return (
    <CoverLayout >
      <Card>
        <MKBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MKTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Join Megenagna Employment System
          </MKTypography>
          <MKTypography display="block" variant="button" color="white" my={1}>
            Fill the below form to register
          </MKTypography>
        </MKBox>
        <MKBox p={3}>
          <MKBox component="form" role="form">
            <Grid container spacing={3} justifyContent="center" sx={{ mt: 1, mb: 2 }}>
              <Grid item xs={12} lg={6}>
                <MKInput
                  type="text"
                  label="First Name"
                  onChange={(e) => {
                    setFirstName(e.target.value);
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <MKInput
                  type="text"
                  label="Last Name"
                  onChange={(e) => {
                    setLastName(e.target.value);
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <MKInput
                  type="email"
                  label="Email"
                  onChange={(e) => {
                    setUserEmail(e.target.value);
                  }}
                  fullWidth
                />
              </Grid>
              {/* <Grid item xs={12} lg={6}>
                <MKBox mb={2}>
                  <MKInput
                    type="text"
                    label="Phone"
                    onChange={(e) => {
                      setPhone(e.target.value);
                    }}
                    fullWidth
                  />
                </MKBox>
              </Grid> */}
              <Grid item xs={12} lg={6}>
                {/* <MKTypography
                  display="block"
                  variant="button"
                  fontWeight="regular"
                  color="text"
                  mb={1}
                >
                  Gender
                </MKTypography> */}
                <Autocomplete
                  defaultValue={gender}
                  onChange={(e) => {
                    setGender(e.target.value);
                  }}
                  options={["male", "female"]}
                  renderInput={(params) => <MKInput {...params} label="Gender" />}
                />
              </Grid>
              {/* <Grid item xs={12} lg={6}>
                <MKTypography
                  display="block"
                  variant="button"
                  fontWeight="regular"
                  color="text"
                  mb={1}
                >
                  Your city
                </MKTypography>
                <Autocomplete
                  defaultValue={city}
                  onChange={(e) => {
                    setCity(e.target.value);
                  }}
                  options={["addis ababa", "hawasa", "debre ziet", "adama", "dilla", "bahrdar"]}
                  renderInput={(params) => <MKInput {...params} />}
                />
              </Grid> */}
              {/* <Grid item xs={12} lg={6}>
                <Checkbox
                  checked={isTutor}
                  onChange={(e) => {
                    setIsTutor(e.target.checked);
                  }}
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                />
                <MKTypography
                  variant="button"
                  fontWeight="regular"
                  color="text"
                  sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                >
                  &nbsp;&nbsp;I am &nbsp;
                </MKTypography>
                <MKTypography
                  component="a"
                  href="#"
                  variant="button"
                  fontWeight="bold"
                  color="info"
                  textGradient
                >
                  a Tutor
                </MKTypography> 
              </Grid>*/}
            </Grid>

            {(loginResponse || !isFormValid) && (
              <MKBox mt={3} mb={1} textAlign="center">
                {loginResponse && <small>{loginResponse.message}</small>}
                {!isFormValid && <small>{invalidResponse}</small>}
              </MKBox>
            )}
            <MKBox mt={3} mb={1}>
              <MKButton
                variant="gradient"
                color="info"
                onClick={() => {
                  register();
                }}
                fullWidth
              >
                Register
              </MKButton>
            </MKBox>
            {loading && (
              <MKBox mt={3} mb={1} textAlign="center">
                <CircularProgress size={24} />{" "}
              </MKBox>
            )}
            <MKBox mt={3} mb={1} textAlign="center">
              <MKTypography variant="button" color="text">
                Already have an account?{" "}
                <MKTypography
                  component={Link}
                  to="/login"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Login
                </MKTypography>
              </MKTypography>
            </MKBox>
          </MKBox>
        </MKBox>
      </Card>
    </CoverLayout>
  );
}

export default Cover;
