/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState, useEffect } from "react";
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
import { toast } from "react-toastify";
import parse from 'html-react-parser';

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import { HttpService } from "Api-Services/httpService";
import { Autocomplete, Checkbox, CircularProgress, FormControlLabel, TextField } from "@mui/material";
import { useCurrentUser } from "Api-contexts/currentUserContext";
import { useNavigate } from "react-router-dom";

function JoinAgency() {
  const [forceRerender, setForceRerender] = React.useState(true);
  const [loading, setLoading] = useState(false);
  const [isFormValid, setIsFormValid] = useState(true);
  const [invalidResponse, setInvalidResponse] = useState("");
  const [loginResponse, setLoginResponse] = useState(null);

  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [agreeTerms, setAgreeTerms] = useState(true);
  const [acceptInstruction, setAcceptInstruction] = useState(true);
  const [currentData,updateContext] = useCurrentUser();
  const { agencyData,websitePagesContent,currentUser, myAgencyJobSeekerInformation, myJobSeekerInformation, jobs } = currentData;
  const [joinUsPage, setJoinUsPage] = React.useState(null);
  const [newSeekerJobs, setNewSeekerJobs] = React.useState([]);
  const [agencyJobs, setAgencyJobs] = React.useState([]);

const navigate = useNavigate();
  useEffect(() => { 
    if (websitePagesContent) {
      if (websitePagesContent.pages) {
        if (websitePagesContent.pages.joinUsPage) {
          if (websitePagesContent.pages.joinUsPage.sections) {
            setJoinUsPage(websitePagesContent.pages.joinUsPage.sections);
          }
        }
      }
    }
  }, [websitePagesContent]);
   
  useEffect(() => { 
    // console.log("useEffect", " data: ", myAgencyJobSeekerInformation,websitePagesContent,agencyData,myJobSeekerInformation   );

   setForceRerender(!forceRerender)
  }, [myAgencyJobSeekerInformation,websitePagesContent,agencyData,myJobSeekerInformation]);

  useEffect(() => {
    // console.log("agencyData",agencyData)
        let filterdJobs=[]
    if (agencyData) {
      if (agencyData.jobsId) {  
        let allagencyjobs=agencyData.jobsId.split(",")
        filterdJobs=  jobs.filter(jobD=>{ 
          let res= false
            allagencyjobs.forEach(element => {
              element=parseInt(element)
              if(element){
                if(element==jobD.id){
                  res= true
                } 
              }
            });
               return res

          })
        setAgencyJobs(filterdJobs) 
      }
    }
  }, [agencyData,jobs]);
 

  const login = async () => {
    let valid = false;
    setLoginResponse(null);
    setInvalidResponse("");
    setIsFormValid(true);
    if (fullName && message) {
      setIsFormValid(true);
      valid = true;
    } else {
      setIsFormValid(false);
      valid = false;
      setInvalidResponse(" Please provide your full name and your message");
    }
    // console.log("userEmail&&password",userEmail,password, valid)
    if (valid) {
      setLoading(true);
      let data = {
        // "platformUserName":companyName,
        email: email,
        message: message,
        fullName: fullName,
      };
      let relativePath = "/api/contactus/new";
      let toastId = toast.loading("updating website user...",{
        position: toast.POSITION.BOTTOM_LEFT
      })
      // let AdditionalData={withCredentials: true, credentials: 'include'}//{  credentials: 'include' }//header can be included here
      let contactUsRes = await postDocuments("contactus", relativePath, data, {});
      if (contactUsRes.success) {
        toast.success(contactUsRes.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        toast.error(contactUsRes.message, {
          position: toast.POSITION.TOP_LEFT,
        });
      }
    }
    setLoading(false);
  };



  const postDocuments = async (documentName, relativePath, data) => {
    let responseD = { success: false, message: "", record: null };
    let documentsResponse = { success: false };
    setLoading(true);

    ////////// UPDATED/////////////
    documentsResponse = await HttpService.postService(relativePath, data, {}, "");
    // console.log(documentName, " data: ", documentsResponse   );
    setLoading(false);

    if (documentsResponse.success) {
      responseD.success = true;
      responseD.record = documentsResponse.data.record;
      responseD.message = documentsResponse.data.message;
      let record = responseD.record;

      //write codtions down here//

      if (documentName == "login") {
        localStorage.clear();
        setLoginResponse(documentsResponse);
        setLoading(true);
        // await getDocumentsAll("allOffices","/api/officeUserRole/myoffices",{})//init user offices and data
        setLoading(false);
        let path = "/";
        window.location.assign(path);
      }
    } else {
      responseD.message = documentsResponse.message;
      if (documentName == "login") {
        setLoginResponse(responseD);
      }
    }

    // console.log(documentName, " responseD: ", responseD   );
    setForceRerender(!forceRerender);
    return responseD;
    ////////// UPDATED/////////////
  };


  const addUserToWebsite=async()=>{  

    // console.log("currentUser",currentUser)
    let data;
    let userId=currentUser.user.id||null
    let seekerJobs=""
    try { 
      if(userId){

      if(newSeekerJobs){
        newSeekerJobs.forEach((job)=>{
          if(job.id){
            seekerJobs=job.id+","
          }
        })
      }
    
        let relativePath="/api/employmentagencyjobSeeker/addusertoemploymentagency";  
          data={  
          "userId" :userId ,
          // "userId" :myJobSeekerInformation.id ,
          "jobSeekerId" :myJobSeekerInformation.id ,
          "employmentAgencyId" :agencyData.id ,
          
          "isUserManaged" :true ,
          "jobsId":seekerJobs, 
          "isActive": false,
          // "currentEducationLevelId":  newSeekerCurrentEducationLevelId,
          // "workExperianceInMonth": newSeekerWorkExperianceInMonth,
          // "websiteUserTypeId":userTypeId,
          "command":"new"
        }       
      
      let toastId = toast.loading("updating website user...",{
        position: toast.POSITION.BOTTOM_LEFT
      })
      setLoading(true)
      let addUserToWebsiteRes=await postDocuments("addUserToWebsite",relativePath,data)
      setLoading(false)
      
      toast.update(toastId, { render: addUserToWebsiteRes.message, type: addUserToWebsiteRes.success?"success":"warn", isLoading: false , autoClose: 5000});
    if(addUserToWebsiteRes.success){
      // setAddNewUserButton(false)
      // setUserSearchResult(null)
      updateContext("myAgencyJobSeekerInformation") 
      updateContext("myAgencies")
      updateContext("employmentAgencyJobSeekers")
      updateContext("newJobSeekers")  
        // "/profile"
    // navigate("/profile?tab=2");
    navigate("/Agency-Registration-Fee-Payment");
    }
          } 
      
    } catch (error) {
    // console.log("error",error)
      
    }
    
   
  }
  
    



  return (
    <MKBox
      component="section"
      display="grid"
      position="relative"
      minHeight="90vh"
      borderRadius="xl"
      mr={{ xs: 0, lg: -2 }}
      mb={{ xs: 0, lg: -2 }}
      sx={{ overflow: { xs: "hidden", lg: "visible" }, placeItems: "center" }}
    >
      {joinUsPage && (
        <MKBox
          component="img"
          alt="lake house"
          src={
            joinUsPage.card.cardImage ||
            "https://images.unsplash.com/photo-1539803442075-48618f39bb3d?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;ixlib=rb-1.2.1&amp;auto=format&amp;fit=crop&amp;w=963&amp;q=80"
          }
          width={{ xs: "100%", lg: "50%" }}
          height={{ xs: "100%", lg: "100%" }}
          position="absolute"
          right={0}
          bottom={{ xs: "-25%", lg: "unset" }}
          top={{ xs: 0, lg: "unset" }}
          sx={{
            objectFit: "cover",
            borderTopLeftRadius: ({ borders: { borderRadius } }) => ({
              xs: 0,
              lg: borderRadius.lg,
            }),
          }}
        />
      )}
      <Container>
        <Grid container spacing={{ xs: 0, lg: 3 }} sx={{ mt: { xs: 0, lg: 12 } }}>
          <Grid item xs={12} lg={12} justifyContent="center" flexDirection="column">
            <MKBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              shadow="lg"
              borderRadius="xl"
              sx={{
                backgroundColor: ({ functions: { rgba }, palette: { white } }) =>
                  rgba(white.main, 0.6),
                backdropFilter: "saturate(200%) blur(30px)",
                px: { xs: 3, sm: 6 },
                py: { xs: 3, sm: 8 },
                mb: { xs: 0, lg: 8 },
                mt: { xs: 0, lg: -6 },
              }}
            >
              <MKTypography variant="h5" textAlign="center" mb={2}>
             {agencyData?(agencyData.brandName||agencyData.fullName):""}
               {" Instruction "}</MKTypography>
              <MKTypography variant="body2" color="danger" textAlign="center" mb={2}> 
               {"Read all carefully by scrolling! "}</MKTypography>
              <MKBox component="form">
                <MKBox py={1} px={{ md: 4, lg: 4, xl: 6 }}>
                    <Grid container spacing={3} sx={{ mb: 3 ,py:1}}>


                    {(joinUsPage)&&
                    <>
                    {(joinUsPage.instruction)&&
                    <Grid item xs={12} lg={12} justifyContent="center" flexDirection="column">
                      <MKBox
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="column"
                        shadow="lg"
                        borderRadius="xl"
                        sx={{
                          backgroundColor: ({ functions: { rgba }, palette: { white } }) =>
                            rgba(white.main, 0.8),
                          backdropFilter: "saturate(200%) blur(30px)",
                          px: { xs: 3, sm: 6 },
                          py: { xs: 3, sm: 2 },
                          // mb: { xs: 0, lg: 8 },
                          mt: { xs: 0, lg: 1 },
                        }}
                      >
                      {(joinUsPage.instruction.title) &&<MKTypography variant="h6" textAlign="center" mb={2}>
                        {joinUsPage.instruction.title}
                        </MKTypography>}
                        {(joinUsPage.instruction.body)&&
                        <MKBox style={{overflow:"auto", maxHeight:"250px"}} >
                          {/* <MKBox  px={{ xs: 3, md: 12, lg: 6, xl: 12 }}> */}
                              <MKTypography variant="subtitle" color="text" >
                 <small>
                              {parse(joinUsPage.instruction.body)}
                      </small>
                  </MKTypography>
                          {/* </MKBox> */}
                        </MKBox>}
                      </MKBox>
                    </Grid>}
                        </>}

                   { (!(myAgencyJobSeekerInformation))&&<Grid item xs={12}  justifyContent="center" flexDirection="column" >
                       <FormControlLabel 
                       control={<Checkbox checked={acceptInstruction} 
                       onChange={(e)=>{setAcceptInstruction(e.target.checked); console.log(e)} } 
                       defaultChecked />} 
                       label="Accept the above instruction" />
                      {/* <Autocomplete fullWidth
                        multiple
                        id="checkboxes-tags-demo"
                        options={agencyJobs}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.title}  
                        onChange={(e,data)=>{
                          console.log("val,ee",e,data)
                          if(data){   
                            setNewSeekerJobs(data)
                            setForceRerender(!forceRerender)
                          } 
                      }}
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Checkbox
                              // icon={icon}
                              // checkedIcon={true}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option.title}
                          </li>
                        )}
                        // style={{ width: 500 }}
                        renderInput={(params) => (
                          <TextField {...params} label="Your Professions" placeholder="Select Your Career" />
                        )}
                      /> */}
                      
                    </Grid>}
                    {/* <Grid item xs={12} sx={{ my: 1 }}>
                      <MKInput
                        variant="standard"
                        type="email"
                        placeholder="eg. extremeTutor@gmail.com"
                        label="Email Address"
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <MKInput
                        variant="standard"
                        label="Your Message"
                        rows={4}
                        InputLabelProps={{ shrink: true }}
                        multiline
                        onChange={(e) => {
                          setMessage(e.target.value);
                        }}
                        fullWidth
                      />
                    </Grid> */}
                    
                    {/* <Grid item xs={12} sx={{ mb: 3, ml: -1 }}>
                      <Switch id="flexSwitchCheckDefault" defaultChecked />
                      <MKTypography
                        component="label"
                        variant="button"
                        color="text"
                        fontWeight="regular"
                        htmlFor="flexSwitchCheckDefault"
                        sx={{ userSelect: "none", cursor: "pointer" }}
                      >
                        I agree to the{" "}
                        <MKTypography component="a" href="#" variant="button" fontWeight="regular">
                          <u>Terms and Conditions</u>
                        </MKTypography>
                        .
                      </MKTypography>
                    </Grid> */}
                   {(loginResponse || !isFormValid||loading) &&
                   <Grid item xs={12}>
                      {(loginResponse || !isFormValid) && (
                        <MKBox mt={3} mb={1} textAlign="center">
                          {loginResponse && <small>{loginResponse.message}</small>}
                          {!isFormValid && <small>{invalidResponse}</small>}
                        </MKBox>
                      )}
                      {loading && (
                        <MKBox mt={3} mb={1} textAlign="center">
                          <CircularProgress size={24} />{" "}
                        </MKBox>
                      )}
                    </Grid>}



                   { (agencyData)&&<Grid item xs={12}>
                     {(!(myAgencyJobSeekerInformation))? <MKButton
                        variant="gradient"
                        color="success"
                        fullWidth 
                        
                        // disable={loading||(newSeekerJobs.length<1)}
                        disabled={loading||(!acceptInstruction)}
                        onClick={() => {
                          addUserToWebsite();
                        }}
                      >
                        Join
                      </MKButton> :<>
                      {(!(myAgencyJobSeekerInformation.registrationFeePaid))&&<MKButton
                        variant="gradient"
                        color="success"
                        fullWidth 
                        
                        // disable={loading||(newSeekerJobs.length<1)}
                        disabled={loading||(!acceptInstruction)}
                        onClick={() => {
                          navigate("/Agency-Registration-Fee-Payment")
                        }}
                      >
                        Continue To Payment
                      </MKButton>}</> }
                    </Grid>}
                  </Grid>
                </MKBox>
              </MKBox>
            </MKBox>
          </Grid>
{forceRerender}
        </Grid>
      </Container>
    </MKBox>
  );
}

export default JoinAgency;
